//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getCompanyDetail = async (companyId:any)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.get(`/companies/${companyId}`);
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}

const getCompanyList = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/companies/all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const getCompanyAllData = async ()=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/companies/get-all`);
    data = response.data.data;
    status = response.status;
   if(status !== 201) {
      errorMessage = response.data.message
    }
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   //console.log(errorMessage);
 }
 return { data, status, errorMessage }
}

 const getCompanies = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string,dateRange?:{startDate:string, endDate:string}) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/companies?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=email"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=address"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=city"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=code"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[3]=status"+ ',' + searchTerm;
    }

    if(dateRange) {
      if(dateRange.startDate !== "") {
        requestUrl = requestUrl + "&greater_or_equal=created_at"+ ',' + dateRange.startDate;
        requestUrl = requestUrl + "&less_or_equal=created_at"+ ',' + dateRange.endDate;
      }
      
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const addCompany = async (companyData: any) => {
  console.log(companyData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/companies`, companyData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateCompany = async (companyData: any, companyId:number) => {
  console.log(companyData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.put(`/companies/${companyId}`, companyData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message

    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}
/*const getValidationMessage = (responseData) =>{
      if(errorMessage)
  } */




export {getCompanyDetail, getCompanyList, getCompanies, addCompany, updateCompany, getCompanyAllData}