import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/scss/booking.scss";
import "../../assets/scss/modify_datepicker.scss";
import MapQuestDirections from "./MapQuestMap";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { clearState, userSelector } from '../../store/slices/user';
import { clearSelectedVehicleState } from '../../store/slices/selectedVehicle';
import { clearVehicleListState } from '../../store/slices/vehicleAvailable';
import toast, { Toaster } from 'react-hot-toast';

import Loader from "../../components/loading/loader"
import moment from "moment";
import { getLocationCodeWiseDetail } from "../../external_api/Location";
import { getVehicleAvailable } from "../../external_api/Booking";
import { getSettingDetail } from "../../external_api/Setting";



import ServerAutoSuggest from "../../components/common/ServerAutoSuggest";
import { AppDispatch } from "../../store";

import { getAgeList } from "../../utility/Helpers"


import { resetExtraItems } from '../../store/slices/extras'

const BookingPage = () => {

  const { isFetching, isSuccess: isLogin } = useSelector(
    userSelector
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  if (!isLogin) {
    navigate("/");
  }

  const [dropOfflocation, setDropOfflocation] = useState("same");


  const validationSchema = Yup.object().shape({
    drop_location: Yup.string()
      .required('drop_location is required'),
    pickup_time: Yup.string()
      .required('Pickup time is required'),
    dropoff_time: Yup.string()
      .required('Dropoff time is required'),
    driver_age: Yup.string()
      .required('Driver age is required'),
    /* payment_type: Yup.string()
      .required('Payment type is required'), */
    pickup_date: Yup.string()
      .required('Pickup date is required'),
    dropoff_date: Yup.string().required('Pickup date is required'),
    pickup_location: Yup.string().default(""),
    dropoff_location: Yup.string().default(""),
    pickup_oagcode: Yup.string()
      .required('Pickup Location is required'),
    dropoff_oagcode: Yup.string()
      .required('Dropoff Location is required'),
    country: Yup.string(),
    vendor: Yup.string()
  });


  type FormValues = {
    drop_location: string,
    pickup_time: string,
    dropoff_time: string,
    driver_age: string,
    //payment_type: string,
    pickup_location: string,
    dropoff_location: string,
    pickup_oagcode: string,
    dropoff_oagcode: string,
    pickup_date: string | null,
    dropoff_date: string,
    country: string,
    vendor: string,

  }

  const { register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    control
  } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });

  //console.log("booking errors ", errors);
  const [loading, setLoading] = useState(false);

  const [bookingDefaultData, setBookingDefaultData] = useState(
    {
      drop_location: "",
      pickup_time: "12:00",
      dropoff_time: "12:00",
      driver_age: "",
      //payment_type: "",
      pickup_location: "",
      dropoff_location: "",
      pickup_oagcode: "",
      dropoff_oagcode: "",
      pickup_date: "",
      dropoff_date: "",
      country: "",
      vendor: "",
    }
  );


  //const [items, setItems] = useState([]);
  const [locLat, setLocLat] = useState("53.3498");
  const [locLon, setLocLon] = useState("-6.2603");


  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [operationHour, setOperationHour] = useState("");

  const [country, setCountry] = useState("");
  const [vendor, setVendor] = useState("ZE");

  const [selPickupLoc, SetSelPickupLoc] = useState("")
  const [selDropoffLoc, SetSelDropoffLoc] = useState("")
  const [selPickupOagcode, SetSelPickupOagcode] = useState("")
  const [selDropoffOagcode, SetSelDropoffOagcode] = useState("")

  /* const [selPickupDate, SetSelPickupDate] = useState(null)
  const [selDropoffDate, SetSelDropoffDate] = useState(null)
 */
  const [selPickupDate, SetSelPickupDate] = useState<Date | null>(null)
  const [selDropoffDate, SetSelDropoffDate] = useState<Date | null>(null)


  const [pickupDetail, setPickupDetail] = useState<any>(null)
  const [dropoffDetail, setDropoffDetail] = useState<any>(null)

  const [settingImageUrl, setSettingImageUrl] = useState("")


  //console.log("selDropoffOagcode", selDropoffOagcode)


  const onSubmitForm = async (formData: any) => {
    //console.log("Booking formData", formData);
    dispatch(resetExtraItems());
    var formNewData = {
      pickUpLoc: formData.pickup_oagcode,
      pickUpDate: formData.pickup_date,
      pickUpTime: formData.pickup_time,
      retLoc: formData.dropoff_oagcode,
      retDate: formData.dropoff_date,
      retTime: formData.dropoff_time,
      country: formData.country,
      //payment_type: formData.payment_type,
      dob: formData.driver_age,
      extras: formData.extras,
      vendor: formData.vendor,
    }


    setLoading(true);
    var response = await getVehicleAvailable(formNewData);
    const { data, status, errorMessage } = response;
    if (status == 201) {
      setLoading(false);
      localStorage.setItem('search_booking', JSON.stringify(formData));
      setTimeout(() => {
        navigate("/booking/vehicle-list");
      }, 500);
    } else {
      if (errorMessage.errors) {
        toast.error(errorMessage.errors.ShortText);
      } else {
        if (errorMessage === "Unauthenticated.") {
          dispatch(clearState());
          localStorage.clear();
        } else {
          toast.error(errorMessage);
        }

      }

    }
    setLoading(false);

  };

  const [driverAgeList, setDriverAgeList] = useState<any>([]);
  /* const ref = useRef(null);
  const ref = useRef(null);
   */
  localStorage.removeItem('booked_reservation_id');
  dispatch(clearVehicleListState());
  dispatch(clearSelectedVehicleState());
  useEffect(() => {

    setValue('pickup_location', selPickupLoc)
    setValue("pickup_oagcode", selPickupOagcode)
    setValue("dropoff_location", selDropoffLoc)
    setValue("dropoff_oagcode", selDropoffOagcode)
    let pickupDate = selPickupDate ? moment(new Date(selPickupDate)).format("YYYY-MM-DD") : "";
    setValue("pickup_date", pickupDate);
    let dropOffDate = selDropoffDate ? moment(new Date(selDropoffDate)).format("YYYY-MM-DD") : "";
    setValue("dropoff_date", dropOffDate)
    setValue("country", country)
    setValue("vendor", vendor)


  }, [selPickupOagcode, selDropoffLoc, selPickupDate, selDropoffDate])

 

  useEffect(() => {
    (async () => {
      const driverAges = getAgeList(country);
      console.log("selPickupOagcode", selPickupOagcode);
    if (driverAges.length) {
      //console.log(driverAges[0].min_age);
      console.log("bookingDefaultData", bookingDefaultData)
      if(bookingDefaultData.driver_age == "") {
        setValue("driver_age", String(driverAges[0].min_age))
      } else {
        var queryData = {
          'code': selPickupOagcode ?? bookingDefaultData.pickup_oagcode, 
          'vendor': bookingDefaultData.vendor
        }

        var response = await getLocationCodeWiseDetail(queryData);
        const { data, status, errorMessage } = response;
        if(status == 201 ) {
          setLocLat(data.latitude);
          setLocLon(data.longitude);
          setName(data.name);
          setAddress(data.address);
          setOperationHour(data.operation_hours);
          
          console.log("location data", data)
        }
        
        setTimeout(() => {
          setValue("driver_age", String(bookingDefaultData.driver_age))  
        }, 1000);
       
      }
      setDriverAgeList(driverAges)
      
    }

      //console.log("profile data",data.output);
    })()

  }, [country]);

   const [autoFilled, setAutoFilled] = useState(false)
   
   
  useEffect(() => {
    
    setTimeout(() => {
        console.log("call of useeffect set searchbooking")
        const searchBooking = String(localStorage.getItem('search_booking'));
        if (searchBooking !== "null" && autoFilled === false) {
          const searchBookingObj = JSON.parse(searchBooking);
          setBookingDefaultData(searchBookingObj)
        }
        
    }, 400);
      return () =>{
        setAutoFilled(true)
      }

  }, []);

  useEffect(() => {
    
    if (bookingDefaultData.pickup_date) {
      const pickupDate = new Date(bookingDefaultData.pickup_date);
      SetSelPickupDate(pickupDate);
    }
    if (bookingDefaultData.dropoff_date) {
      const dropoffDate = new Date(bookingDefaultData.dropoff_date);
      SetSelDropoffDate(dropoffDate);

      let eleDropoffContainer = document.querySelectorAll('input[aria-controls=react-autowhatever-dropoff]');
      if (eleDropoffContainer[0])
        eleDropoffContainer[0].setAttribute("value", bookingDefaultData.dropoff_location);

    }


    SetSelPickupLoc(bookingDefaultData.pickup_location);
    SetSelPickupOagcode(bookingDefaultData.pickup_oagcode);
    SetSelDropoffLoc(bookingDefaultData.dropoff_location);
    SetSelDropoffOagcode(bookingDefaultData.dropoff_oagcode);


    if(bookingDefaultData.pickup_oagcode !== bookingDefaultData.dropoff_oagcode) {
      setDropOfflocation("different");
    }

    setCountry(bookingDefaultData.country);
    
    var isSameLocation = (bookingDefaultData.dropoff_location == bookingDefaultData.pickup_location) ? "same" : "different";
    //console.log("isSameLocation", isSameLocation, bookingDefaultData.dropoff_location, bookingDefaultData.pickup_location);
    return reset(
      {
        //drop_location: bookingDefaultData.drop_location !== "" ? bookingDefaultData.drop_location : "same",
        drop_location: isSameLocation,
        pickup_time: bookingDefaultData.pickup_time.substring(0, 5),
        dropoff_time: bookingDefaultData.dropoff_time,
        pickup_location: bookingDefaultData.pickup_location,
        dropoff_location: bookingDefaultData.dropoff_location,
        pickup_oagcode: bookingDefaultData.pickup_oagcode,
        dropoff_oagcode: bookingDefaultData.dropoff_oagcode,
        pickup_date: bookingDefaultData.pickup_date,
        dropoff_date: bookingDefaultData.dropoff_date,
        country: bookingDefaultData.country,
        vendor: bookingDefaultData.vendor ?? "ZE",
        driver_age: bookingDefaultData.driver_age ?? 30


      }
    );
  }, [reset, bookingDefaultData]);

  useEffect(() => {
    
    setTimeout(() => {
        console.log("call of useeffect set searchbooking")
        const searchBooking = String(localStorage.getItem('search_booking'));
        if (searchBooking !== "null" && autoFilled === false) {
          const searchBookingObj = JSON.parse(searchBooking);
          setBookingDefaultData(searchBookingObj)
        }
        
    }, 400);
      return () =>{
        setAutoFilled(true)
      }

  }, []);

  useEffect(() => {
    (async () => {
    var responseSetting = await getSettingDetail();
        console.log('responseSetting',responseSetting);
        if(responseSetting.status == 201) {
            setSettingImageUrl(responseSetting.data.image_url);
        }
        })()

  }, []);


  return (
    <>
      <Toaster
        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <section className="container-fluid wiz-form-hertz">
        <div className="row">
          <div className="container">
            <div className="container-with-border">
              <div className="tab-hertz">
                <ul className="list-inline d-flex justify-content-between tab-list-hertz">
                  <li className="list-inline-item active">
                    <button type="button">SEARCH</button>
                  </li>
                  <li className="list-inline-item">
                    <button type="button">RESULT</button>
                  </li>
                  <li className="list-inline-item">
                    <button type="button">CUSTOMER INFORMATION</button>
                  </li>
                </ul>
                <div className="tab-wiz-inner-hertz">
                  <div className="tab-inner-hertz" style={{ display: "block" }}>
                    <div className="col-12">
                      <div className="row">
                        <div className="form-wrapper col-8 w-100-tab order-lg-1 order-md-2 order-sm-last" style={{ margin: 0 }}>
                          <form
                            className="spacertopform form_bg"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="bookingForm"
                            id="bookingForm"
                            autoComplete="off"
                            
                            style={{
                                    /*background: `url(${settingImageUrl}) no-repeat`*/
                                    ...settingImageUrl ? {
                                      background: `url(${settingImageUrl})`,
                                      'background-size': 'cover',
                                      'background-position': '50%'
                                    } : {}
                                    
                               }}
                            
                          >
                            <div className="inner-tab-hertz-content">
                              {/* <h2>Create Reservation</h2> */}
                              <div className="main-form-hertz">
                                <div className="row">
                                  <div className="col-5">
                                    <select
                                      className="w-100 where-location dropLocation"
                                      {...register("drop_location")}
                                      defaultValue={bookingDefaultData?.drop_location}
                                      onChange={(e) => { setDropOfflocation(e.target.value); if (e.target.value === "same") { SetSelDropoffLoc(selPickupLoc); SetSelDropoffOagcode(selPickupOagcode) } }}
                                    >
                                      <option value="same">
                                        Same Drop-off Location{" "}
                                      </option>
                                      <option value="different">
                                        Different Drop-off Location
                                      </option>
                                    </select>
                                  </div>

                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <div className="pickup-location-hertz searchAutocomplete modified bg-white">
                                      <label className="label-space-6">
                                        Pick up location<span className="red">*</span>
                                      </label>
                                      {/* <select name="pickup-location">
                                        <option value="0" selected disabled>
                                          Select Location
                                        </option>
                                      </select> 
                                      const user = JSON.parse(localStorage.getItem('user'));
                                      */}
                                        
                                        {
                                          selPickupLoc !== "" ?
                                          <> 
                                          {/* {selPickupLoc}  */}
                                          <ServerAutoSuggest
                                            name={'pickup'}
                                            SetSelPickupLoc={SetSelPickupLoc}
                                            SetSelPickupOagcode={SetSelPickupOagcode}
                                            SetSelDropoffLoc={SetSelDropoffLoc}
                                            SetSelDropoffOagcode={SetSelDropoffOagcode}
                                            setLocLat={setLocLat}
                                            setLocLon={setLocLon}
                                            setCountry={setCountry}
                                            setVendor={setVendor}
                                            setName={setName}
                                            setAddress={setAddress}
                                            setOperationHour={setOperationHour}
                                            defaultValue={selPickupOagcode + ' - ' + selPickupLoc}
                                          />
                                          </>
                                          :
                                          <ServerAutoSuggest
                                            name={'pickup'}
                                            SetSelPickupLoc={SetSelPickupLoc}
                                            SetSelPickupOagcode={SetSelPickupOagcode}
                                            SetSelDropoffLoc={SetSelDropoffLoc}
                                            SetSelDropoffOagcode={SetSelDropoffOagcode}
                                            setLocLat={setLocLat}
                                            setLocLon={setLocLon}
                                            setCountry={setCountry}
                                            setVendor={setVendor}
                                            setName={setName}
                                            setAddress={setAddress}
                                            setOperationHour={setOperationHour}
                                            defaultValue=""
                                          />
                                        }
                                         
                                        


                                    </div>
                                    <span className="formFieldError error">{errors.pickup_oagcode?.message}</span>
                                    <div style={{ "position": "absolute", "left": "-200px", "display": "none" }} >
                                      <input
                                        id="pickup_location"
                                        /* type="hidden"  */
                                        {...register("pickup_location")}
                                        /* defaultValue={selPickupLoc} */
                                        value={selPickupLoc}
                                      /* ref={refPickupLoc} */

                                      />
                                      <input
                                        {...register("pickup_oagcode")}
                                        value={selPickupOagcode}
                                      /* ref={refPickupOagcode} */
                                      />
                                      <input
                                        id="country"
                                        {...register("country")}
                                        value={country}
                                      />
                                      <input
                                        id="vendor"
                                        {...register("vendor")}
                                        value={vendor}
                                      />
                                    </div>
                                  </div>

                                  <div className={`col-6 ${dropOfflocation !== "different" ? "hide" : ""}`}  >
                                    <div className="pickup-location-hertz searchAutocomplete modified bg-white">
                                      <label className="label-space-6">
                                        Drop up location<span className="red">*</span>
                                      </label>
                                      {/* <select name="pickup-location">
                                          <option value="0" selected disabled>
                                            Select Location
                                          </option>
                                        </select> */}
                                      {
                                      selDropoffLoc !== "" ?  
                                      <>
                                      <ServerAutoSuggest
                                        name={'dropoff'}
                                        SetSelPickupLoc={SetSelPickupLoc}
                                        SetSelPickupOagcode={SetSelPickupOagcode}
                                        SetSelDropoffLoc={SetSelDropoffLoc}
                                        SetSelDropoffOagcode={SetSelDropoffOagcode}
                                        setLocLat={setLocLat}
                                        setLocLon={setLocLon}
                                        setCountry={setCountry}
                                        defaultValue={selDropoffOagcode + ' - ' + selDropoffLoc}

                                      />
                                      </>
                                      :
                                      <ServerAutoSuggest
                                        name={'dropoff'}
                                        SetSelPickupLoc={SetSelPickupLoc}
                                        SetSelPickupOagcode={SetSelPickupOagcode}
                                        SetSelDropoffLoc={SetSelDropoffLoc}
                                        SetSelDropoffOagcode={SetSelDropoffOagcode}
                                        setLocLat={setLocLat}
                                        setLocLon={setLocLon}
                                        setCountry={setCountry}
                                        defaultValue=""

                                      />
                                      }   

                                    </div>
                                    <span className="formFieldError error">{errors.dropoff_oagcode?.message}</span>
                                    <div style={{ "position": "absolute", "left": "-300px", "display": "none" }} >
                                      <input
                                        {...register("dropoff_location")}
                                        defaultValue={selDropoffLoc}

                                      />
                                      <input
                                        {...register("dropoff_oagcode")}
                                        defaultValue={selDropoffOagcode}
                                        onChange={(e: any) => SetSelDropoffOagcode(e.target.value)}
                                      />
                                    </div>
                                  </div>

                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <div className="d-flex block-mobile">
                                      <div className="col-8">
                                        <div className="pickup-date-hertz datepickerIcon bg-white">
                                          <label className="label-space-4">
                                            Pick-up date<span className="red">*</span>
                                          </label>
                                          <DatePicker
                                            monthsShown={2}
                                            selected={selPickupDate}

                                            //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                            onChange={(date: any) => { SetSelPickupDate(date); SetSelDropoffDate(null) }}
                                            dateFormat={"dd/MM/yyyy"}
                                            placeholderText="DD/MM/YYYY"
                                            selectsStart
                                            startDate={selPickupDate}
                                            minDate={new Date()}
                                          />
                                          <input
                                            type={"hidden"}
                                            {...register("pickup_date")}
                                            //value={selPickupDate ? moment(new Date(selPickupDate)).format("YYYY-MM-DD") : ""}
                                            defaultValue={selPickupDate ? moment(new Date(selPickupDate)).format("YYYY-MM-DD") : ""}
                                          //ref={refPickupDate}
                                          /* onChange={handlerInput} */
                                          />
                                          <span className="formFieldError error">{errors.pickup_date?.message}</span>
                                        </div>

                                        {/* <span className="formFieldError error">{errors.pickup_date?.message}</span> */}
                                      </div>
                                      <div className="col-4">
                                        <div className="pickup-time-hertz bg-white">
                                          <label className="label-space-6">
                                            Time<span className="red">*</span>
                                          </label>
                                          <select
                                            className="pickup-time dropLocation"
                                            {...register("pickup_time")}
                                            defaultValue={bookingDefaultData?.pickup_time}
                                          >
                                            <option value="00:00">00:00</option>
                                            <option value="00:30">00:30</option>
                                            <option value="01:00">01:00</option>
                                            <option value="01:30">01:30</option>
                                            <option value="02:00">02:00</option>
                                            <option value="02:30">02:30</option>
                                            <option value="03:00">03:00</option>
                                            <option value="03:30">03:30</option>
                                            <option value="04:00">04:00</option>
                                            <option value="04:30">04:30</option>
                                            <option value="05:00">05:00</option>
                                            <option value="05:30">05:30</option>
                                            <option value="06:00">06:00</option>
                                            <option value="06:30">06:30</option>
                                            <option value="07:00">07:00</option>
                                            <option value="07:30">07:30</option>
                                            <option value="08:00">08:00</option>
                                            <option value="08:30">08:30</option>
                                            <option value="09:00">09:00</option>
                                            <option value="09:30">09:30</option>
                                            <option value="10:00">10:00</option>
                                            <option value="10:30">10:30</option>
                                            <option value="11:00">11:00</option>
                                            <option value="11:30">11:30</option>
                                            <option
                                              value="12:00"
                                            >
                                              12:00
                                            </option>
                                            <option value="12:30">12:30</option>
                                            <option value="13:00">13:00</option>
                                            <option value="13:30">13:30</option>
                                            <option value="14:00">14:00</option>
                                            <option value="14:30">14:30</option>
                                            <option value="15:00">15:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="16:00">16:00</option>
                                            <option value="16:30">16:30</option>
                                            <option value="17:00">17:00</option>
                                            <option value="17:30">17:30</option>
                                            <option value="18:00">18:00</option>
                                            <option value="18:30">18:30</option>
                                            <option value="19:00">19:00</option>
                                            <option value="19:30">19:30</option>
                                            <option value="20:00">20:00</option>
                                            <option value="20:30">20:30</option>
                                            <option value="21:00">21:00</option>
                                            <option value="21:30">21:30</option>
                                            <option value="22:00">22:00</option>
                                            <option value="22:30">22:30</option>
                                            <option value="23:00">23:00</option>
                                            <option value="23:30">23:30</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="d-flex block-mobile">
                                      <div className="col-8">
                                        <div className="pickup-date-hertz datepickerIcon bg-white">
                                          <label className="label-space-4">
                                            Return date<span className="red">*</span>
                                          </label>
                                          <DatePicker
                                            monthsShown={2}
                                            selected={selDropoffDate}
                                            //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                            onChange={(date: any) => SetSelDropoffDate(date)}
                                            dateFormat={"dd/MM/yyyy"}
                                            placeholderText="DD/MM/YYYY"
                                            selectsEnd
                                            startDate={selPickupDate}
                                            endDate={selDropoffDate}
                                            minDate={selPickupDate}

                                          />
                                          <input
                                            type="hidden"
                                            value={moment(new Date(selDropoffDate ?? new Date())).format("YYYY-MM-DD") ?? ""}
                                            /* ref={refDropoffDate} */
                                            {...register("dropoff_date")}
                                          /* onChange={(e:any) => SetSelDropoffDate(e.target.value)} */


                                          />
                                          <span className="formFieldError error">{errors.dropoff_date?.message}</span>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="pickup-time-hertz bg-white">
                                          <label className="label-space-4">
                                            Time<span className="red">*</span>
                                          </label>
                                          <select
                                            className="pickup-time dropLocation"
                                            {...register("dropoff_time")}
                                            defaultValue={bookingDefaultData?.dropoff_time}
                                          >
                                            <option value="00:00">00:00</option>
                                            <option value="00:30">00:30</option>
                                            <option value="01:00">01:00</option>
                                            <option value="01:30">01:30</option>
                                            <option value="02:00">02:00</option>
                                            <option value="02:30">02:30</option>
                                            <option value="03:00">03:00</option>
                                            <option value="03:30">03:30</option>
                                            <option value="04:00">04:00</option>
                                            <option value="04:30">04:30</option>
                                            <option value="05:00">05:00</option>
                                            <option value="05:30">05:30</option>
                                            <option value="06:00">06:00</option>
                                            <option value="06:30">06:30</option>
                                            <option value="07:00">07:00</option>
                                            <option value="07:30">07:30</option>
                                            <option value="08:00">08:00</option>
                                            <option value="08:30">08:30</option>
                                            <option value="09:00">09:00</option>
                                            <option value="09:30">09:30</option>
                                            <option value="10:00">10:00</option>
                                            <option value="10:30">10:30</option>
                                            <option value="11:00">11:00</option>
                                            <option value="11:30">11:30</option>
                                            <option
                                              value="12:00"
                                            >
                                              12:00
                                            </option>
                                            <option value="12:30">12:30</option>
                                            <option value="13:00">13:00</option>
                                            <option value="13:30">13:30</option>
                                            <option value="14:00">14:00</option>
                                            <option value="14:30">14:30</option>
                                            <option value="15:00">15:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="16:00">16:00</option>
                                            <option value="16:30">16:30</option>
                                            <option value="17:00">17:00</option>
                                            <option value="17:30">17:30</option>
                                            <option value="18:00">18:00</option>
                                            <option value="18:30">18:30</option>
                                            <option value="19:00">19:00</option>
                                            <option value="19:30">19:30</option>
                                            <option value="20:00">20:00</option>
                                            <option value="20:30">20:30</option>
                                            <option value="21:00">21:00</option>
                                            <option value="21:30">21:30</option>
                                            <option value="22:00">22:00</option>
                                            <option value="22:30">22:30</option>
                                            <option value="23:00">23:00</option>
                                            <option value="23:30">23:30</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-3">
                                    <div className="driver-age-hertz bg-white">
                                      <label className="label-space-6">
                                        Driver's Age<span className="red">*</span>
                                      </label>
                                      <select
                                        className="dropLocation"
                                        {...register("driver_age")}
                                        defaultValue={bookingDefaultData?.driver_age}
                                      >
                                        {
                                          driverAgeList.length &&
                                          driverAgeList.map((driverAge: any, index: number) => {
                                            return <option value={driverAge.min_age} key={index}>{driverAge.age_display}</option>;
                                          })
                                        }
                                      </select>
                                    </div>
                                    <span className="formFieldError error">{errors.driver_age?.message}</span>
                                  </div>
                                  {/* <div className="col-3">
                                    <div className="driver-age-hertz">
                                      <label className="label-space-6">
                                        Payment Type<span className="red">*</span>
                                      </label>
                                      <select
                                        className="dropLocation"
                                        {...register("payment_type")}
                                        defaultValue={bookingDefaultData.driver_age}
                                      >
                                        <option value="vl">Partial Value Voucher</option>
                                        <option value="lc">Local charge</option>
                                        <option value="fc">Full credit</option>
                                      </select>
                                    </div>
                                    <span className="formFieldError error">{errors.payment_type?.message}</span>
                                  </div> */}
                                </div>

                                <div className="col-6 save-btn-group-hertz">
                                  <div className="d-flex">
                                    <button type="submit" className="btn-continue-hertz">
                                      Continue
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-4 w-100-tab order-lg-2 order-sm-first order-md-1 ">
                          <div className="map-image-hertz">
                            <MapQuestDirections zoom={12} lat={locLat} lon={locLon} name={name} address={address} operationHour={operationHour ?? ""} />
                            {/* <img
                              src={mapImage}
                              className="w-100 h-100"
                              alt="map"
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingPage;
