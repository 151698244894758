//import * as React from "react";
import React from "react";
import Loader from "../../components/loading/loader";
import "../../assets/scss/static.scss";

import img1 from "../../assets/images/extra-equipmnt-img.jpg";
import plus from "../../assets/svg/plus-icon.svg";
import minus from "../../assets/svg/minus-icon.svg";

const OperationExtra = () => {
  const accordionHander = (e:any) => {
    var el = document.querySelectorAll(".accordian-box");
    const element = e.target.closest(".accordian-box");
    element.classList.toggle("open");
    /* for (let i = 0; i < el.length; i++) {
      el[i].classList.remove("open");
    } */
    /* if (element.classList.contains("open")) {
      element.classList.remove("open");
    } else if (!element.classList.contains("open")) {
      element.classList.add("open");
    } else {
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove("open");
      }
    } */
  };

  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid static-pages">
        <div className="row">
          <div className="operation-extra-section">
            <section className="extra-equip-section">
              <h2 className="section-tittle">Optional Extras</h2>
              <div className="extra-equip-inner">
                <div className="extra-equip-img extra-equip-box">
                  <img src={img1} alt="" />
                </div>
                <div className="extra-equip-text extra-equip-box">
                  <h3 className="tittle-name">Extra Equipment</h3>
                  <p className="extra-msg-txt">
                    Hertz offers a wide range of optional extras and rental
                    enhancements, all aimed at making your clients rental a
                    stress free and enjoyable experience.
                  </p>
                </div>
              </div>
            </section>
            <section className="operation-accordian-section">
              <div className="operation-accordian-inner">
                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">NeverLost</h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>

                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">Baby and Child Safety Seats</h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">
                      Winter Tyres and Snow Chains
                    </h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">Steering Wheel Controls</h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">Roof Racks</h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">
                      Sirius Satellite Radio (only U.S. and Canada)
                    </h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">PlatePass (only U.S.)</h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">Telerent (only in Italy)</h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        Instant location: it detects the exact car's location at
                        any time and immediately re-computes the indications in
                        case the user takes a detour
                      </li>
                      <li>
                        Turn by turn voice and visual guidance: an accurate
                        recorded voice and always updated maps (also available
                        as 3D view), mainly used by those car drivers that need
                        to focus their attention to the road.
                      </li>
                      <li>
                        Quick Spell: its easy-to-use colour touch screen display
                        allows to select the destination with a simple touch of
                        the display.
                      </li>
                      <li>
                        Customizable options: it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        Create and save an itinerary: it advises your clients to
                        create their own trip planning on the www.neverlost.com
                        website, saving it to a USB Drive and uploading it on
                        the Hertz NeverLost System when they pick up the car, in
                        order to save time at their arrival.
                      </li>
                      <li>
                        Multilanguage: it speaks English + 10 more languages.
                      </li>
                      <li>
                        Weather and flights: always updated information on
                        flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`accordian-box`}>
                  <div
                    className="acoordian-head"
                    onClick={(e) => accordionHander(e)}
                  >
                    <h3 className="tittle-name">Hertz MiFi</h3>
                    <div className="sign-box">
                      <img className="open-sign" src={plus} alt="" />
                      <img className="close-sign" src={minus} alt="" />
                    </div>
                  </div>
                  <div className="accordian-content">
                    <ul>
                      <li>
                        <b>Instant location:</b> it detects the exact car's
                        location at any time and immediately re-computes the
                        indications in case the user takes a detour
                      </li>
                      <li>
                        <b>Turn by turn voice and visual guidance:</b> an
                        accurate recorded voice and always updated maps (also
                        available as 3D view), mainly used by those car drivers
                        that need to focus their attention to the road.
                      </li>
                      <li>
                        <b>Quick Spell:</b> its easy-to-use colour touch screen
                        display allows to select the destination with a simple
                        touch of the display.
                      </li>
                      <li>
                        <b>Customizable options:</b> it allows you to choose the
                        shortest routes and shows the routes to be avoided.
                      </li>
                      <li>
                        <b>Create and save an itinerary:</b> it advises your
                        clients to create their own trip planning on the
                        www.neverlost.com website, saving it to a USB Drive and
                        uploading it on the Hertz NeverLost System when they
                        pick up the car, in order to save time at their arrival.
                      </li>
                      <li>
                        <b>Multilanguage:</b> it speaks English + 10 more
                        languages.
                      </li>
                      <li>
                        <b>Weather and flights:</b> always updated information
                        on flights and weather conditions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default OperationExtra;