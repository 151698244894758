import React, { useEffect, useState } from "react";
import userImg from "../../assets/svg/users.svg";
import { getDashboardReservationsTypeCounts } from "../../external_api/Reservation";



const Listitem = (props:any) => {
  const [listitemData, setListitemData] = useState([]);
  useEffect(() => {
    (async () => {
        //setLoading(true);
        var response = await getDashboardReservationsTypeCounts();
        var { data, status } = response;
          console.log(data.data);
        if (status === 201) {
          setListitemData(data);  
        } 
        //setLoading(false);

        //console.log("profile data",data.output);
    })()

}, []);

  return (
    <>
    <section className="counters">
        {listitemData.length !== 0
          ? listitemData.map((item:any, i:number) => (
              <div className="small-cards" key={i}>
                <div>
                  <img src={userImg} alt="icon" />
                  <h2 className="title">{item.total}</h2>
                  <p className="subtitle">{item.reservation_type}</p>
                </div>
              </div>
            ))
          : ""}
      </section>
      {/* <section className="counters">
        {props.listitemData.length !== 0
          ? props.listitemData.map((item:any, i:number) => (
              <div className="small-cards" key={i}>
                <div>
                  <img src={userImg} alt="icon" />
                  <h2 className="title">{item.numberData}</h2>
                  <p className="subtitle">{item.title}</p>
                </div>
              </div>
            ))
          : ""}
      </section> */}
    </>
  );
};

export default Listitem;
