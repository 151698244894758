import React, { useState } from "react";
//import closeIcon from "../../../assets/svg/cross-icon.svg";
import closeIcon from "../../assets/svg/cross-icon.svg";
import "../../assets/scss/booking.scss";
import { cancelReservation } from "../../external_api/Booking";
import toast, { Toaster } from 'react-hot-toast';

const CancelBooking = ({closeHandler, reservation_id, loaderHandler}:{closeHandler:any, reservation_id:any, loaderHandler:any}) => {
  
  
  const cancelHandler = async () => {
      //alert(reservation_id);
      loaderHandler(true);
    var response = await cancelReservation(reservation_id);
    const { data, status, errorMessage } = response;
    console.log("reservation cancel data", data);
    if (status == 201) {
      loaderHandler(false);
      closeHandler();
      toast.success("Booking has been cancelled successfully"); 
      
    } else {
      if (errorMessage.errors) {
        //setServerError(errorMessage.errors.ShortText);
        toast.error(errorMessage.errors.ShortText);
      } else {
        toast.error(errorMessage);
        closeHandler();
        //setServerError(errorMessage);
      }

    }
    loaderHandler(false);

  }


  return (
    <>
    <Toaster
        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      <div className={`modal-box-countainer infoPoup`}>
        <div className="spacerLR_model">
          <div className="modal-box">
            <div className="modal-body-box extra-fee-modal">
              <button className="close-btn btn">
                <img src={closeIcon} alt="close icon" onClick={closeHandler} />
              </button>
              <h2 className="etra-fee-title">Cancellation Reservation</h2>
              <div className="height-setting">
                <div className="s-scroll">
                  <div className="extra-fee-inner">
                    <p>
                      <div className="actions">Do you want to cancel reservation {reservation_id}? 
                        <button className="btn" onClick={cancelHandler} style={{backgroundColor: "#ffcc00", fontSize: "12px"}}>Yes</button> 
                        <button className="btn" onClick={closeHandler} style={{fontSize: "12px"}}>No</button></div> 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelBooking;
