//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getExtraDetail = async (extraId:any)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.get(`/extras/${extraId}`);
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}

const getExtraList = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/extras/all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const getExtras = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string, searchField?:string) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/extras?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      if(searchField) {
          /* requestUrl = requestUrl + "&like[0]="+searchField+ ',' + searchTerm;
          requestUrl = requestUrl + "&like[1]=status,1"; */
          requestUrl = requestUrl + "&country="+searchTerm;
          requestUrl = requestUrl + "&status=1";
      } else {
        requestUrl = requestUrl + "&like_or[0]=code"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[1]=currency"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[2]=country"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[3]=vendor"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[4]=cost_daily"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[5]=cost_weekly"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[6]=cost_max"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[7]=content"+ ',' + searchTerm;
        requestUrl = requestUrl + "&like_or[7]=status"+ ',' + searchTerm;
      }
      
      
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}


const addExtra = async (rateData: any) => {
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/extras`, rateData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateExtra = async (rateData: any, extraId:number) => {
  
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.put(`/extras/${extraId}`, rateData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}




export {getExtraDetail, getExtraList, getExtras, addExtra, updateExtra}