//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getLanguageDetail = async (languageId:any)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.get(`/languages/${languageId}`);
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}

const getLanguageList = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/languages/all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const getLanguages = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/languages?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=code"+ ',' + searchTerm;
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const addLanguage = async (LanguageData: any) => {
  console.log(LanguageData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/languages`, LanguageData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateLanguage = async (LanguageData: any, LanguageId:number) => {
  console.log(LanguageData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.put(`/languages/${LanguageId}`, LanguageData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}




export {getLanguageDetail, getLanguageList, getLanguages, addLanguage, updateLanguage}