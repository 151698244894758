const Routes = {
  home: "/",
  forget_password:"/forget-password",
  change_password:"/change-password",
  reset_password:"/reset-password",
  dashboard:"/dashboard",
  profile:"/profile",
  user:"/user",
  company:"/company",
  location:"/location",
  rate:"/rate",
  extra:"/extra",
  staticpage:"/staticpage",
  about:"/about",
  our_team:"/our-team",
  customer_service:"/customer-service",
  help_manual:"/help-manual",
  operation_extra:"/operation-extra",
  rate_condition:"/rate-condition",
  information_page:"/information",
  booking_list:"/travel/list",
  booking:"/booking",
  booking_edit:"/booking/edit",
  vehicle_list:"/booking/vehicle-list",
  review_and_book:"/booking/review-book",
  confirmation:"/booking/confirmation",
  user_list:{
    add:"/user/add",
    edit:"/user/edit",
  },
  company_list:{
    add:"/company/add",
    edit:"/company/edit",
  },
  location_list:{
    add:"/location/add",
    edit:"/location/edit",
    upload:"/location/upload",
    uploadStatus:"/location/upload-status",
  },
  rate_list:{
    add:"/rate/add",
    edit:"/rate/edit",
  },
  extra_list:{
    add:"/extra/add",
    edit:"/extra/edit",
  },
  staticpage_list:{
    add:"/staticpage/add",
    edit:"/staticpage/edit",
  },
  vehicle_module_list:{
    add:"/vehicle/add",
    edit:"/vehicle/edit",
    upload:"/vehicle/upload",
    uploadLocationExcess:"/vehicle/upload-location-excess",
  },
  setting_list:{
    add:"/setting/add",
    edit:"/setting/edit",
    list:"/setting",
  },
  notfound:"*"
};

export default Routes;
