/* import * as React from "react"; */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getCountryList, getUserDetials, updateUser } from "../../external_api/User";
import { addCompany, getCompanyDetail, getCompanyList, updateCompany } from "../../external_api/Company";
import { getRoleList } from "../../external_api/User";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { companyTypeList, paymentTypeList } from "../../constants/StaticData";


const AddCompany = () => {

  const { isFetching, isSuccess: isLogin } = useSelector(
    userSelector
  );


  const navigate = useNavigate();
  if (!isLogin) {
    navigate("/");
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    code: Yup.string().nullable(),
    vat: Yup.string().nullable(),
    type: Yup.string()
      .required('Type is required'),
    payment_type: Yup.array()
      .min(1, 'Select at least one type'),
    parent_id: Yup.string()
      .required('Company is required'),
    address: Yup.string()
      .required('Address is required'),
    country: Yup.string()
      .required('Country is required'),
    city: Yup.string()
      .required('City is required'),
    fax: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    phone: Yup.string()
      .required('Phone is required'),
    email: Yup.string()
      .email()
      .required('Email is required')
      .email('E-mail is not valid!'),
    special_fare: Yup.string().nullable(),
    status: Yup.string()

  });


  const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
  type FormValues = {
    name: string,
    code: string,
    type: string,
    vat: string,
    parent_id: string,
    payment_type: Array<number>,
    address: string,
    country: string,
    city: string,
    phone: string,
    email: string,
    fax: string,
    zip: string,
    special_fare: string,
    status: string,
  }
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


  const [loading, setLoading] = useState(false);

  const [companyDetail, setCompanyDetail] = useState(
    {
      name: "",
      code: "",
      type: "",
      vat: "",
      parent_id: "",
      payment_type: [],
      address: "",
      country: "",
      city: "",
      phone: "",
      email: "",
      fax: "",
      zip: "",
      special_fare: "",
      status: "1",

    }
  );
  const paramData = useParams();
  const [companyId, setCompanyId] = useState(paramData.id);

  //const userId = paramData.

  const [isSubmitForm, setSubmitForm] = useState(false);

  const [loginResMsg, setLoginResMsg] = useState({
    status: false,
    msg: ""
  });

  const [inputUserName, setUserName] = useState("");


  // Login Form Submit Handler

  const onSubmitForm = async (formData: any) => {
    console.log("formData", formData, companyId);
    //return true;
    setLoading(true);
    var response = await addCompany(formData);
    const { data, status, errorMessage } = response;
    console.log(response);
    if (status == 201) {
      setLoading(false);
      toast.success("Company has been created successfully");
      setTimeout(() => {
        navigate("/company");  
    }, 500);
    } else {
      toast.error(errorMessage);
    }
    setLoading(false);

  };

  const [countryList, setCountryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [roleList, setRoleList] = useState([]);


  useEffect(() => {
    (async () => {
      setLoading(true);
      var responseCountry = await getCountryList();
      var { data, status } = responseCountry;
      //console.log("data",data[0])
      if (status === 200) {
        setCountryList(data);
      }

      var responseCompany = await getCompanyList();
      var { data, status } = responseCompany;
      //console.log("data",data[0])
      if (status === 200) {
        setCompanyList(data);
      }
      var responseRole = await getRoleList();
      console.log("profile responseRole", responseRole)
      var { data, status } = responseRole;
      if (status === 200) {
        setRoleList(data);

      }

      setLoading(false);

      //console.log("profile data",data.output);
    })()

  }, []);

  useEffect(() => {
    return reset(
      {

        name: companyDetail.name,
        code: companyDetail.code,
        type: companyDetail.type,
        vat: companyDetail.vat ?? "",
        parent_id: companyDetail.parent_id,
        payment_type: companyDetail.payment_type,
        address: companyDetail.address,
        country: companyDetail.country,
        city: companyDetail.city,
        phone: companyDetail.phone,
        email: companyDetail.email,
        fax: companyDetail.fax,
        zip: companyDetail.zip,
        special_fare: companyDetail.special_fare,
        status: companyDetail.status,
      }
    );
  }, [reset, companyDetail]);





  //console.log(post)

  return (
    <>
      <Toaster

        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <Titlestrip title={"Edit Company"} />
      <div className="form-wrapper container-fluid">
        <div className="row">
          <div className="container">


            <form
              className="spacertopform"
              onSubmit={handleSubmit(onSubmitForm)}
              name="userEdit"
              id="userEdit"
              autoComplete="off"
            >
              {
                loginResMsg.msg ?
                  <div className="col-12">
                    <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                      {loginResMsg.msg}
                    </div>
                  </div>
                  :
                  ""
              }

              <div className="row">
                <div className="col-md-3 col-12" style={{"display":"none"}}>
                  <div className="custom-field">  
                    <label>Status<span className="red">*</span></label>
                    <div className="select">
                      <select
                        {...register("status")}
                        defaultValue={1}
                      >
                        <option value="1">Enabled</option>
                        <option value="0">Disabled</option>

                      </select>
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.status?.message}</span>
                </div>

                <div className="col-md-4 col-12">
                  <div className="custom-field">
                    <label>Type<span className="red">*</span></label>
                    <div className="select">
                      <select
                        {...register("type")}
                        defaultValue={companyDetail.type}
                      >
                        {
                          companyTypeList.map((companyType: any, index) => {
                            return <option value={companyType.code}>{companyType.name}</option>
                          })
                        }

                      </select>
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.type?.message}</span>
                </div>
                <div className="col-md-4 col-12">
                  <div className="custom-field ">
                    <label>Code</label>
                    <div className="text">
                      <input
                        type="text"
                        className={`input-field ${errors.code ? 'is-invalid' : ''}`}
                        {...register("code")}
                        disabled={true}
                        defaultValue={companyDetail.code}
                      />
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.code?.message}</span>
                </div>
                <div className="col-md-4 col-12">
                  <div className="custom-field ">
                    <label>VAT</label>
                    <div className="text">
                      <input
                        type="text"
                        className={`input-field ${errors.vat ? 'is-invalid' : ''}`}
                        {...register("vat")}
                        defaultValue={companyDetail.vat}

                      />
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.vat?.message}</span>
                </div>
              </div>



              <div className="gray-bg">
                <h2>Payment type<span className="red">*</span></h2>
                <div className="d-flex flex-wrap">
                  {

                    paymentTypeList.map((paymentType: any, index) => {
                      //var isChecked = (userDetail.profile.find(profile.id) !== undefined) ? true : false;
                      return (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={paymentType.code}
                            id="localCharge"
                            //checked={isChecked}
                            //checked={true}
                            {...register(`payment_type`)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`payment_type`}
                          >
                            {paymentType.name}
                          </label>
                        </div>
                      )

                    })
                  }
                </div>

              </div>
              <span className="formFieldError error">{errors.payment_type?.message}</span>
              <div className="row">

                <div className="col-md-6 col-12">
                  <div className="custom-field">
                    <label>Parent<span className="red">*</span></label>
                    <div className="text">
                      <select
                        {...register("parent_id")}
                        defaultValue={companyDetail.parent_id}
                      >
                        {companyList.length > 0 ?
                          companyList.map((company: any, index) => {
                            return <option value={company.id} key={index}>{company.name}</option>;
                          })
                          :
                          ""


                        }
                      </select>

                    </div>
                  </div>
                  <span className="formFieldError error">{errors.parent_id?.message}</span>
                </div>
                <div className="col-md-6 col-12">
                  <div className="custom-field">
                    <label>Name<span className="red">*</span></label>
                    <div className="text">
                      <input
                        type="text"
                        {...register("name")}
                        defaultValue={companyDetail.name}
                      />
                    </div>

                  </div>
                  <span className="formFieldError error">{errors.name?.message}</span>
                </div>

              </div>
              <div className="row">

                <div className="col-md-6 col-12">
                  <div className="custom-field">
                    <label>Address<span className="red">*</span></label>
                    <div className="text">
                      <input

                        type="text"
                        {...register("address")}
                        defaultValue={companyDetail.address}

                      />
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.address?.message}</span>
                </div>
                <div className="col-md-2 col-12">
                  <div className="custom-field">
                    <label>City<span className="red">*</span></label>
                    <div className="text">
                      <input
                        type="text"
                        {...register("city")}
                        defaultValue={companyDetail.city}

                      />
                    </div>

                  </div>
                  <span className="formFieldError error">{errors.city?.message}</span>
                </div>
                <div className="col-md-2 col-12">
                  <div className="custom-field">
                    <label>EIRCODE</label>
                    <div className="text">
                      <input
                        type="text"
                        {...register("zip")}
                        defaultValue={companyDetail.zip}

                      />
                    </div>

                  </div>
                  <span className="formFieldError error">{errors.zip?.message}</span>
                </div>
                <div className="col-md-2 col-12">
                  <div className="custom-field">
                    <label>Country<span className="red">*</span></label>
                    <div className="select">
                      <select
                        {...register("country")}
                        defaultValue={companyDetail.country ?? ""}
                      >
                        <option value="">Select</option>
                        {
                          countryList.map((country: any, index) => {

                            return <option value={country.code} key={index}>{country.name}</option>
                          })

                        }


                      </select>
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.country?.message}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="custom-field">
                    <label>Email<span className="red">*</span></label>
                    <div className="text">
                      <input
                        type="text"
                        {...register("email")}
                        defaultValue={companyDetail.email}

                      />
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.email?.message}</span>
                </div>
                <div className="col-md-2 col-12">
                  <div className="custom-field">
                    <label>Phone</label>
                    <div className="text">
                      <input
                        type="text"
                        {...register("phone")}
                        defaultValue={companyDetail.phone}

                      />
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.phone?.message}</span>
                </div>

                <div className="col-md-2 col-12">
                  <div className="custom-field">
                    <label>Fax</label>
                    <div className="text">
                      <input
                        type="text"
                        {...register("fax")}
                        defaultValue={companyDetail.fax}

                      />
                    </div>
                  </div>
                  <span className="formFieldError error">{errors.fax?.message}</span>
                </div>
                <div className="col-md-2 col-12">
                  <div className="custom-field">
                    <label>Special Fare</label>
                    <div className="select">
                      <select
                        {...register("special_fare")}
                        defaultValue={companyDetail.special_fare}
                      >
                        <option value="">No</option>
                        <option value="SPE">Special Fare</option>
                      </select>
                    </div>

                  </div>
                  <span className="formFieldError error">{errors.special_fare?.message}</span>
                </div>

              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <input type="submit" value="Save" className="btn-yellow" />
                </div>
              </div>
            </form>



          </div>
        </div>
      </div>

    </>
  )



}


export default AddCompany;


