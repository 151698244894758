import * as React from "react";
import Loader from "../../components/loading/loader";
import "../../assets/scss/static.scss";
import customer_service_img from "../../assets/images/customer-top-srvc-img.jpg";
const CustomerService = () => {
  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid static-pages">
        <div className="row">
          <div className="customer-srvc-wrapper">
            <section className="customer-srvc-section">
              <div className="customer-srvc-inner">
                <div className="extra-equip-text extra-equip-box">
                  <h3 className="tittle-name section-tittle-mb">
                    Customer Service
                  </h3>
                  <p className="extra-msg-txt">
                    Should you have any customer service issues, please contact
                    us on 053 91 52 500 or by emailing{" "}
                    <a href="mailto:customerservices@hertz.ie">
                      customerservices@hertz.ie
                    </a>
                    and copying{" "}
                    <a href="mailto:traveltrade@hertz.ie">
                      traveltrade@hertz.ie
                    </a>{" "}
                    on the email.
                  </p>
                  <p className="extra-msg-txt">
                    Our customer service team will do their very best to assist
                    you in a prompt and efficient manner
                  </p>
                  <p className="extra-msg-txt">
                    Our opening hours are Monday to Friday (excluding bank
                    holidays) 9:00am to 13:00pm and 14:00pm to 17:30pm
                  </p>
                </div>
                <div className="extra-equip-img extra-equip-box">
                  <img src={customer_service_img} alt="" />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerService;
