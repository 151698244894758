import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import userReducer from './slices/user';
import vehicleAvailableReducer from './slices/vehicleAvailable';
import vehicleSelectedReducer from './slices/selectedVehicle';
import extraSelectedReducer from './slices/extras';

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  // if you do not want to persist this part of the state
  //blacklist: ['omitedPart']
};


const reducer = combineReducers({
  user: userReducer,
  vehicleAvailable: vehicleAvailableReducer,
  vehicleSelected: vehicleSelectedReducer,
  extraSelected: extraSelectedReducer
  
  // not persisting this reducer
  //omitedPart:OmitReducer
});
// this ensures your redux state is saved to persisted storage whenever it changes
// we pass this to the store
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
});

/* const store = configureStore({
  reducer: {
    user: userReducer,
  },
}) */


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store
