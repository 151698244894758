import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./global.scss";
import Navbar from "./components/header/Navbar";
import FooterModule from "./components/footer/Index";
import LoginModule from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
//import Dashboard from "./pages/dashboard";
import About from "./pages/static/About";
import Company from "./pages/company";
import AddCompany from "./pages/company/add";
import EditCompany from "./pages/company/edit";
import Location from "./pages/location";
import NotFound from "./pages/404/notfound";
import configRoutes from "../src/config/Routes";
import ChangePassword from "./pages/auth/ChangePassword";
import Team from "./pages/static/Team";
import CustomerService from "./pages/static/CustomerService";
import HelpManual from "./pages/static/HelpManual";
import OperationExtra from "./pages/static/OperationExtra";
import RateCondition from "./pages/static/RateCondition";
import User from "./pages/user";
import AddUser from "./pages/user/add";
import EditUser from "./pages/user/edit";
import Profile from "./pages/user/profile";
import ResetPassword from "./pages/auth/ResetPassword";
import Rate from "./pages/rate";
import StaticPage from "./pages/static-page";
import AddLocation from "./pages/location/add";
import EditLocation from "./pages/location/edit";
import UploadLocation from "./pages/location/upload";
import UploadLocationStatus from "./pages/location/upload-status";



import EditStaticPage from "./pages/static-page/edit";
import AddStaticPage from "./pages/static-page/add";
import EditRate from "./pages/rate/edit";
import AddRate from "./pages/rate/add";
import DashboardNew from "./pages/dashboard/Dashboardnew";
import InformationPage from "./pages/static/Information";

import BookingPage from "./pages/booking/BookingPage";
import VehicleList from "./pages/booking/VehicleList";
import EditExtra from "./pages/extra/edit";
import AddExtra from "./pages/extra/add";
import Extra from "./pages/extra";
import CustomerInformation from "./pages/booking/CustomerInformation";
import Confirmation from "./pages/booking/Confirmation";
import Reservation from "./pages/reservation";
import BookingEdit from "./pages/booking/BookingEdit";
import CustomerInformationEdit from "./pages/booking/CustomerInformationEdit";


import UploadVehicle from "./pages/vehicle/upload";
import UploadVehicleLocationExcess from "./pages/vehicle/uploadLocationExcess";

import AddSetting from "./pages/setting/add";
import EditSetting from "./pages/setting/edit";
import Setting from "./pages/setting";


//import { Provider } from 'react-redux'
//import store from './store'



function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path={configRoutes.home} element={<LoginModule />} />
          <Route
            path={configRoutes.forget_password}
            element={<ForgetPassword />}
          />
          <Route
            path={configRoutes.change_password}
            element={<ChangePassword />}
          />
          <Route
            path={`${configRoutes.reset_password}/:token`}
            element={<ResetPassword />}
          />


          <Route path={configRoutes.dashboard} element={<DashboardNew />} />
          <Route path={configRoutes.profile} element={<Profile />} />
          <Route path={configRoutes.user} element={<User />} />
          <Route
            path={configRoutes.user_list.add}
            element={<AddUser />}
          />
          <Route
            path={`${configRoutes.user_list.edit}/:id`}
            element={<EditUser />}
          />
          <Route path={configRoutes.company} element={<Company />} />
          <Route
            path={configRoutes.company_list.add}
            element={<AddCompany />}
          />
          <Route
            path={`${configRoutes.company_list.edit}/:id`}
            element={<EditCompany />}
          />
          <Route path={configRoutes.location} element={<Location />} />
           <Route
            path={configRoutes.location_list.add}
            element={<AddLocation />}
          />
          <Route
            path={`${configRoutes.location_list.edit}/:id`}
            element={<EditLocation />}
          />

        <Route
            path={`${configRoutes.location_list.upload}`}
            element={<UploadLocation />}
          />

        <Route
            path={`${configRoutes.location_list.uploadStatus}`}
            element={<UploadLocationStatus />}
          />  

        <Route
            path={`${configRoutes.vehicle_module_list.upload}`}
            element={<UploadVehicle />}
          />    LocationExcess  


          <Route
            path={`${configRoutes.vehicle_module_list.uploadLocationExcess}`}
            element={<UploadVehicleLocationExcess />}
          />

          <Route path={configRoutes.rate} element={<Rate />} />
          <Route
            path={configRoutes.rate_list.add}
            element={<AddRate />}
          />
          <Route
            path={`${configRoutes.rate_list.edit}/:id`}
            element={<EditRate />}
          />

          <Route path={configRoutes.extra} element={<Extra />} />
          <Route
            path={configRoutes.extra_list.add}
            element={<AddExtra />}
          />
          <Route
            path={`${configRoutes.extra_list.edit}/:id`}
            element={<EditExtra />}
          /> 

          <Route path={configRoutes.staticpage} element={<StaticPage />} />
          <Route
            path={configRoutes.staticpage_list.add}
            element={<AddStaticPage />}
          />
          <Route
            path={`${configRoutes.staticpage_list.edit}/:id`}
            element={<EditStaticPage />}
          />

          <Route
            
            path={`${configRoutes.information_page}/:slug`}
            element={<InformationPage />}
            
          />
          <Route
            
            path={`${configRoutes.information_page}/:slug/privilege/:privilege_type`}
            element={<InformationPage />}
            
          />
          {/* <Route
            
            path={`${configRoutes.information_page}/:slug/privilege/:privilege-type`}
            element={<InformationPage />}
            
          /> */}


          <Route path={configRoutes.about} element={<About />} />
          <Route path={configRoutes.our_team} element={<Team />} />
          <Route
            path={configRoutes.customer_service}
            element={<CustomerService />}
          />
          <Route path={configRoutes.help_manual} element={<HelpManual />} />
          <Route
            path={configRoutes.operation_extra}
            element={<OperationExtra />}
          />
          <Route
            path={configRoutes.rate_condition}
            element={<RateCondition />}
          />



          <Route path={configRoutes.booking} element={<BookingPage />} />
          <Route path={configRoutes.vehicle_list} element={<VehicleList />} />
          <Route path={configRoutes.review_and_book} element={<CustomerInformation />} />
          <Route path={configRoutes.confirmation} element={<Confirmation />} />

          <Route path={configRoutes.booking_list} element={<Reservation />} />


          <Route
          path={`${configRoutes.vehicle_list}/edit/:reservationId`} 
          element={<VehicleList />} 
          />

          <Route 
          path={`${configRoutes.booking_edit}/:reservationId`}
          element={<BookingEdit />} 
          />

          <Route 
          path={`${configRoutes.review_and_book}/:reservationId`}
          element={<CustomerInformationEdit />} 
          />
          
          <Route path={configRoutes.setting_list.list} element={<Setting />} />
          <Route
            path={configRoutes.setting_list.add}
            element={<AddSetting />}
          />
          <Route
            path={`${configRoutes.setting_list.edit}/:id`}
            element={<EditSetting />}
          />


          <Route path={configRoutes.notfound} element={<NotFound />} />
        </Routes>
        <FooterModule />
      </Router>
      
    </div>
  );
}

export default App;
