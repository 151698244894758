import * as React from "react";
import Loader from "../../components/loading/loader";
import "../../assets/scss/static.scss";
import img_1 from "../../assets/images/extra-equipmnt-img.jpg";
import img_2 from "../../assets/images/business-image.jpg";
import img_3 from "../../assets/images/pay-local-image.jpg";

const RateCondition = () => {
  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid static-pages">
        <div className="row">
          <div className="rate-condtn-wrapper">
            <h2 className="section-tittle section-tittle-mb">
              Rates and Conditions
            </h2>
            <section className="rate-condtn-section cmn-section">
              <div className="top-head-text">
                <h2 className="section-head top-head-text-mb">
                  Hertz Business +
                </h2>
                <p className="sm-text">
                  Hertz Business + is an all inclusive product created to
                  satisfy the rental needs of small and medium enterprises.
                </p>
                <p>
                  N.B. Please note that the HB+ is not available on account for
                  US bookings and these must be paid for locally by the renter
                  by selecting local payment as the preferred method of payment
                  as if not the system will not allow you to finalise the
                  booking.
                </p>
              </div>
              <div className="rate-condtn-inner flex-reverse-cls">
                <div className="extra-equip-text extra-equip-box">
                  <p className="rate-msg-txt">
                    If you choose Hertz Business+ for your clients, you will
                    enjoy:
                  </p>

                  <div className="rate-condtn-list">
                    <ul>
                      <li>
                        A major brand, offering a reliable service Globally.
                      </li>
                      <li>
                        A wide range of vehicles thanks to one of the most
                        diverse rental fleets, and the highest availability -
                        even at the last minute.
                      </li>
                      <li>
                        An "All Inclusive" product, including: insurance and
                        full coverage for damages or theft, additional driver,
                        unlimited mileage (except for Italy where mileage is
                        limited), government tax (VAT) and airport service
                        charges;
                      </li>
                      <li>
                        Fixed rates all year round, independent from seasonal
                        period (in Europe, seasonal surcharges do apply in the
                        U.S)
                      </li>
                      <li>Free changes and cancellations.</li>
                    </ul>
                  </div>
                </div>
                <div className="extra-equip-img extra-equip-box">
                  <img src={img_1} alt="" />
                </div>
              </div>
            </section>
            <section className="rate-condtn-section cmn-section">
              <div className="top-head-text">
                <h2 className="section-head top-head-text-mb">
                  Business Rates
                </h2>
                <p className="sm-text">
                  Business Rates in Europe, UAE and South Africa: inclusive of
                  unlimited mileage, civil liability, Collision Damage Waiver
                  (CDW) and Theft Protection (TP), Super Collision Damage Waiver
                  (SCDW), government tax (VAT) and local taxes, airport service
                  charges, additional driver and, in some countries, Personal
                  Accident Insurance (PAI).
                </p>
              </div>
              <div className="rate-condtn-inner">
                <div className="extra-equip-img extra-equip-box">
                  <img src={img_2} alt="" />
                </div>
                <div className="extra-equip-text extra-equip-box">
                  <p className="rate-msg-txt">
                    Business Rates in the USA: inclusive of unlimited mileage,
                    Liability Insurance Supplement (LIS), Loss Damage Waiver
                    (LDW), additional driver, airport service charges and taxes.
                  </p>
                  <p className="rate-msg-txt">
                    Flexible Rates: the best available dynamique rates available
                    at the time of reservation, including standard coverage and
                    rental fees, inclusions may vary and will be verified at
                    time of quotation.
                  </p>
                </div>
              </div>
            </section>
            <section className="rate-condtn-section cmn-section">
              <div className="top-head-text">
                <h2 className="section-head top-head-text-mb">
                  Rental Requirements
                </h2>
                <p className="sm-text">
                  At the time of collection, the driver must present a valid
                  national driver's license, which has been held for at least 1
                  year (or more years in some countries), and a credit card
                  (electronic and prepaid credit cards are not accepted).
                </p>
              </div>
              <div className="rate-condtn-inner flex-reverse-cls">
                <div className="extra-equip-text extra-equip-box">
                  <p className="rate-msg-txt">
                    The credit card must be in the name of the renter and there
                    must be sufficient credit on the card to cover, any
                    additional charges or deposits requested as guarantee for
                    the rental.
                  </p>
                  <p className="rate-msg-txt">
                    The minimum rental age is 25, some countries will allow
                    rentals to younger drivers (21-23 years old depending on the
                    country of rental) by enforcing a young driver surcharge.
                  </p>
                  <p className="rate-msg-txt">
                    Certain vehicles categories will command a higher minimum
                    age for car rental. In some countries, like Australia, a
                    maximum age for car rental may apply. Please contact us for
                    more information.
                  </p>
                </div>
                <div className="extra-equip-img extra-equip-box">
                  <img src="images/rental-image.jpg" alt="" />
                </div>
              </div>
            </section>
            <section className="rate-condtn-section cmn-section">
              <div className="top-head-text">
                <h2 className="section-head top-head-text-mb">
                  Pay Local Rates
                </h2>
                <p className="sm-text">
                  For people preferring to pay locally, the best available rates
                  will be showen during the reservation process, inclusive of
                  standard coverage and rental charges (conditions may vary
                  according to the reservation period and the destination,
                  please verify before making the reservation).
                </p>
                <p className="sm-text">
                  Pay Local rates are available in most major countries
                  worldwide.
                </p>
              </div>
              <div className="rate-condtn-inner">
                <div className="extra-equip-img extra-equip-box">
                  <img src={img_3} alt="" />
                </div>
                <div className="extra-equip-text extra-equip-box">
                  <p className="rate-msg-txt">
                    <b>Rates in local currency:</b> the most convenient rate
                    available at reservation;
                  </p>
                  <p className="rate-msg-txt">
                    <b>Flexibility:</b> possibility to change, cancel or extend
                    the rental period at any time at no additional cost.
                    Moreover, if your client returns the car earlier, he/she
                    will only pay for the days used plus an €8 admin fee.
                  </p>
                  <p className="rate-msg-txt">
                    <b>Guaranteed Commission:</b> which will be generated once
                    the rental has closed and is paid out quarterly unless
                    otherwise requested.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default RateCondition;
