import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/scss/login.scss";


import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
//import { changePassword } from "../../external_api/User";
import { loginUser, userSelector } from '../../store/slices/user';
import { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  //const dispatch = useAppDispatch();
  // form validation rules 
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email/Username is required')
      .min(6, "Min length should be 6")
      .max(30, "Max length should be 30"),
    password: Yup.string()
      .required('Password is required'),
  });

 
  const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
  type FormValues = {
    username: string,
    password: string,
    
  }
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });

  
  const { isFetching, isSuccess, isError, errorMessage } = useSelector(
    userSelector
  );

  if(isSuccess) {
    navigate("/booking");
  }
  
  const [loginLoading, setLoginLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(isSuccess);
  //const [setIsLogin] = useState(false);


  const [isSubmitForm, setSubmitForm] = useState(false);

  const [loginResMsg, setLoginResMsg] = useState({
    status: false,
    msg: ""
  });

  const [inputUserName, setUserName] = useState("");


  // Login Form Submit Handler
   const onSubmitForm = async (formData: any) => {
    const username = inputUserName;
    let dataNew = { email: username, password: formData.password }
    setLoginLoading(true);
    const loginInfo = await dispatch(loginUser(dataNew));
    //console.log(loginInfo);
    setIsLogin(false)
    setLoginLoading(false);
    setSubmitForm(true);
    //navigate("/dashboard");
  }; 

  /* const resetMsg = () => {
    global.setTimeout(() =>  {
        setLoginResMsg({status:false,msg:""});  
        }, 4000);
    } */

  useEffect(() => {
    // Login with Password effect
    if (isFetching === false && isSubmitForm) {
        if (isError) {
            setIsLogin(false);
            //toast.error(errorMessage);
            setLoginResMsg({status:false,msg:errorMessage});      
        }
        
      setSubmitForm(false)
    }
    if (isSuccess) {
      //toast.success("Login Successfually");
      //setIsLogin(true);
      navigate("/booking");
    }
    
    //resetMsg();
}, [errorMessage, isError, isFetching, isSuccess , isSubmitForm, isSubmitForm]);



  return (
    <>
      <Toaster />
      { loginLoading && <Loader /> }
      
      <div className="container-fluid bg-image">
        <div className="row">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center center-height">
              <div className="outer-form-container">
                <div className="form-container">
                  <h1 className="heading-h1-container">Log In </h1>
                  {/* <h5 className="heading-h5-container">
                    If you don't have an account
                  </h5>
                  <h6 className="heading-h6-container">
                    You can <Link to="/forget-password">Register here!</Link>
                  </h6> */}
                  
                  <form
                    className="spacertopform"
                    onSubmit={handleSubmit(onSubmitForm)}
                    name="userlogin"
                    id="userlogin"
                    autoComplete="off"
                  >
                    {
                        loginResMsg.msg ?
                    <div className="col-12">     
                        <div className={`set-default-fs alert ${!loginResMsg.status ? "alert-danger" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                        {/* {loginResMsg.msg} */} Username/Password is wrong
                        </div>
                    </div>
                    :
                    ""
                    } 
                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>Username</label>
                        <input
                          id="username"
                          //name="username"
                          type="text"
                          className={`input-field ${errors.username ? 'is-invalid' : ''}`}
                          placeholder="username"
                          {...register("username")}
                          onChange={(e) => {setUserName(e.target.value);setLoginResMsg({status:false,msg:""})}}
                        />
                      </div>
                      {errors.username &&  (
                      <span className="formFieldError">
                          {errors?.username?.message}
                      </span>
                      )}
                    </div>

                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>Password</label>
                        <input
                          id="password"
                          //name="password"
                          type="password"
                          className={`input-field ${errors.password ? 'is-invalid' : ''}`}
                          placeholder="password"
                          {...register("password")}
                        //onChange={(e)=>setUserName(e.target.value)}
                        />
                      </div>
                      <span className="formFieldError">
                        {errors.password?.message}
                      </span>
                    </div>


                    <div className="form-field d-link-contianer">
                      <label>
                        <input type="checkbox" name="remember-me" />{" "}
                        <span>Remember me?</span>
                      </label>

                      <Link
                        to="/forget-password"
                        className="link-container"
                      >
                        Forgot passowrd?
                      </Link>
                    </div>

                    <button
                      className="btn-yellow"
                      type="submit"
                      disabled={isSubmitForm}
                    >
                      Submit
                    </button>
                  </form>

                  {/* <div className="form-field">
                    <label>Username</label>
                    <input type="text" name="" className="input-field" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
};

export default Login;
