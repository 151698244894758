import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/scss/login.scss";


import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { forgotPassword } from "../../external_api/User";
//import { getCompanyList } from "../../external_api/Company";
//import { getRoleList } from "../../external_api/User";
import { userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
//import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

const ForgetPassword = () => {

  const { isFetching, isSuccess: isLogin, id: user_id } = useSelector(
    userSelector
  );
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(user_id);
  const [errorResMsg, setErrorResMsg] = useState({
    status: false,
    msg: ""
  });

  const navigate = useNavigate();
  if (isLogin) {
    navigate("/dashboard");
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),

  });

  const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };

  type FormValues = {
    username: string,
  }
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


  const onSubmitForm = async (formData: any) => {
    console.log("formData", formData, userId);
    setErrorResMsg(
      {
        status: false,
        msg: ""
      })
    setLoading(true);
    var response = await forgotPassword(formData);
    const { data, status, errorMessage } = response;
    console.log(response);
    if (status == 200) {
      toast.success(`Please check your email and follow the link to set a new password`);
      reset();
      //navigate("/profile");
    } else {
      setErrorResMsg(
        {
          status: false,
          msg: errorMessage
        })
      //toast.error(errorMessage);
    }
    setLoading(false);

  };

  return (
    <>
      <Toaster

        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <div className="container-fluid bg-image">
        <div className="row">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center center-height">
              <div className="outer-form-container">
                <div className="form-container">
                  <h1 className="heading-h1-container">Forgot Password</h1>
                  {/* <h5 className="heading-h5-container">
                    If you don't have an account
                  </h5>
                  <h6 className="heading-h6-container">
                    You can <Link to="">Register here!</Link>
                  </h6> */}

                  <form
                    className="spacertopform"
                    onSubmit={handleSubmit(onSubmitForm)}
                    name="forgotpassord_frm"
                    id="forgotpassord_frm"
                    autoComplete="off"
                  >
                    {
                      errorResMsg.msg ?
                        <div className="col-12">
                          <div className={`alert ${!errorResMsg.status ? "alert-danger set-default-fs" : ""} ${errorResMsg.status ? "alert-success" : ""}`} role="alert">
                            {errorResMsg.msg}
                          </div>
                        </div>
                        :
                        ""
                    }
                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>Username</label>
                        <input
                          className="input-field"
                          type="text"
                          {...register("username")}

                        />
                      </div>
                      <span className="formFieldError error">{errors.username?.message}</span>
                    </div>

                    <button
                      className="btn-yellow"
                      type="submit"
                    /* disabled={isSubmitting} */
                    >
                      Reset Password
                    </button>

                    <p className="para-text">
                      Already have an account?
                      <Link to="/" className="link-container">
                        Login
                      </Link>
                    </p>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
