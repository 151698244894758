import React, { useEffect, useState } from "react";
import { useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import configRoutes from '../../config/Routes';
import PostLoginMenus from "./PostLoginMenus";
import {headerLinks} from "../../data";
import "../../assets/scss/header.scss";

const Navbar = () => {

  

  return (
    <>
      
      <div className="container-fluid  header-container">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <header className="font-set">
                  <Link to={configRoutes.home} className="logo">
                    <img src="/logo.png" alt="logo" />
                  </Link>
                  <PostLoginMenus menulinks={headerLinks} />
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
