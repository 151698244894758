import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import "../../assets/scss/booking.scss";
import "../../assets/scss/modify_datepicker.scss";
import "../../assets/scss/tabs-banner.scss";


import { useDispatch, useSelector } from "react-redux";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { userSelector } from '../../store/slices/user';
import { vehiclerates, vehicleRatesSelector } from "../../store/slices/vehicleAvailable";

import toast, { Toaster } from 'react-hot-toast';

import Loader from "../../components/loading/loader"
import moment from "moment";
import { getLocations } from "../../external_api/Location";
import { getVehicleRates } from "../../external_api/Booking";


import VehicleListItem from "./VehicleListIntem";
import VehiclePopup from "./VehiclePopup";
import Header from "../../components/booking/Header";
import Titlestrip from "../../utility/Titlestrip";

import { AppDispatch } from "../../store";

const VehicleList = () => {

  const [loading, setLoading] = useState(false);
  const [vPopup, setVPopup] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  
  const { isFetching, isSuccess: isLogin } = useSelector(
    userSelector
  );
  
  const {isSuccess: isSuccessVehicle,isFetching: isFetchingVehicle, vehicles: availableVehicles, rates:  availableRates  } = useSelector(
    vehicleRatesSelector
  );

  const navigate = useNavigate();
  if (!isLogin) {
    navigate("/");
  }
  const popupHandler = () => {
    setVPopup(true);
  };

  const closeHandler = () => {
    setVPopup(false);
  };

  const [rateList, setRateList] = useState<any[]>([]);
  const [vehiclesList, setVehicleList] = useState<any[]>([]);
  //ajay localStorage.removeItem("selected_vehicle");
  const [updateList, setUpdateList] = useState(true);
  useEffect(() => {
    if(updateList) {
    (async () => {
        setLoading(true);
        dispatch(vehiclerates(""));
        setVehicleList(availableVehicles);
        setRateList(availableRates)
        /* var response = await getVehicleRates();
        var { data, status } = response;
        console.log("data", data);
        if (status === 201) {
            setTimeout(() =>{ setVehicleList(data.vehicles);setRateList(data.rates) }, 500);
            //setUserDetail(data);
        } else {
            navigate("/booking");
        } */
        setLoading(false);

        
    })()
  }
  return () => {
    setUpdateList(false);
  }
  
}, [updateList]);

useEffect(() => {
  if(isSuccessVehicle && !isFetchingVehicle) {
  (async () => {
      setLoading(true);
      //dispatch(vehiclerates(""));
      setVehicleList(availableVehicles);
      setRateList(availableRates)
      /* var response = await getVehicleRates();
      var { data, status } = response;
      console.log("data", data);
      if (status === 201) {
          setTimeout(() =>{ setVehicleList(data.vehicles);setRateList(data.rates) }, 500);
          //setUserDetail(data);
      } else {
          navigate("/booking");
      } */
      setLoading(false);

      
  })()
}

}, [isFetchingVehicle]);

  const paramData = useParams();
  const [reservationId, setReservationId] = useState(paramData.reservationId);


  return (
    <>
      <Toaster
        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <Header />
      {
        reservationId ? 
      <Titlestrip title={"Edit Reservation : "+reservationId} />
      :
      ""
      }
      <section className="container-fluid wiz-form-hertz">
        <div className="row">
          <div className="container">
            <div className="container-with-border">
              <div className="tab-hertz">
                <ul className="list-inline d-flex justify-content-between tab-list-hertz">
                  <li className="list-inline-item active completeProcess">
                    {
                      reservationId ?
                    <button type="button" onClick={(e) =>navigate("/booking/edit/"+reservationId)}>SEARCH</button>
                    :
                    <button type="button" onClick={(e) =>navigate("/booking")}>SEARCH</button>
                    }
                  </li>
                  <li className="list-inline-item active">
                    <button type="button">RESULT</button>
                  </li>
                  <li className="list-inline-item">
                    <button type="button">CUSTOMER INFORMATION</button>
                  </li>
                </ul>
                <div className="tab-wiz-inner-hertz">
                  <div className="tab-inner-hertz" style={{ display: "block" }}>
                    <div className="col-12">
                      {/* table here */}

                      <VehicleListItem handlePopup={popupHandler}  vehicles={vehiclesList} rateList={rateList} setUpdateList={setUpdateList}  />

                      <button type="button" className="send-estimate-btn hide">
                        Send Estimate
                      </button>
                      {/*  */}
                      {/* <VehiclePopup
                        vPopup={vPopup}
                        closeHandler={closeHandler}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VehicleList;
