import React, { useEffect, useRef } from "react";

declare global {
    interface Window { L: any }
}


type Props ={
    zoom: number,
    lat: string,
    lon: string,
    name?: string,
    address?: string,
    operationHour?: string
}
var map:any;
const API_MAPQUEST_KEY = "Gmjtd%7Cluuan107n9%2C75%3Do5-lz8w0";
const MapQuestDirections: React.FC<Props> = ({zoom = 13, lat, lon, name, address, operationHour}
) => {

    
    const genrateMap = (zoom:number , lat:string, lon:string) => {
        const leaftef = window.L;
        leaftef.mapquest.key = API_MAPQUEST_KEY;
        var baseLayer = leaftef.mapquest.tileLayer('map');
        
        //console.log("initialize map")
        var container = leaftef.DomUtil.get('map');
        if(container != null){
            container._leaflet_id = null;
        }
        
        
        if(map !== undefined) {
            //console.log("map", map)
            map.remove();
        }
        map = leaftef.mapquest.map('mapView', {
            /* center: [from.lat, from.lng], */
            center: [lat, lon],
            layers: baseLayer,
            zoom: zoom
        });
        
        var locationLatLng = {
            lat: lat,
            lon: lon
        };

        var customIcon = leaftef.mapquest.icons.marker({
            primaryColor: '#ffcc00'
        });
        var marker = leaftef.marker(locationLatLng, { icon: customIcon });

        if(name) {
            marker.bindPopup(name +'<br>'+ address +'<br><b>Opening Hours</b><br>'+operationHour);
        }    
        marker.addTo(map);
    
       
        let isClicked = false

        marker.on({
            mouseover: function() {
                if(!isClicked) {
                    this.openPopup()
                }
            },
            mouseout: function() { 
                if(!isClicked) {
                    this.closePopup()
                }
            },
            click: function() {
                isClicked = true
                this.openPopup()
            }
        })

        map.on ({
            click: function() {
                isClicked = false
            },
            popupclose: function () {
                isClicked = false
            }
        })
        
    }
   
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://api.mqcdn.com/sdk/mapquest-js/v1.3.2/mapquest.js";
        script.addEventListener('load', () => {
            genrateMap(zoom, lat, lon)
            // unmount map function
            
        });

        document.body.appendChild(script);  
        setTimeout(() => {
            var markerts = document.getElementsByClassName("leaflet-marker-icon");
            if(markerts[0]) {
                //@ts-ignore
                markerts[0].click(); 
            }
           
        }, 2000);
        
    }, [lat, lon]);

    return (
        <div id="mapView"  style={{flex:1, borderRadius: "0px", border: "0px solid #c4c4c4", width: "100%", height: "500px"}}></div>
    );
}


export default MapQuestDirections;