import React, { useEffect, useState } from "react";
import { getLatestReservation } from "../../external_api/Reservation";
import moment from "moment";

const Locationlist = () => {
  const [listitemData, setListitemData] = useState([]);

  useEffect(() => {
    (async () => {
        //setLoading(true);
        var response = await getLatestReservation();
        var { data, status } = response;
          //console.log(data.data);
        if (status === 201) {
          setListitemData(data);  
        } 
        //setLoading(false);

        //console.log("profile data",data.output);
    })()

}, []);
  /* 
    
    $('.custon-dropdown-btn').click(function(){
           $(this).parents('tr').siblings().find('.custom-dropdown-menu').slideUp();
            $(this).siblings('.custom-dropdown-menu').slideToggle();
        })
    
    */
  return (
    <>
      <section className="loactions">
        <h2 className="sub-heading">Reservation List</h2>
        <div className="table-responsive">
          <table className="table custon-table ">
            <thead>
              <tr>
                <th scope="col">Reservation ID</th>
                <th scope="col">Location Code</th>
                <th scope="col">Pickup Date</th>
                <th scope="col">Dropoff Date</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
            {listitemData.length !== 0
          ? listitemData.map((item:any, i:number) => (
              <tr key={i}>
                <td>{item.reservation_id}</td>
                <td>{item.pickup_oagcode}</td>
                <td>{item.pickup_date}</td>
                <td>{item.dropoff_date}</td>
                <td>
                </td>
              </tr>
              ))
              : ""}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default Locationlist;
