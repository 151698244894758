import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { userSelector } from '../../store/slices/user';
import { AppDispatch } from "../../store";

import "../../assets/scss/static.scss";
import { getStaticPageDetail } from "../../external_api/StaticPage";
import PageInfo from "../../components/staticpage/pageInfo";
import NotFound from "../../components/staticpage/NotFound";


const InformationPage = (props:any) => {
    

    const paramData = useParams();
    
      
  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid static-pages">
        {/* static code section start */}
        <div className="row">
          <div className="container">
            
            <PageInfo paramData={paramData} />
                    
          </div>
        </div>
        {/* static code section end */}
      </div>
    </>
  );
};

export default InformationPage;

