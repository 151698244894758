import React, { useEffect, useState } from "react";
//import closeIcon from "../../../assets/svg/cross-icon.svg";
import closeIcon from "../../assets/svg/cross-icon.svg";
import "../../assets/scss/booking.scss";
import { capitalizeFirstLetter } from "../../utility/Helpers";
import { extrasList, coverageList } from "../../constants/StaticData"
import { getRateDetail } from "../../external_api/Rate";
import { dataBind } from "jodit/types/core/helpers";
const PopupToKnow = ({ oagcode, rateId, closeHandler }: { oagcode: any, rateId: number, closeHandler: any }) => {


  const [rateDetail, setRateDetail] = useState<any>({});



  useEffect(() => {
    (async () => {
      var response = await getRateDetail(rateId);
      var { data, status } = response;
      setRateDetail(data)

    })()

  }, []);


  return (

    <>


      <div className={`modal-box-countainer infoPoup`}>
        <div className="spacerLR_model">
          <div className="modal-box">
            <div className="modal-body-box extra-fee-modal">
              <button className="close-btn btn">
                <img src={closeIcon} alt="close icon" onClick={closeHandler} />
              </button>
              <h2 className="etra-fee-title">To Know </h2>
              <div className="height-setting">
                <div className="s-scroll">
                  <div className="extra-fee-inner">
                    <div className="static-content" dangerouslySetInnerHTML={{ __html: rateDetail?.content }} />
                    <iframe height={400} width={"100%"}
                      src={`https://www.hertz.co.uk/rentacar/reservation/reviewmodifycancel/templates/rentalTerms.jsp?EOAG=${oagcode}`}
                      title="To Know"></iframe>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupToKnow;
