import React, { useEffect, useState } from "react";
import { getReservationReport } from "../../external_api/Reservation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GraphData = () => {
  const options = {
    responsive: true,
    /* plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    }, */
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [reportData, setReportData] = useState([]);

  const resetChartData = () => {
    var dataCounts = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    let defaultCopy = {...defaultData};
    //console.log("defaultCopy", defaultData);
    reportData.map(function(item){
      if(item.year == defaultYear) {
        //years.push(item.year)
        dataCounts[item.month - 1 ] = item.count;
      }
      
    })
    //console.log(dataCounts);
    defaultCopy.datasets[0].data = dataCounts;
    setChartData(defaultCopy);
  }
  const defaultData = {
    labels,
    datasets: [
      {
        label: "Reservations",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        backgroundColor: "rgba(255, 204, 0, 1)",
      },
    ],
  };
  const [chartData, setChartData] = useState(defaultData);
  

  const [yearList, setYearList] = useState([]);
  const [defaultYear, setDefaultYear] = useState("");

  useEffect(() => {
    (async () => {
        var response = await getReservationReport();
        var { data, status } = response;
          //console.log(data.data);
        if (status === 201) {
          setReportData(data);
        } 
       
    })()

}, []);

useEffect(() => {
    const years = [];
    reportData.map(function(item){
      if(!years.includes(item.year)) {
        years.push(item.year)
      }
      
    })
    if(years.length) {
      setDefaultYear(Math.max(...years));
    }
    //console.log("defaultData",defaultData)
    setYearList(years);
    resetChartData();

}, [reportData]);


useEffect(() => {
  resetChartData();

}, [defaultYear]);

  return (
    <>
      <section className="graph">
        <div className="d-flex">
          <h2 className="sub-heading">Booking Reports</h2>
          <div className="select-box">
            {/* <select>
              <option>Aug</option>
            </select> */}
            <select  onChange={(e) => {setDefaultYear(e.target.value);console.log(e.target.value)} }>
            {yearList.length !== 0
          ? yearList.map((year, i) => (

                <option key={i} value={year}>{year}</option>
                ))
                : ""}              
            </select>
          </div>
        </div>
        {/* <img src={graph} alt="grapg" /> */}
        <Bar options={options} data={chartData} />;
      </section>
    </>
  );
};

export default GraphData;
