import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from "formik";
import Titlestrip from "../../utility/Titlestrip";

import configRoutes from "../../config/Routes";
import AppUrl from "../../config/AppUrl";


import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addUser, getUserDetials, updateUser  } from "../../external_api/User";
import { getCompanyList } from "../../external_api/Company";
import { getRoleList } from "../../external_api/User";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { profileList } from "../../constants/StaticData";

const AddUser = () => {

    const { isFetching, isSuccess: isLogin } = useSelector(
        userSelector
    );
    
    const dispatch = useDispatch<AppDispatch>();
    
    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required('Name is required'),
        last_name: Yup.string()
            .required('Surname is required'),
        profile: Yup.array()
            .min(1,'Select at least one Profile'),
        company_id: Yup.string()
            .required('Company is required'),
        role_id: Yup.string()
            .required('User level is required'),
        phone: Yup.string()
            .required('Phone is required'),
        email: Yup.string()
             .email()
            .required('Email is required')
            .email('E-mail is not valid!'),
        name: Yup.string()
            .required('Username is required')
            .min(6, "Min length should be 6")
            .max(20, "Max length should be 20"),
        password: Yup.string()
            .required('Password is required'),
        status: Yup.string()
            .required('Status is required'),
    });


    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    type FormValues = {
        first_name: string,
        last_name: string,
        profile: Array<number>,
        company_id: string,
        role_id: string,
        phone: string,
        email: string,
        name: string,
        password: string,
        status: string,
    }
    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


    const [loading, setLoading] = useState(false);
    
    const [userDetail, setUserDetail] = useState(
        {
            first_name: "",
            last_name: "",
            profile: [],
            company_id: "",
            roles: {id:""},
            phone: "",
            email: "",
            name: "",
            password: "",
            status: "",
        }
      );
    const paramData = useParams();
    const [userId, setUserId] = useState(paramData.id);

    //const userId = paramData.

    const [isSubmitForm, setSubmitForm] = useState(false);

    const [loginResMsg, setLoginResMsg] = useState({
        status: false,
        msg: ""
    });

    const [inputUserName, setUserName] = useState("");


    // Login Form Submit Handler
    
    const onSubmitForm = async (formData: any) => {
        console.log("formData",formData, userId);
        //return true;
        setLoading(true);
        var response = await addUser(formData);
        const { data, status, errorMessage } = response;
        console.log(response);
        if (status == 201) {
            setLoading(false);
            toast.success("User has been created");
            setTimeout(() => {
                navigate("/user");  
            }, 500);
        } else {
            toast.error(errorMessage);
        }
        setLoading(false);
        
      };

    

    const [companyList, setCompanyList] = useState([]);
    const [roleList, setRoleList] = useState([]);

    

    useEffect(() => {
        (async () => {
            //const loginInfo = dispatch(clearState);    
             var responseCompany = await getCompanyList();
            var { data, status } = responseCompany;
            //console.log("data",data[0])
            if (status === 200) {
                setCompanyList(data);
            }
            var responseRole = await getRoleList();
            console.log("profile responseRole", responseRole)
            var { data, status } = responseRole;
            if (status === 200) {
                setRoleList(data);

            }


        })()

    }, []);

    
    
    
    //console.log(post)

    return (
        <>
            <Toaster 

            toastOptions={{
                className: 'toaster-popup',
            }}
            />
            {loading ? (

                <Loader />
            ) :
                ""
            }
            <div className="form-wrapper container-fluid">
                <div className="row">
                    <div className="container">


                        <form
                            className="spacertopform"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="userEdit"
                            id="userEdit"
                            autoComplete="off"
                        >
                            {
                                loginResMsg.msg ?
                                    <div className="col-12">
                                        <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                                            {loginResMsg.msg}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row">

                                <div className="col-md-6 col-12">
                                    <div className="custom-field">
                                        <label>Name<span className="red">*</span></label>
                                        <div className="text">
                                            {/* {userDetail.first_name} */}
                                            <input 
                                            defaultValue={userDetail.first_name}
                                            type="text"
                                            className={`input-field ${errors.first_name ? 'is-invalid' : ''}`} 
                                            {...register("first_name")}
                                            //onChange={(e) => {setUserName(e.target.value);setLoginResMsg({status:false,msg:""})}}
                                              
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.first_name?.message}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="custom-field ">
                                        <label>Surname<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.last_name ? 'is-invalid' : ''}`} 
                                                {...register("last_name")}
                                                defaultValue={userDetail.last_name}  
                                            
                                            /* onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.vat} */
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.last_name?.message}</span>
                                </div>
                            </div>



                            <div className="gray-bg">
                                <h2>Profile<span className="red">*</span></h2>
                                <div className="d-flex flex-wrap">
                                    {
                                        
                                        profileList.map((profile: any,index) => {
                                            //var isChecked = (userDetail.profile.find(profile.id) !== undefined) ? true : false;
                                            return (
                                                <div className="form-check" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={profile.id}
                                                        id="localCharge"
                                                        //checked={isChecked}
                                                        //checked={true}
                                                        {...register(`profile`)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`profile`}
                                                    >
                                                        {profile.name}
                                                    </label>
                                                </div>
                                            )
                                            
                                        })
                                    }
                                </div>
                                
                            </div>
                            <span className="formFieldError error">{errors.profile?.message}</span>
                            
                            <div className="row">

                                <div className="col-md-6 col-12">
                                    <div className="custom-field">
                                        <label>Email<span className="red">*</span></label>
                                        <div className="text">
                                            <input

                                                type="text"
                                                {...register("email")}
                                                defaultValue={userDetail.email}  
                                            /* onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.customername} */
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.email?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Phone<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="number"
                                                {...register("phone")}
                                                defaultValue={userDetail.phone}  
                                            /* onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.customername} */
                                            />
                                        </div>
                                        
                                    </div>
                                    <span className="formFieldError error">{errors.phone?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>User Level<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("role_id")}
                                                defaultValue={userDetail?.roles?.id ?? ""}  
                                            /* onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.parent} */
                                            >
                                                <option value="">Select</option>
                                                {
                                                    roleList.map((role: any,index) => {

                                                        return <option value={role.id} key={index}>{role.name}</option>
                                                    })

                                                }
                                                
                                                
                                            </select>
                                          </div>
                                        </div>
                                        <span className="formFieldError error">{errors.role_id?.message}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="custom-field">
                                        <label>Username<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("name")}
                                                defaultValue={userDetail.name}  
                                            
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.name?.message}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="custom-field">
                                        <label>Password</label>
                                        <div className="text">
                                            <input
                                                type="password"
                                                {...register("password")}
                                                defaultValue={userDetail.password}  
                                            
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.password?.message}</span>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="custom-field">
                                        <label>Company<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("company_id")}
                                                defaultValue={userDetail.company_id}  
                                                >
                                                {companyList.length > 0 ?
                                                    companyList.map((company: any,index) => {
                                                        return <option value={company.id} key={index}>{company.name}</option>;
                                                    })
                                                    :
                                                    ""


                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.company_id?.message}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="custom-field">
                                        <label>Enabling<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("status")}
                                                defaultValue={userDetail.status}  
                                                >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.status?.message}</span>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12">
                                    <input type="submit" value="Add" className="btn-yellow" />
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>

        </>
    )



}

export default AddUser;


