/* eslint-disable jsx-a11y/iframe-has-title */
//import * as React from "react";
import React, { useState } from "react";
import Loader from "../../components/loading/loader";
import "../../assets/scss/static.scss";

const HelpManual = () => {
  const [overlay, setOverlay] = useState(false);

  const url = overlay
    ? `https://www.youtube.com/embed/xbvAzAjhrw8?autoplay=1`
    : `https://www.youtube.com/embed/xbvAzAjhrw8`;

  const handleVideo = () => {
    setOverlay(true);
  };

  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid static-pages">
        <div className="row">
          <div className="customer-srvc-wrapper">
            <section className="customer-srvc-section">
              <div className="customer-srvc-inner">
                <div className="extra-equip-text extra-equip-box">
                  <h3 className="tittle-name section-tittle-mb ml-left">
                    Help - Manual
                  </h3>
                  <p className="extra-msg-txt ml-left">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book.
                  </p>
                </div>
                <div className="extra-equip-img extra-equip-box">
                  <div id="background-video">
                    {overlay ? (
                      <iframe
                        width="295"
                        height="400"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                        src={url}
                        style={{ display: "block" }}
                      ></iframe>
                    ) : (
                      ""
                    )}

                    {overlay ? (
                      ""
                    ) : (
                      <span className="play-btn" onClick={() => handleVideo()}>
                        Play Button
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpManual;
