//import * as React from "react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import "../../assets/scss/login.scss";
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { changePassword } from "../../external_api/User";
//import { getCompanyList } from "../../external_api/Company";
//import { getRoleList } from "../../external_api/User";
import { userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"


const ChangePassword = () => {

  const { isFetching, isSuccess: isLogin, id: user_id } = useSelector(
    userSelector
  );
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(user_id);
  const [errorResMsg, setErrorResMsg] = useState({
    status: false,
    msg: ""
  });

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  if (!isLogin) {
    navigate("/");
  }

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .required('Old Password is required'),
    password: Yup.string()
      .required('New Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match with New password')
  });


  const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };

  type FormValues = {
    old_password: string,
    password: string,
    confirm_password: string,
  }
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


  const onSubmitForm = async (formData: any) => {
    console.log("formData", formData, userId);
    setErrorResMsg(
      {
        status:false,
        msg: ""
      })
    //return true;
    setLoading(true);
    var response = await changePassword(formData);
    const { data, status, errorMessage } = response;
    console.log(response);
    if (status == 200) {
      toast.success("Password has been changed");
      reset();
      //navigate("/profile");
    } else {
      setErrorResMsg(
        {
          status:false,
          msg: errorMessage
        })
      //toast.error(errorMessage);
    }
    setLoading(false);

  };




  return (
    <>
      <Toaster

        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <Titlestrip title={"Change Password"} />
      <div className="container-fluid bg-image">
        <div className="row">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center center-height">
              <div className="outer-form-container">
                <div className="form-container">
                  {/* <h1 className="heading-h1-container">Change Password</h1>
                  <h5 className="heading-h5-container">
                    If you don't have an account
                  </h5>
                  <h6 className="heading-h6-container">
                    You can <Link to="">Register here!</Link>
                  </h6> */}
                  <form
                    className="spacertopform"
                    onSubmit={handleSubmit(onSubmitForm)}
                    name="userEdit"
                    id="userEdit"
                    autoComplete="off"
                  >
                    {
                      errorResMsg.msg ?
                        <div className="col-12">
                          <div className={`alert ${!errorResMsg.status ? "alert-danger set-default-fs" : ""} ${errorResMsg.status ? "alert-success" : ""}`} role="alert">
                            {errorResMsg.msg}
                          </div>
                        </div>
                        :
                        ""
                    }
                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>Old password<span className="red">*</span></label>
                        <input
                          className="input-field"
                          type="password"
                          {...register("old_password")}

                        />
                      </div>
                      <span className="formFieldError error">{errors.old_password?.message}</span>

                    </div>

                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>New password<span className="red">*</span></label>
                        <input
                          className="input-field"
                          type="password"
                          {...register("password")}
                        />

                      </div>
                      <span className="formFieldError error">{errors.password?.message}</span>
                    </div>

                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>Retype New password<span className="red">*</span></label>
                        <input
                          className="input-field"
                          type="password"
                          {...register("confirm_password")}
                        />
                      </div>
                      <span className="formFieldError error">{errors.confirm_password?.message}</span>
                    </div>


                    <div className="d-flex flex-wrap align-content-center justify-content-between">
                      <button
                        className="btn-yellow"
                        type="submit"
                      /* disabled={isSubmitting} */
                      >
                        Reset Password
                      </button>

                      <p className="para-text">
                        <Link to="/" className="link-container">
                          Cancel
                        </Link>
                      </p>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
