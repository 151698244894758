import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getVehicleRates } from '../../external_api/Booking';
//import { login, register } from "../../external_api/User";
import type { RootState } from '../../store';

import { extrasList } from "../../constants/StaticData"

export const extrasSlice = createSlice({
    name: 'extras',
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        extraItems: localStorage.getItem('extraItems') ? JSON.parse(localStorage.getItem('extraItems') as any) : [],
        totalQuantity: 0,
    },
    reducers: {
        clearExtraListState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.extraItems = [];
            state.totalQuantity = 0;
            return state;
        },
        decreaseCartQuantity(state, action) {
            const itemIndex = state.extraItems.findIndex(
                (extraItem: { id: any; }) => extraItem.id === action.payload.id
            )
                console.log("state.extraItems[itemIndex]?.extraQuantity",state.extraItems[itemIndex]?.extraQuantity);
            if (state.extraItems[itemIndex]?.extraQuantity >= 1) {
                state.extraItems[itemIndex].extraQuantity -= 1;
            }

            /* else if (state.extraItems[itemIndex]?.extraQuantity === 1) {
                const inExtraItems = state.extraItems.filter(
                    (extraItem: { id: any; }) => extraItem.id !== action.payload.id
                );
                state.extraItems = inExtraItems;
                console.log(`${action.payload.name} is removed from the list!`);
                //toast.error(`${action.payload.name} is removed from the cart!`,
                    {position:'bottom-right'})
            } */
            localStorage.setItem('extraItems', JSON.stringify(state.extraItems))

        },

        increaseCartQuantity(state, action) {
            console.log(action.payload.id);
            const itemIndex = state.extraItems.findIndex(
                (extraItem: { id: any; }) => extraItem.id === action.payload.id
            )
            state.extraItems[itemIndex].extraQuantity += 1;
            /* if (state.extraItems[itemIndex]?.extraQuantity) {
                state.extraItems[itemIndex].extraQuantity += 1;
            } else {
                state.extraItems[itemIndex] = {id:itemIndex, extraQuantity: 1};
            } */
            
            localStorage.setItem('extraItems', JSON.stringify(state.extraItems))
        },
        getTotal(state) {
            let { quantity } = state.extraItems.reduce((extraTotal: {quantity: any; }, extraItem: { extraQuantity: any; }) => {
                const { extraQuantity } = extraItem;
                
                extraTotal.quantity += extraQuantity

                return extraTotal;
            }, {
                quantity: 0,
            });

            state.totalQuantity = quantity;
        },
        resetExtraItems(state) {
            var extraItemsInit = [];
            let i = 0;
            while(i < extrasList.length) {
                let extra = extrasList[i];
                extraItemsInit[i] = {id:extra.id, extraQuantity:0}
                i++;
            }
            console.log(extraItemsInit.length);
            localStorage.setItem('extraItems', JSON.stringify(extraItemsInit))    
            
            console.log("resetExtraItems");
            console.log(JSON.parse(localStorage.getItem('extraItems') as any));
            state.extraItems = localStorage.getItem('extraItems') ? JSON.parse(localStorage.getItem('extraItems') as any) : [];
        }
    }
    
});


export const { clearExtraListState, decreaseCartQuantity,
    increaseCartQuantity, getTotal, resetExtraItems } = extrasSlice.actions;

//export const userSelector = (state: { user: any; }) => state.user;
//export const extrasSelector = (state: RootState) => state.vehicleSelected;

export const extrasSelector = (state: RootState) => state.extraSelected;

export default extrasSlice.reducer;
