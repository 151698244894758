import { useEffect, useRef, useState } from 'react'
import { DateRangePicker, defaultStaticRanges } from 'react-date-range'

import format from 'date-fns/format'

//import { addDays } from 'date-fns'

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from "date-fns";

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import closeImg from "../../assets/svg/close-modal-hertz-btn.svg";

import moment from "moment";

// https://codesandbox.io/s/musing-almeida-9kf3e?file=/src/App.js:0-215

const DateRangePickerComp = ({getDates}) => {

  // date state
  var firstDateCurrentWeek = moment().startOf('isoWeek') //Monday is the week start day
  var lastDateCurrentWeek = moment().startOf('Week') //Sunday is the week start day

  var firstDateCurrentMonth = moment().startOf('Month') //Monday is the week start day
  
  //console.log(firstDateCurrentWeek.toString());
  const [range, setRange] = useState([
    /* {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    } */
    {
      startDate: new Date(firstDateCurrentMonth.toString()),
      endDate: new Date(),
      key: 'selection'
    }

  ])

  


  // open close
  const [open, setOpen] = useState(false)

  // get the target element to toggle 
  const refOne = useRef(null)

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
  }

  // Hide dropdown on outside click
  const hideOnClickCloseButton = (e) => {
      setOpen(false)
  }

  

  useEffect(() => {
            console.log("range", range) 
            var startedDate = moment(range[0].startDate,).format("YYYY-MM-DD");
            var endDate = moment(range[0].endDate).format("YYYY-MM-DD"); 
            getDates({"startDate": startedDate, "endDate": endDate})
  },[range])

  return (
    <div className="calendarWrap">
      
      <div className="date-range-icon" onClick={ () => setOpen(open => !open) }></div>  
      <input
        //value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
        value={`${format(range[0].startDate, "dd/MM/yyyy")} to ${format(range[0].endDate, "dd/MM/yyyy")}`}
        readOnly
        className="inputBox form-search"
        onClick={ () => setOpen(open => !open) }
      />

      <div ref={refOne}>
        {open && 
          <>  
          <span className='close-calender' onClick={hideOnClickCloseButton}>Close<img src={closeImg} alt="close icon" /></span>  
          <DateRangePicker
            onChange={item => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={2}
            direction="horizontal"
            className="calendarElement"
            staticRanges={[
              //...defaultStaticRanges,
            {
              label: 'Current Month',
              range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date())
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                );
              }
            },
            {
              label: 'Previous Month',
              range: () => ({
                startDate: startOfMonth(addMonths(new Date(), -1)),
                endDate: endOfMonth(addMonths(new Date(), -1))
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                );
              }
            },
            {
              label: "Current year",
              range: () => ({
                startDate: startOfYear(new Date()),
                endDate: endOfDay(new Date())
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                );
              }
            },
              {
                label: "Previous year",
                range: () => ({
                  startDate: startOfYear(addYears(new Date(), -1)),
                  endDate: endOfYear(addYears(new Date(), -1))
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                }
              },
              
            ]}
            
          />
          </>
        }
      </div>

    </div>
  )
}

export default DateRangePickerComp