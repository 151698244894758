import moment from 'moment';

import { ageCountryList, paymentTypeAllList } from '../constants/StaticData';


export function getDateFormat(date) {
    return moment(new Date(date)).format('ll');
}

export function getStanderedDateFormat(date) {
    return moment(new Date(date)).format("DD MMMM YYYY");
}

export function getAgeList(country_code) {
        var ageData = [];

        var ageData = ageCountryList.filter(function(ageRow){
            return ageRow.country_code === country_code
        })
        if(ageData.length === 0) {
            country_code = "GLOBAL";
            var ageData = ageCountryList.filter(function(ageRow){
                return ageRow.country_code === country_code
            }) 
        } 
        return ageData;
    
}

export function capitalizeFirstLetter(string) {
    
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function hasCoverageIncluded(coverage) {
    if((coverage.includeInRate == "false" && coverage.require === "") 
    ||  
    (coverage.includeInRate === "" && coverage.require === "")
    ||
    (coverage.includeInRate === "false" && coverage.require === "false")

    ) {
            return false;
    } 
    return true;
}

export function hasOptionalCoverage(rate) {
    var hasOptionals = false;
    if(rate.priced_coverages.length === 0) {
        return hasOptionals;
    }
    rate.priced_coverages.map((coverage, index) => {
          if(!hasCoverageIncluded(coverage)) {
            hasOptionals = true;
          }

    })

    return hasOptionals;
}

export function hasOptionalCoverageList(coverates) {
    var hasOptionals = false;
    if(coverates.length === 0) {
        return hasOptionals;
    }
    coverates.map((coverage, index) => {
          if(!hasCoverageIncluded(coverage)) {
            hasOptionals = true;
          }

    })
    
    return hasOptionals;
}

export function isElectricVehicle(sippCode) {
    if(sippCode[3] === "C" || sippCode[3] === "E") {
            return true;
    } 
    return false;
}


export function getDefualtPaymentTypeUser(user_payment_types) {

    if(user_payment_types.length) {
    
        if(user_payment_types.includes("VL")) {
            return "vl";
        }
        if(user_payment_types.includes("LC")) {
            return "lc";
        }
        if(user_payment_types.includes("FC")) {
            return "fc";
        }

    } else {
        return ""
    }

   // console.log("user_payment_types", user_payment_types);
    
    
}

export function getExtrasTotal(extras) {
   
    var extrasTotal = 0.00;
    if(extras) {
        extras.map((extra, index) => {
            extrasTotal = extrasTotal + +extra["Charge"]["@attributes"].Amount
            
          })    
    }
    return extrasTotal;  
    
}
export function hasAdditionallDriver(countryCode, rate_name) {
    if(!rate_name) {
        return false;
    }
    //var countryList = ["US", "CA", "AU", "NZ"];
    var countryList = ["PT", "AU", "NZ"];
    //console.log("rate_name", rate_name)
    //return false;
    if(rate_name.toLowerCase().indexOf("max") !== -1) {
        if(countryList.includes(countryCode.toUpperCase())) {
                return true;
        } else {
            return false;
        }
    }
    
    //if(rate_name.toLowerCase().indexOf("plus") !== -1 || rate_name.toLowerCase().indexOf("max") !== -1) {
    if(rate_name.toLowerCase().indexOf("plus") !== -1) {    
        return true;
    } 
    
    return false;  
    
}

export function hasYounchDriverSurcharge(fees) {
    var youngDriverFee = {};
    if(fees) {
        fees.map((fee, index) => {
            if(fee.purpose == 6) {
                youngDriverFee = fee;
            }
            
        })
    }
    
    return youngDriverFee;
}

export function getPaymentTypeName(payment_type, privilege) {
    var paymentTypeName = payment_type;
    if( privilege == "touroperator") {
        paymentTypeName = "ITV";
    }
    if( privilege == "leisure") {
        paymentTypeName = "Prepaid";
    }
    if( privilege == "business") {
        var payTypeObj = paymentTypeAllList.find((item) => { 
            //console.log(item.code.toLowerCase(),":",reservation.payment_type.toLowerCase());
            return item.code.toLowerCase() == payment_type.toLowerCase()
        }
        );
        
        if(payTypeObj) {
            paymentTypeName = payTypeObj?.name;  
        }
    }

    return paymentTypeName;
    
}

export function downloadFile ({ data, fileName, fileType }) {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

export function hasTankOfFuel(countryCode, rate_name) {
    if(!rate_name) {
        return false;
    }
    var countryList = ["US", "CA"];
    
    if(rate_name.toLowerCase().indexOf("max") !== -1) {
        if(countryList.includes(countryCode.toUpperCase())) {
                return true;
        } else {
            return false;
        }
    }
    
    return false;  
    
}

export function getAdditionalInclusions(countryCode, additional_inclusions) {
    if(!additional_inclusions) {
        return false;
    }

    if(additional_inclusions.length > 0) {
        return true;
    }

    return false;
    
    var countryList = ["PT", "AU", "NZ"];
    console.log('additional_inclusions',additional_inclusions);
    
      
    
}
