import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../assets/scss/custom-modal.scss";
import closeIcon from "../../assets/svg/cross-icon.svg";

type Props = {
    verifyPopup: any,
    closesmallHandler: any,
    yesHandler: any,
    noHandler: any  
}



const PopupBoxSmall = ({
  verifyPopup,
  closesmallHandler,
  yesHandler,
  noHandler,
}: Props) => {

  const navigate = useNavigate();
  const paramData = useParams();
  const [reservationId, setReservationId] = useState(paramData.reservationId);
  
  return (
    <>
      <div
        className={`modal-box-countainer confirmPopup ${
          !verifyPopup ? "hideBox" : ""
        }`}
      >
        <div className="spacerLR_model">
          <div className="modal-box small-modal">
            <div className="modal-body-box">
              <button
                className="close-btn confirmClose"
                onClick={() => closesmallHandler()}
              >
                <img src={closeIcon} alt="close icon" />
              </button>
              <h2 className="modal-title">
                Do you want to buy extras and coverage?
              </h2>
              <div className="d-flex-model">
                <button
                  className="btn confirmation-btn"
                  onClick={() => yesHandler()}
                >
                  Yes
                </button>
                {
                  reservationId ?
                <button
                  className="btn confirmation-btn"
                  onClick={() => { 
                    navigate("/booking/review-book/"+reservationId)
                    //noHandler();
                  }}
                >
                  No
                </button>
                  :

                <button
                className="btn confirmation-btn"
                onClick={() => { 
                  navigate("/booking/review-book")
                  //noHandler();
                }}
              >
                No
              </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupBoxSmall;