//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getReservationDetail = async (reservationId:any)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.get(`/reservations/${reservationId}`);
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}

const getReservationList = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/reservations/all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const getReservations = async (
    pageNumber:number = 1, 
    pageSize?:number, 
    sort?:string, 
    order?:string, 
    searchTerm?:string, 
    dateRange?:{startDate:string, endDate:string}) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/reservations?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=reservation_id"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=first_name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=last_name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[3]=country"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[4]=email"+ ',' + searchTerm;
    }
    if(dateRange) {
      if(dateRange.startDate !== "") {
        requestUrl = requestUrl + "&greater_or_equal=created_at"+ ',' + dateRange.startDate;
        requestUrl = requestUrl + "&less_or_equal=created_at"+ ',' + dateRange.endDate;
      }
      
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

/* const addReservation = async (RateData: any) => {
  console.log(RateData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/reservations`, RateData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
} */

const updateReservation = async (reservationData: any, reservationId:number) => {
  console.log(reservationData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.put(`/reservations/${reservationId}`, reservationData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const getDashboardReservationsTypeCounts = async ()=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/dashboard/reservation-types`);
   console.log("response", response)
   data = response.data.data;
   status = response.status;
   if(status !== 200) {
      errorMessage = response.data.message
    }
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   //console.log(errorMessage);
 }
 return { data, status, errorMessage }
}

const getLatestReservation = async ()=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/dashboard/latest-reservation`);
   data = response.data.data;
   status = response.status;
   if(status !== 200) {
      errorMessage = response.data.message
    }
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   //console.log(errorMessage);
 }
 return { data, status, errorMessage }
}

const getReservationReport = async ()=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/dashboard/reports`);
   data = response.data.data;
   status = response.status;
   if(status !== 200) {
      errorMessage = response.data.message
    }
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   //console.log(errorMessage);
 }
 return { data, status, errorMessage }
}

const getReservationsDateWise = async (dateRange?:{startDate:string, endDate:string}) => {
  let data, status, errorMessage;

  const pageSize = 999999;
  try {
    let requestUrl = "/reservations/get-all?";
   
    requestUrl = requestUrl + "&page_size=" + pageSize;
    
    if(dateRange) {
      if(dateRange.startDate !== "") {
        requestUrl = requestUrl + "&greater_or_equal=created_at"+ ',' + dateRange.startDate;
        requestUrl = requestUrl + "&less_or_equal=created_at"+ ',' + dateRange.endDate;
      }
      
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    console.log("data", data);
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}


export {
  getReservationDetail, 
  getReservationList, 
  getReservations,
  updateReservation,
  getDashboardReservationsTypeCounts,
  getLatestReservation,
  getReservationReport,
  getReservationsDateWise
}