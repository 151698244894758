import React, { useEffect, useRef, useState } from "react";
import vehicleImage from "../../assets/images/car.png";
import "../../assets/scss/sticky_table.scss";

import VehiclePopup from "./VehiclePopup";
import { v4 as uuidv4 } from "uuid";
import carSeats from "../../assets/svg/car-seats.svg";
import carLuggage from "../../assets/svg/car-luggage.svg";
import carAutomatic from "../../assets/svg/car-automatic.svg";
import infoIcon from "../../assets/svg/info-icon.svg";

import { countryDefualtCurrency, extrasList } from "../../constants/StaticData";
import { getExtras } from "../../external_api/Extra";
import { getLocationsSearchByCodeVendor } from "../../external_api/Location";
import { useDispatch, useSelector } from "react-redux";
import { vehicleSelectedRate } from "../../store/slices/selectedVehicle";
import { AppDispatch } from "../../store";
import { vehicleRatesSelector } from "../../store/slices/vehicleAvailable";
import Popupinfo from "../../components/booking/PopupIncluded";
import PopupIncluded from "../../components/booking/PopupIncluded";
import { isElectricVehicle } from "../../utility/Helpers";


const imagePrefix = "https://images.hertz.com/vehicles/220x128/";

const VehicleListItem = ({ handlePopup, vehicles, rateList, setUpdateList }: { handlePopup: any, vehicles: any[], rateList: any[], setUpdateList: any }) => {
  const [vPopup, setVPopup] = useState(false);
  const closeHandler = () => {
    setVPopup(false);
  };

  const dispatch = useDispatch<AppDispatch>();

  const { isSuccess: isSuccessVehicle, isFetching: isFetchingVehicle } = useSelector(
    vehicleRatesSelector
  );

  const [rateVehile, setRateVehile] = useState({});
  const [selRateType, setRateType] = useState("");
  const [selVehicle, setSelVehicle] = useState("");

  const [rateListUpdated, setRateListUpdated] = useState(rateList);

  const popupHandler = () => {
    setVPopup(true);
  };
  const searchBooking = String(localStorage.getItem('search_booking'));
  const searchBookingObj = JSON.parse(searchBooking);
  const currentCountry = searchBookingObj.country;
  
  const countryObj = countryDefualtCurrency.filter(function (item) {
    if (item.country_code == currentCountry) {
      return true;
    }
  })
  const pickupOagcode = searchBookingObj.pickup_oagcode;
  const vendor = searchBookingObj.vendor;
  
  const defaultCurrency = countryObj[0]?.currency_code ?? "";
  
    

  const vehicleDetails: any[] = [];

  if (rateList.length) {
    rateList.map((rate: any, index) => {
      let rateKey = Object.keys(rate)[0];
      var rateObj = rate[rateKey]
      if (rateObj['ShortText']) {

      } else {
        Object.keys(rateObj).map((v: any, i: number) => {
          //console.log('vehicle', v);
          vehicleDetails[v] = rateObj[v];
        })
      }


    })
    //console.log(vehicleDetails);
  }



  const storeSelVehicle = (rateType: string, vehicleCode: string, rateObj: any) => {

    var selectedVeh = {
      "rateType": rateType,
      "vehicleCode": vehicleCode,
      "rateObj": rateObj
    }

    localStorage.removeItem('selected_vehicle');
    localStorage.setItem('selected_vehicle', JSON.stringify(selectedVeh));
    dispatch(vehicleSelectedRate(selectedVeh));

  }
  const [extraData, setExtraData] = useState([]);
  const [pickupLocationExtraData, setPickupLocationExtraData] = useState([]);
  const [selExtras, setSelExtras] = useState<any[]>([]);

  const showExtras = () => {
    const searchBooking = String(localStorage.getItem('search_booking'));
    if (searchBooking) {
      const searchBookingObj = JSON.parse(searchBooking);
      console.log("searchBookingObj",typeof searchBookingObj.extra);
      if(typeof searchBookingObj.extra !== "undefined" && searchBookingObj.extra != "") {
        //let extraArr = searchBookingObj.extra.split(",")
        let extraArr = searchBookingObj.extra;
        let selExtrasArr = extrasList.filter((item) => {
          return extraArr.includes(String(item.id));
        })
        //console.log("selExtrasArr", selExtrasArr,extraArr);
        setSelExtras(selExtrasArr);
      } else {
        setSelExtras([]);
      }
      
    }
  }
  useEffect(() => {
    (async () => {
      //console.log("rateVehicle",rateVehicle)
      //var response = await getExtras(1, 10 , "code", "asc", "GB", "country");
      var response = await getExtras(1, 10, "code", "asc", currentCountry, "country");
      var { data, status } = response;
      if (status === 200) {
        setExtraData(data.data);
      }
      var response = await getLocationsSearchByCodeVendor(pickupOagcode, vendor);
      var { data, status } = response;
      if (status === 200) {
        setPickupLocationExtraData(data?.data[0]?.extras);
      }

      showExtras();

    })()

  }, []);

  useEffect(() => {
    if (isSuccessVehicle && !isFetchingVehicle) {
      showExtras();
    }

  }, [isFetchingVehicle]);

  const closeIncludedHandler = () => {
     setShowIncludedPopup(false)
     
  }
  const setIncludedPopupHandler = (ratePopup:any) => {
    setShowIncludedPopup(true)
    setRatePopup(ratePopup)  
    console.log("ratePopup", ratePopup)
 }
  
  const [showIncludedPopup , setShowIncludedPopup] = useState(false);
  //const [showInclusivePopup , setShowInclusivePopup] = useState(false);
  const [ratePopup , setRatePopup] = useState<any>({});
  return (
    <>
      { showIncludedPopup &&
      <PopupIncluded closeHandler={closeIncludedHandler} rates={ratePopup} country={currentCountry} />
      }
      <div className="table-responsive setMinHeightt" style={{ "maxHeight": "650px", "overflow": "auto" }}>
        <table className="table">
          <thead className="sticky_header">
            <tr className="boxShadow" key={uuidv4()}>
              <th scope="col" className={"sticky_header"} key={uuidv4()}>Vehicle Model</th>
              <th scope="col" className={"sticky_header"} key={uuidv4()}></th>
              {
                rateList.length > 0 ? rateList.map((rate: any, index) => {
                  let rateKey = Object.keys(rate)[0];
                  let ratePopup = Object.keys(rate[rateKey])[0];
                  //console.log(rateKey, ratePopup)
                  return (
                    <>
                      {
                        !rate[rateKey]?.Type && typeof ratePopup != "undefined" ?
                          <th scope="col" key={uuidv4()} className={`sticky_header ${Object.keys(rate)[0]}`}>
                              <div className="sticky_div">
                                <p>{Object.keys(rate)[0]}</p> 
                                <img src={infoIcon} alt="info icon" onClick={() => setIncludedPopupHandler(rate[rateKey][ratePopup])} />
                              </div>
                          </th>
                          :
                          ""
                      }

                    </>
                  )
                })
                  :
                  ""
              }
            </tr>
          </thead>
          <tbody>
            {
              vehicles.length > 0 ? vehicles.map((v: any, index) => {
                //console.log("vehicle", v)
                var vehicleCode = v.vehicle_code;
                var vDetails = vehicleDetails[vehicleCode];

                var transmission_type = vDetails.transmission_type ?? "";
                if (transmission_type !== "") {
                  transmission_type = transmission_type.charAt(0).toUpperCase() + transmission_type.slice(1);
                }

                return (
                  <>
                    <tr key={uuidv4()}>
                      <td key={uuidv4()}>
                        {
                          isElectricVehicle(vehicleCode) &&
                        <div className="vehicle-type-ev">Electric Vehicle</div>
                        }
                        <img
                          src={imagePrefix + v.picture_url}
                          alt="Vehicle Image"
                        />
                      </td>
                      <td key={uuidv4()}>
                        <h5>{v.veh_name}</h5>
                        <div className="car-detail-hertz">
                          <ul className="list-unstyled">
                            <li>
                              <img src={carSeats} alt={"Car seats"} />
                              <span>{vDetails.passener_qty} Seats</span>
                            </li>
                            <li>
                              <img src={carLuggage} alt={"Car Luggage"} />
                              <span>{vDetails.baggage_qty} Luggage</span>
                            </li>
                            <li>
                              <img src={carAutomatic} alt={"Car Automatic"} />
                              {<span>{transmission_type}</span>}
                            </li>
                          </ul>

                          {/* <a href="#">Inclusions</a>
                          <a href="#">To Know</a> */}
                        </div>
                        {
                          selExtras.length > 0 &&
                          <h5>Extras Included:</h5>
                        }
                        {
                          selExtras.length ?

                            <div className="car-detail-hertz" style={{ maxWidth: "250px" }}>
                              <ul className="list-unstyled extra-list">
                                {
                                  selExtras.map((extra: any, index) => {
                                    return (
                                      <li><span>{extra.name}</span></li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                            :
                            ""
                        }
                      </td>
                      {
                        rateList.length > 0 ? rateList.map((rate: any, index) => {

                          //console.log("rate", rate)
                          var rateKey = Object.keys(rate)[0];
                          var rateObj = rate[rateKey][vehicleCode];
                          //console.log(typeof rateObj)
                          var total_price = "";
                          var currency = "";
                          var otherCurrencyCls = "";
                          var priceColorCls = "";
                          var totalPrice = "";
                          var margin_amount;
                          var total_amount_with_margin = 0;
                          if (typeof rateObj != "undefined") {
                            totalPrice = rate[rateKey][vehicleCode]?.total_price;
                            total_amount_with_margin = rate[rateKey][vehicleCode]?.total_amount_with_margin;
                            currency = rate[rateKey][vehicleCode]?.currency;
                            margin_amount = rate[rateKey][vehicleCode]?.margin_amount ?? 0;
                            //console.log(vehicleCode, rateKey, rateObj)
                            //otherCurrencyCls = defaultCurrency != currency ? "btn-inside-table-inverse" : "";
                            otherCurrencyCls = "";
                            if(rateObj.fare_name.toLowerCase() === "hertz net") {
                               priceColorCls = "price_black";
                            } else if(rateObj.fare_name.toLowerCase().indexOf("thrifty") !== -1 ) {
                               priceColorCls = "price_blue";
                            } else if(rateObj.fare_name.toLowerCase().indexOf("dollar") !== -1 ) {
                              priceColorCls = "price_red";
                            }
                            
                           
                            if(rateObj.privilege) {
                              const privilege = rateObj.privilege; 
                              if(privilege == "touroperator") {
                                otherCurrencyCls = "btn-inside-table-inverse";
                                //margin_amount = parseFloat(margin_amount);
                                if(margin_amount) {
                                  margin_amount = parseFloat(margin_amount);
                                  //totalPrice = parseFloat(totalPrice + margin_amount).toFixed(2);  
                                  totalPrice = total_amount_with_margin.toFixed(2);
                                }
                                
                              }
                              //if(margin_amount) {
                                

                              //}
                            }
                            

                          }

                          return (
                            !rate[rateKey]?.Type ?
                              <td key={uuidv4()}>
                                {
                                  totalPrice ?
                                    <button type="button" className={`btn-inside-table ${otherCurrencyCls} ${priceColorCls}`} onClick={() => { popupHandler(); setRateType(rateKey); setRateVehile(rateObj); storeSelVehicle(rateKey, rateObj.vehicle_code, rateObj) }}>
                                      {totalPrice} {" "} {currency}
                                    </button>
                                    :
                                    <button type="button" className="btn-inside-table" style={{ background: "none", textAlign: "center" }}>
                                      NA
                                    </button>

                                }
                              </td>
                              :
                              ""

                          )
                        })
                          :
                          ""
                      }

                    </tr>

                  </>
                )
              })
                :
                ""
            }


          </tbody>
        </table>
        {
          selRateType ?
            <VehiclePopup
              rateType={selRateType}
              rateVehicle={rateVehile}
              vPopup={vPopup}
              extraData={extraData}
              pickupLocationExtraData={pickupLocationExtraData}
              closeHandler={closeHandler}
            />
            :
            ""
        }
      </div>
    </>
  );
};

export default VehicleListItem;
