const accessAll = ['admin','super-admin','user'];
const accessExcludeUser = ['admin','super-admin'];
const footerLink = [
  /* {
      heading: "Customer Support",
      list: [
        { text: "+353539152500", link: "tel:+353539152500", linkAttr: "href" },
        {
          text: "traveltrade@hertz.ie",
          link: "mailto:traveltrade@hertz.ie",
          linkAttr: "href",
        },
      ],
    }, */

  {
    heading: "Quick Links",
    list: [
      { text: "Add this site to favorites", link: "/", linkAttr: "to", access: accessAll  },
      { text: "Download a quick access link", link: "/", linkAttr: "to", access: accessAll  },
      { text: "Privacy Policy", link: "/", linkAttr: "to" },
    ],
  },
];

const headerLinks = [
  {
    headtext: "HERTZ GSA IE\nHERTZ HOUSE FERRYBANK - -WEXFORD(EE)",
    list: [
     /*  { text: "Home", link: "/dashboard", linkAttr: "to", access: accessAll  }, */
      {
        text: "Reservation",
        link: "#",
        linkAttr: "to",
        access: accessAll,
        child: [

          { text: "New Reservation", link: "/booking", linkAttr: "to", access: accessAll  },
          { text: "List Reservations", link: "/travel/list", linkAttr: "to", access: accessAll  },
          
        ],

      },
      {
        text: "Admin",
        link: "#",
        linkAttr: "to",
        access: accessExcludeUser,
        child: [

          { text: "List companies", link: "/company", linkAttr: "to", access: accessExcludeUser },
          { text: "List users", link: "/user", linkAttr: "to", access: accessExcludeUser },
          /* { text: "List markup", link: "/", linkAttr: "to" }, */
          { text: "List rates", link: "/rate", linkAttr: "to", access: accessExcludeUser },
          /* { text: "Fare rules", link: "/fare", linkAttr: "to" }, */
          /* { text: "List communication", link: "/", linkAttr: "to" }, */
          /* { text: "List accessories", link: "/", linkAttr: "to" }, */
          { text: "List locations", link: "/location", linkAttr: "to", access: accessExcludeUser },
          { text: "List accessories", link: "/extra", linkAttr: "to", access: accessExcludeUser },
          { text: "List web pages", link: "/staticpage", linkAttr: "to", access: accessExcludeUser },
          { text: "Setting", link: "/setting", linkAttr: "to", access: accessExcludeUser },
       
           


          /* { text: "Upload File", link: "/", linkAttr: "to" }, */
        ],
      },
      {
        text: "Information",
        link: "#",
        linkAttr: "to",
        access: accessAll,
        child: [
          { text: "About Us", link: "/information/about-us", linkAttr: "to", access: accessAll  },
          { text: "Sales Team", link: "/information/sales-team", linkAttr: "to", access: accessAll  },
          /* {
            text: "Customer Service / Help Desk",
            link: "/information/customer-service",
            linkAttr: "to"
            , access: accessAll
            ,
            
          }, */
          { text: "Optional Extras", link: "/information/optional-extras", linkAttr: "to", access: accessAll  },
          /* { text: "Services", link: "/information/services", linkAttr: "to", access: accessAll }, */
          { text: "Hertz Gold Plus Rewards", link: "/information/hertz-gold-plus-rewards", linkAttr: "to", access: accessAll  },
          {
            text: "Vehicles", link: "https://www.hertz.ie/rentacar/vehicleguide/index.jsp?targetPage=vehicleGuideHomeView.jsp&countryCode=IE&category=Standard%20Cars", linkAttr: "to", access: accessAll ,
            /* subchild: [

              { text: "Europe", link: "/information/Europe", linkAttr: "to", access: accessAll  },
              { text: "USA", link: "/information/USA", linkAttr: "to", access: accessAll  },
            ], */
          },
          { text: "Rental Information", link: "/information/rental-information", linkAttr: "to", access: accessAll  },

        ],
      },
      {
        text: "Terms of Use",
        link: "#",
        linkAttr: "to",
        access: accessAll,
        child: [
          {
            text: "Leisure Rates",
            link: "#",
            linkAttr: "to",
            
            subchild: [
              { text: "Rates & Conditions", link: "/information/ratesandconditions/privilege/leisure", linkAttr: "to" },
              /* { text: "Payment and Commisions", link: "/information/paymentsandcommisions/privilege/business", linkAttr: "to" }, */
              { text: "Billing", link: "/information/billing/privilege/leisure", linkAttr: "to" },
              { text: "Modifications and Cancellations", link: "/information/modificationsandcancellations/privilege/leisure", linkAttr: "to" },
              /* { text: "Special Offers", link: "/information/specialoffers/privilege/business", linkAttr: "to" }, */

              
            ],
          },
          {
            text: "Flexible Rates",
            link: "#",
            linkAttr: "to",
            
            subchild: [
              { text: "Rates & Conditions", link: "/information/ratesandconditions/privilege/business", linkAttr: "to" },
              /* { text: "Payment and Commisions", link: "/information/paymentsandcommisions/privilege/business", linkAttr: "to" }, */
              { text: "Billing", link: "/information/billing/privilege/business", linkAttr: "to" },
              { text: "Modifications and Cancellations", link: "/information/modificationsandcancellations/privilege/business", linkAttr: "to" },
              /* { text: "Special Offers", link: "/information/specialoffers/privilege/business", linkAttr: "to" }, */

            ],
          },
          { text: "Tour Rates", link: "#", linkAttr: "to",
              subchild: [
                { text: "Rates & Conditions", link: "/information/ratesandconditions/privilege/touroperator", linkAttr: "to" },
                /* { text: "Payment and Commisions", link: "/information/paymentsandcommisions/privilege/business", linkAttr: "to" }, */
                { text: "Billing", link: "/information/billing/privilege/touroperator", linkAttr: "to" },
                { text: "Modifications and Cancellations", link: "/information/modificationsandcancellations/privilege/touroperator", linkAttr: "to" },
                /* { text: "Special Offers", link: "/information/specialoffers/privilege/business", linkAttr: "to" }, */
                
              ]

        },
          /* {
            text: "Hertz Leisure",
            link: "#",
            linkAttr: "to",
            subchild: [
              { text: "Rates & Conditions", link: "/information/ratesandconditions/privilege/leisure", linkAttr: "to" },
              { text: "Payment and Commisions", link: "/information/paymentsandcommisions/privilege/leisure", linkAttr: "to" },
              { text: "Billing", link: "/information/billing/privilege/leisure", linkAttr: "to" },
              { text: "Modifications and Cancellations", link: "/information/modificationsandcancellations/privilege/leisure", linkAttr: "to" },
               { text: "Special Offers", link: "/information/specialoffers/privilege/leisure", linkAttr: "to" },
            ],
          }, */
        ],
      },
      {
        text: "Overview", link: "/dashboard", linkAttr: "to",
        access: accessAll,
        /* child: [
          { text: "Manual", link: "/information/manual", linkAttr: "to", access: accessAll, },
        ], */

      },
      /* { text: "Logs", link: "#", linkAttr: "to" }, */
    ],
    user: { firstName: "Sumesh", lastName: "KS", shortName: "KS" },
    userProfieLinks: [
      { text: "Profile", link: "/profile", linkAttr: "to" },
      { text: "Change Password", link: "/change-password", linkAttr: "to" },
      /* { text: "Logout", link: "#", linkAttr: "to" }, */
    ],
  },
];
const userInfo = [
  { label: "Your name is", value: "SUMESH KS" },
  { label: "Company", value: "HERTZ GSA IE" },
  { label: "Address", value: "HERTZ HOUSE FERRYBANK WEXFORD" },
  { label: "Business telephone number	", value: "00353539152500" },
  { label: "Email", value: "sumesh.ks@envigo.co.uk" },
  { label: "Your client code is	", value: "0000003" },
  { label: "Your level is	", value: "super-admin" },
];
const banners = [
  { image: "slider-1.jpg", alt: "Slider 1" },
  { image: "slider-2.jpg", alt: "Slider 2" },
  { image: "slider-2.jpg", alt: "Slider 3" },
];
const tableData = [
  {
    Name: "HERTZ-GSA.IE",
    Parent: "HERTZ GSA",
    Address: "HERTZ HOUSE, FERRYB...",
    City: "WEXFORD",
    Email: "traveltrade@hertz.ie",
    Code: "0000003",
    Action: "edit",
  },
  {
    Name: "Club Corporate",
    Parent: "HERTZ-GSA.IE",
    Address: "Suite 103, Dublin Airport",
    City: "Dublin 1",
    Email: "sdalton@ie.hrgworldwide..",
    Code: "0000011",
    Action: "edit",
  },
  {
    Name: "Travel Leaders (TMI)",
    Parent: "HERTZ-GSA.IE",
    Address: "14 The Fairways, Golf ...",
    City: "Co. Dublin",
    Email: "lkelly@clubtravel.ie",
    Code: "0000012",
    Action: "edit",
  },
  {
    Name: "Worldchoice Ireland",
    Parent: "Worldchoice Ireland",
    Address: "43-45 Middle Abbey Str...",
    City: "Dublin 9",
    Email: "Alan.Mcara@travelman..",
    Code: "0000013",
    Action: "edit",
  },
  {
    Name: "HERTZ-GSA.IE",
    Parent: "HERTZ GSA",
    Address: "HERTZ HOUSE, FERRYB...",
    City: "WEXFORD",
    Email: "traveltrade@hertz.ie",
    Code: "0000003",
    Action: "edit",
  },
  {
    Name: "Club Corporate",
    Parent: "HERTZ-GSA.IE",
    Address: "Suite 103, Dublin Airport",
    City: "Dublin 1",
    Email: "sdalton@ie.hrgworldwide..",
    Code: "0000011",
    Action: "edit",
  },
  {
    Name: "Travel Leaders (TMI)",
    Parent: "HERTZ-GSA.IE",
    Address: "14 The Fairways, Golf ...",
    City: "Co. Dublin",
    Email: "lkelly@clubtravel.ie",
    Code: "0000012",
    Action: "edit",
  },
  {
    Name: "Worldchoice Ireland",
    Parent: "Worldchoice Ireland",
    Address: "43-45 Middle Abbey Str...",
    City: "Dublin 9",
    Email: "Alan.Mcara@travelman..",
    Code: "0000013",
    Action: "edit",
  },
  {
    Name: "HERTZ-GSA.IE",
    Parent: "HERTZ GSA",
    Address: "HERTZ HOUSE, FERRYB...",
    City: "WEXFORD",
    Email: "traveltrade@hertz.ie",
    Code: "0000003",
    Action: "edit",
  },
  {
    Name: "Club Corporate",
    Parent: "HERTZ-GSA.IE",
    Address: "Suite 103, Dublin Airport",
    City: "Dublin 1",
    Email: "sdalton@ie.hrgworldwide..",
    Code: "0000011",
    Action: "edit",
  },
  {
    Name: "Travel Leaders (TMI)",
    Parent: "HERTZ-GSA.IE",
    Address: "14 The Fairways, Golf ...",
    City: "Co. Dublin",
    Email: "lkelly@clubtravel.ie",
    Code: "0000012",
    Action: "edit",
  },
  {
    Name: "Worldchoice Ireland",
    Parent: "Worldchoice Ireland",
    Address: "43-45 Middle Abbey Str...",
    City: "Dublin 9",
    Email: "Alan.Mcara@travelman..",
    Code: "0000013",
    Action: "edit",
  },
  {
    Name: "HERTZ-GSA.IE",
    Parent: "HERTZ GSA",
    Address: "HERTZ HOUSE, FERRYB...",
    City: "WEXFORD",
    Email: "traveltrade@hertz.ie",
    Code: "0000003",
    Action: "edit",
  },
  {
    Name: "Club Corporate",
    Parent: "HERTZ-GSA.IE",
    Address: "Suite 103, Dublin Airport",
    City: "Dublin 1",
    Email: "sdalton@ie.hrgworldwide..",
    Code: "0000011",
    Action: "edit",
  },
  {
    Name: "Travel Leaders (TMI)",
    Parent: "HERTZ-GSA.IE",
    Address: "14 The Fairways, Golf ...",
    City: "Co. Dublin",
    Email: "lkelly@clubtravel.ie",
    Code: "0000012",
    Action: "edit",
  },
  {
    Name: "Worldchoice Ireland",
    Parent: "Worldchoice Ireland",
    Address: "43-45 Middle Abbey Str...",
    City: "Dublin 9",
    Email: "Alan.Mcara@travelman..",
    Code: "0000013",
    Action: "edit",
  },
];
export { footerLink, headerLinks, userInfo, banners, tableData };
