import React, {useEffect, useState} from "react";
import Slider from 'react-slick';
import {userInfo, banners} from '../../data';
import "../../assets/scss/dashboard.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImg from '../../assets/images/slider-1.jpg';
import { v4 as uuidv4 } from "uuid";


import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from '../../store/slices/user';
import Loader from "../../components/loading/loader"
import { getUserDetials } from "../../external_api/User";

const Profile = () => {
  const { isFetching, isSuccess: isLogin,id: userId, first_name: userFirstName, last_name: userLastName, name: userName, email: userEmail, phone: userPhone, company: userCompanyInfo, role: userRoleInfo } = useSelector(
    userSelector
  );
    
    const navigate = useNavigate();
    if(isLogin === false) {
        navigate("/");
    }
    //console.log("isLogin",isLogin)
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };

    type IUserValues = {
      first_name: string,
      last_name: string,
      profile: Array<number>,
      company_id: string,
      role_id: string,
      phone: string,
      email: string,
      name: string,
      address: string,
      status: string,
      company: {
        name: string,
        code: string
      },
      roles: {
        slug:string
      }
  }
    const [userDetail, setUserDetail] = useState<IUserValues>();
    useEffect(() => {
      (async () => {
      if(isLogin === false) {
        navigate("/");
      } else {
        var response = await getUserDetials(userId);
        var { data, status } = response;
        if (status === 201) {
            //setTimeout(() => setUserDetail(data), 1000);
            setUserDetail(data);
        } else {
            navigate("/user");
        }
      }
    
    })()
  
  }, []);
    
    

    return (
      <div className="container-fluid dashboard">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="white-box">
                  <h2 className="title">User profile</h2>
                  <div className="table-responsive">
                  <table className="table table-hover">
                      <tbody>
                        <tr>
                          <td>Your name is</td>
                          <td>{userDetail?.first_name ?? ""}{" "}{userDetail?.last_name ?? ""}</td>
                        </tr>
                        
                         <tr>
                          <td>Company</td>
                          <td>{userDetail?.company?.name ?? ""}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>{userDetail?.address ?? ""}</td>
                        </tr>
                        <tr>
                          <td>Business telephone number </td>
                          <td>{userDetail?.phone && userDetail?.phone !== "null" ? userDetail?.phone : ""}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{userDetail?.email ?? ""}</td>
                        </tr>
                        <tr>
                          <td>Your client code is </td>
                          <td>{userDetail?.company?.code ?? ""}</td>
                        </tr>
                        <tr>
                          <td>Your level is </td>
                          <td>{userDetail?.roles?.slug ?? ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <Slider className="banner-slider" {...settings}>
                  {banners.map((banner: any) => {
                    return (
                      <div className="slider-item" key={uuidv4()}>
                        <figure>
                          <img
                            src={sliderImg}
                            className="img-fw"
                            alt={banner.alt}
                          />
                        </figure>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );



}

export default Profile;