import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/scss/booking.scss";
import "../../assets/scss/modify_datepicker.scss";
import MapQuestDirections from "./MapQuestMap";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { clearState, userSelector } from '../../store/slices/user';
import { clearSelectedVehicleState } from '../../store/slices/selectedVehicle';
import { clearVehicleListState } from '../../store/slices/vehicleAvailable';

import toast, { Toaster } from 'react-hot-toast';

import Loader from "../../components/loading/loader"
import moment from "moment";
import { getLocations } from "../../external_api/Location";
import { getReservationDetail, getReservationPdf, getVehicleAvailable, sendEmail } from "../../external_api/Booking";


import ServerAutoSuggest from "../../components/common/ServerAutoSuggest";
import { AppDispatch } from "../../store";
import AppUrl from "../../config/AppUrl";

import configRoutes from "../../config/Routes";

import { resetExtraItems } from '../../store/slices/extras'

const BookingEdit = () => {

  const { isFetching, isSuccess: isLogin } = useSelector(
    userSelector
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  if (!isLogin) {
    navigate("/");
  }

  const paramData = useParams();
  const [reservationId, setReservationId] = useState(paramData.reservationId);



  const validationSchema = Yup.object().shape({
    pickup_time: Yup.string()
      .required('Pickup time is required'),
    dropoff_time: Yup.string()
      .required('Dropoff time is required'),
    pickup_date: Yup.string()
      .required('Pickup date is required'),
    dropoff_date: Yup.string().required('Pickup date is required'),
    driver_age: Yup.string()
      .required('Driver age is required'),
    pickup_location: Yup.string().default(""),
    dropoff_location: Yup.string().default(""),
    pickup_oagcode: Yup.string()
      .required('Pickup Location is required'),
    dropoff_oagcode: Yup.string()
      .required('Dropoff Location is required'),
    country: Yup.string(),
    vendor: Yup.string(),
    reservation_id: Yup.string()
      .required('Reservation Id'),
  });


  type FormValues = {
    drop_location: string,
    pickup_time: string,
    dropoff_time: string,
    driver_age: string,
    //payment_type: string,
    pickup_location: string,
    dropoff_location: string,
    pickup_oagcode: string,
    dropoff_oagcode: string,
    pickup_date: string | null,
    dropoff_date: string,
    reservation_id: string,
    country: string,
    vendor: string,

  }

  const { register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    control
  } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


  //console.log("errors", errors)

  const [loading, setLoading] = useState(false);

  const [bookingDefaultData, setBookingDefaultData] = useState(
    {
      drop_location: "",
      pickup_time: "12:00",
      dropoff_time: "12:00",
      pickup_location: "",
      dropoff_location: "",
      pickup_oagcode: "",
      dropoff_oagcode: "",
      pickup_date: "",
      dropoff_date: "",
      country: "",
      vendor: "",
      reservation_id: "",
      driver_age: "",
      status: ""

    }
  );



  const [locLat, setLocLat] = useState("53.3498");
  const [locLon, setLocLon] = useState("-6.2603");


  const [country, setCountry] = useState("");
  const [vendor, setVendor] = useState("");


  const [selPickupLoc, SetSelPickupLoc] = useState("")
  const [selDropoffLoc, SetSelDropoffLoc] = useState("")
  const [selPickupOagcode, SetSelPickupOagcode] = useState("")
  const [selDropoffOagcode, SetSelDropoffOagcode] = useState("")

  const [selPickupDate, SetSelPickupDate] = useState<Date | null>(null)
  const [selDropoffDate, SetSelDropoffDate] = useState<Date | null>(null)


  const [pickupDetail, setPickupDetail] = useState<any>(null)
  const [dropoffDetail, setDropoffDetail] = useState<any>(null)

  //console.log("selDropoffOagcode", selDropoffOagcode)


  const onSubmitForm = async (formData: any) => {
    //console.log("Booking formData", formData);
    dispatch(resetExtraItems());
    var formNewData = {
      pickUpLoc: formData.pickup_oagcode,
      pickUpDate: formData.pickup_date,
      pickUpTime: formData.pickup_time,
      retLoc: formData.dropoff_oagcode,
      retDate: formData.dropoff_date,
      retTime: formData.dropoff_time,
      country: formData.country,
      vendor: formData.vendor,
      //payment_type: formData.payment_type,
      dob: formData.driver_age,
      extras: formData.extras,
      reservation_id: formData.reservation_id,

    }


    setLoading(true);
    var response = await getVehicleAvailable(formNewData);
    const { data, status, errorMessage } = response;
    if (status == 201) {
      setLoading(false);
      localStorage.setItem('search_booking', JSON.stringify(formData));
      setTimeout(() => {
        navigate("/booking/vehicle-list/edit/" + reservationId);
      }, 500);
    } else {
      if (errorMessage.errors) {
        toast.error(errorMessage.errors.ShortText);
      } else {
        if (errorMessage === "Unauthenticated.") {
          dispatch(clearState());
          localStorage.clear();
        } else {
          toast.error(errorMessage);
        }

      }

    }
    setLoading(false);

  };

  const onSendMail = async () => {

    if (!reservationId) {
      toast.error('Reservation id should not be empty');
    }
    setLoading(true);
    var response = await sendEmail(reservationId);
    const { data, status, errorMessage } = response;
    if (status == 201) {
      setLoading(false);
      toast.success("Mail has been sent successfully");
    } else {
      if (errorMessage === "Unauthenticated.") {
        dispatch(clearState());
        localStorage.clear();
      } else {
        toast.error(errorMessage);
      }

    }
    setLoading(false);

  };

  const downloadPdf = async () => {

    if (!reservationId) {
      toast.error('Reservation id should not be empty');
    }
    setLoading(true);
    var response = await getReservationPdf(reservationId);
    const { data, status, errorMessage } = response;
    if (status == 201) {
      setLoading(false);
      toast.success("Mail has been sent successfully");
    } else {
      if (errorMessage === "Unauthenticated.") {
        dispatch(clearState());
        localStorage.clear();
      } else {
        //toast.error(errorMessage);
      }

    }
    setLoading(false);

  };
  /* const ref = useRef(null);
  const ref = useRef(null);
   */
  localStorage.removeItem('booked_reservation_id');
  dispatch(clearVehicleListState());
  dispatch(clearSelectedVehicleState());

  useEffect(() => {
    setValue('pickup_location', selPickupLoc)
    setValue("pickup_oagcode", selPickupOagcode)
    setValue("dropoff_location", selDropoffLoc)
    setValue("dropoff_oagcode", selDropoffOagcode)

  }, [selPickupOagcode, selDropoffLoc])

  useEffect(() => {
    let pickupDate = selPickupDate ? moment(new Date(selPickupDate)).format("YYYY-MM-DD") : "";
    setValue("pickup_date", pickupDate);
    let dropOffDate = selDropoffDate ? moment(new Date(selDropoffDate)).format("YYYY-MM-DD") : "";
    setValue("dropoff_date", dropOffDate)
    //setValue("country", country)


  }, [selPickupDate, selDropoffDate])


  useEffect(() => {
    (async () => {
      setLoading(true);
      var response = await getReservationDetail(String(reservationId));
      var { data, status } = response;

      if (status === 201) {
        setTimeout(() => setBookingDefaultData(data.reservation), 500);
        setPickupDetail(data.pickupLocDetail);
        setDropoffDetail(data.dropoffLocDetail);
        setLocLat(data.pickupLocDetail.latitude);
        setLocLon(data.pickupLocDetail.longitude);
        //setUserDetail(data);
      } else {
        //navigate("/rate");
      }
      setLoading(false);

      //console.log("profile data",data.output);
    })()

  }, []);


  useEffect(() => {
    console.log('bookingDefaultData', bookingDefaultData);
    if (bookingDefaultData.pickup_date) {
      const pickupDate = new Date(bookingDefaultData.pickup_date);
      SetSelPickupDate(pickupDate);
    }
    if (bookingDefaultData.dropoff_date) {
      const dropoffDate = new Date(bookingDefaultData.dropoff_date);
      SetSelDropoffDate(dropoffDate);
      console.log("bookingDefaultData.pickup_location", bookingDefaultData.pickup_location)
     

      let eleDropoffContainer = document.querySelectorAll('input[aria-controls=react-autowhatever-dropoff]');
      if (eleDropoffContainer[0])
        eleDropoffContainer[0].setAttribute("value", bookingDefaultData.dropoff_location);

    }

    setTimeout(() => {
      //@ts-ignore
      //document.querySelectorAll('input[aria-controls=react-autowhatever-pickup]')[0].value = bookingDefaultData.pickup_location;
      //@ts-ignore
      //document.querySelectorAll('input[aria-controls=react-autowhatever-dropoff]')[0].value = bookingDefaultData.dropoff_location;
    }, 50);

    SetSelPickupLoc(bookingDefaultData.pickup_location);
    SetSelPickupOagcode(bookingDefaultData.pickup_oagcode);
    SetSelDropoffLoc(bookingDefaultData.dropoff_location);
    SetSelDropoffOagcode(bookingDefaultData.dropoff_oagcode);

    //setLoc()
    //setLocLat(bookingDefaultData.drop_location);
    //setLocLon(bookingDefaultData.drop_location);

    //bookingDefaultData.drop_location
    console.log(bookingDefaultData);
    return reset(
      {
        drop_location: bookingDefaultData.drop_location,
        pickup_time: bookingDefaultData.pickup_time.substring(0, 5),
        dropoff_time: bookingDefaultData.dropoff_time,
        pickup_location: bookingDefaultData.pickup_location,
        dropoff_location: bookingDefaultData.dropoff_location,
        pickup_oagcode: bookingDefaultData.pickup_oagcode,
        dropoff_oagcode: bookingDefaultData.dropoff_oagcode,
        pickup_date: bookingDefaultData.pickup_date,
        dropoff_date: bookingDefaultData.dropoff_date,
        country: bookingDefaultData.country,
        vendor: bookingDefaultData.vendor,
        reservation_id: bookingDefaultData.reservation_id,
        driver_age: bookingDefaultData.driver_age ?? 30


      }
    );
  }, [reset, bookingDefaultData]);

  const cancelPage = () => {
    setLoading(true);
    window.location.href = "https://www.hertz.co.uk/rentacar/reservation/"
  }

  return (
    <>
      <Toaster
        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <section className="container-fluid wiz-form-hertz">
        <div className="row">
          <div className="container">
            <div className="container-with-border">
              <div className="tab-hertz">
                <ul className="list-inline d-flex justify-content-between tab-list-hertz">
                  <li className="list-inline-item active">
                    <button type="button">SEARCH</button>
                  </li>
                  <li className="list-inline-item">
                    <button type="button">RESULT</button>
                  </li>
                  <li className="list-inline-item">
                    <button type="button">CUSTOMER INFORMATION</button>
                  </li>
                </ul>
                <div className="tab-wiz-inner-hertz">
                  <div className="tab-inner-hertz" style={{ display: "block" }}>
                    <div className="col-12">
                      <div className="row">
                        <div className="form-wrapper col-8 w-100-tab order-lg-1 order-md-2 order-sm-last" style={{ margin: 0 }}>
                          <form
                            className="spacertopform form_bg"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="bookingForm"
                            id="bookingForm"
                            autoComplete="off"
                          >
                            <div className="inner-tab-hertz-content">
                              <h2>Edit Reservation</h2>
                              <div className="main-form-hertz">

                                <div className="row">
                                  <div className="col-6 ">
                                    <div className="promotion-hertz bg-gray">
                                      <label className="label-space-6">
                                        Pick up location<span className="red">*</span>
                                      </label>
                                      {/* <select name="pickup-location">
                                        <option value="0" selected disabled>
                                          Select Location
                                        </option>
                                      </select> 
                                      const user = JSON.parse(localStorage.getItem('user'));
                                      */}
                                      {
                                        pickupDetail ?
                                          <input
                                            id="pickup_location_disable"
                                            /* defaultValue={selPickupOagcode+' - ' + pickupDetail?.country +' - '+selPickupLoc} */
                                            defaultValue={pickupDetail?.code + ' - ' + pickupDetail?.country + ' - ' + pickupDetail?.city}
                                            disabled={true}
                                          />
                                          :
                                          ""
                                      }

                                    </div>
                                    <span className="formFieldError error">{errors.pickup_oagcode?.message}</span>
                                    <div style={{ "position": "absolute", "left": "-200px", "display": "none" }} >
                                      <input
                                        {...register("pickup_location")}
                                        id="pickup_location"
                                        /* type="hidden"  */
                                        /* defaultValue={selPickupLoc} */
                                        value={selPickupLoc}
                                      /* ref={refPickupLoc} */

                                      />
                                      <input
                                        {...register("pickup_oagcode")}
                                        value={selPickupOagcode}

                                      /* ref={refPickupOagcode} */
                                      />
                                      <input
                                        id="country"
                                        {...register("country")}
                                        value={country}
                                      />
                                      <input
                                        id="vendor"
                                        {...register("vendor")}
                                        value={vendor}
                                      />
                                    </div>
                                  </div>

                                  <div className={`col-6`}  >
                                    <div className="pickup-location-hertz searchAutocomplete modified bg-white">
                                      <label className="label-space-6">
                                        Drop up location<span className="red">*</span>
                                      </label>
                                      {/* <select name="pickup-location">
                                          <option value="0" selected disabled>
                                            Select Location
                                          </option>
                                        </select> */}
                                      {
                                        selDropoffLoc != "" ?
                                          <ServerAutoSuggest
                                            name={'dropoff'}
                                            SetSelPickupLoc={SetSelPickupLoc}
                                            SetSelPickupOagcode={SetSelPickupOagcode}
                                            SetSelDropoffLoc={SetSelDropoffLoc}
                                            SetSelDropoffOagcode={SetSelDropoffOagcode}
                                            setLocLat={setLocLat}
                                            setLocLon={setLocLon}
                                            setCountry={setCountry}
                                            defaultValue={selDropoffOagcode + ' - ' + dropoffDetail.country + ' - ' + selDropoffLoc}

                                          />
                                          :
                                          ""
                                      }

                                    </div>
                                    <span className="formFieldError error">{errors.dropoff_oagcode?.message}</span>
                                    <div style={{ "position": "absolute", "left": "-300px", "display": "none" }} >
                                      <input
                                        {...register("dropoff_location")}
                                        value={selDropoffLoc}

                                      />
                                      <input
                                        {...register("dropoff_oagcode")}
                                        value={selDropoffOagcode}
                                        onChange={(e: any) => SetSelDropoffOagcode(e.target.value)}
                                      />
                                    </div>
                                  </div>

                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <div className="d-flex block-mobile">
                                      <div className="col-8">
                                        <div className="pickup-date-hertz datepickerIcon bg-white">
                                          <label className="label-space-4">
                                            Pick-up date<span className="red">*</span>
                                          </label>
                                          <DatePicker
                                            monthsShown={2}
                                            selected={selPickupDate}

                                            //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                            onChange={(date: any) => { SetSelPickupDate(date); SetSelDropoffDate(null) }}
                                            dateFormat={"dd/MM/yyyy"}
                                            placeholderText="DD/MM/YYYY"
                                            selectsStart
                                            startDate={selPickupDate}
                                            minDate={new Date()}
                                          />
                                          <input
                                            type={"hidden"}
                                            {...register("pickup_date")}
                                            //value={selPickupDate ? moment(new Date(selPickupDate)).format("YYYY-MM-DD") : ""}
                                            defaultValue={selPickupDate ? moment(new Date(selPickupDate)).format("YYYY-MM-DD") : ""}
                                          //ref={refPickupDate}
                                          /* onChange={handlerInput} */
                                          />
                                          <span className="formFieldError error">{errors.pickup_date?.message}</span>
                                        </div>

                                        {/* <span className="formFieldError error">{errors.pickup_date?.message}</span> */}
                                      </div>
                                      <div className="col-4">
                                        <div className="pickup-time-hertz bg-white">
                                          <label className="label-space-6">
                                            Time<span className="red">*</span>
                                          </label>
                                          <select
                                            className="pickup-time dropLocation"
                                            {...register("pickup_time")}
                                            defaultValue={bookingDefaultData.pickup_time}

                                          >
                                            <option value="00:00">00:00</option>
                                            <option value="00:30">00:30</option>
                                            <option value="01:00">01:00</option>
                                            <option value="01:30">01:30</option>
                                            <option value="02:00">02:00</option>
                                            <option value="02:30">02:30</option>
                                            <option value="03:00">03:00</option>
                                            <option value="03:30">03:30</option>
                                            <option value="04:00">04:00</option>
                                            <option value="04:30">04:30</option>
                                            <option value="05:00">05:00</option>
                                            <option value="05:30">05:30</option>
                                            <option value="06:00">06:00</option>
                                            <option value="06:30">06:30</option>
                                            <option value="07:00">07:00</option>
                                            <option value="07:30">07:30</option>
                                            <option value="08:00">08:00</option>
                                            <option value="08:30">08:30</option>
                                            <option value="09:00">09:00</option>
                                            <option value="09:30">09:30</option>
                                            <option value="10:00">10:00</option>
                                            <option value="10:30">10:30</option>
                                            <option value="11:00">11:00</option>
                                            <option value="11:30">11:30</option>
                                            <option
                                              value="12:00"
                                            >
                                              12:00
                                            </option>
                                            <option value="12:30">12:30</option>
                                            <option value="13:00">13:00</option>
                                            <option value="13:30">13:30</option>
                                            <option value="14:00">14:00</option>
                                            <option value="14:30">14:30</option>
                                            <option value="15:00">15:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="16:00">16:00</option>
                                            <option value="16:30">16:30</option>
                                            <option value="17:00">17:00</option>
                                            <option value="17:30">17:30</option>
                                            <option value="18:00">18:00</option>
                                            <option value="18:30">18:30</option>
                                            <option value="19:00">19:00</option>
                                            <option value="19:30">19:30</option>
                                            <option value="20:00">20:00</option>
                                            <option value="20:30">20:30</option>
                                            <option value="21:00">21:00</option>
                                            <option value="21:30">21:30</option>
                                            <option value="22:00">22:00</option>
                                            <option value="22:30">22:30</option>
                                            <option value="23:00">23:00</option>
                                            <option value="23:30">23:30</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="d-flex block-mobile">
                                      <div className="col-8">
                                        <div className="pickup-date-hertz datepickerIcon bg-white">
                                          <label className="label-space-4">
                                            Return date<span className="red">*</span>
                                          </label>
                                          <DatePicker
                                            monthsShown={2}
                                            selected={selDropoffDate}
                                            //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                            onChange={(date: any) => SetSelDropoffDate(date)}
                                            dateFormat={"dd/MM/yyyy"}
                                            placeholderText="DD/MM/YYYY"
                                            selectsEnd
                                            startDate={selPickupDate}
                                            endDate={selDropoffDate}
                                            minDate={selPickupDate}

                                          />
                                          <input
                                            type="hidden"
                                            value={moment(new Date(selDropoffDate ?? new Date())).format("YYYY-MM-DD") ?? ""}
                                            /* ref={refDropoffDate} */
                                            {...register("dropoff_date")}
                                          /* onChange={(e:any) => SetSelDropoffDate(e.target.value)} */


                                          />
                                          <span className="formFieldError error">{errors.dropoff_date?.message}</span>
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="pickup-time-hertz bg-white">
                                          <label className="label-space-4">
                                            Time<span className="red">*</span>
                                          </label>
                                          <select
                                            className="pickup-time dropLocation"
                                            {...register("dropoff_time")}
                                            defaultValue={bookingDefaultData.dropoff_time}
                                          >
                                            <option value="00:00">00:00</option>
                                            <option value="00:30">00:30</option>
                                            <option value="01:00">01:00</option>
                                            <option value="01:30">01:30</option>
                                            <option value="02:00">02:00</option>
                                            <option value="02:30">02:30</option>
                                            <option value="03:00">03:00</option>
                                            <option value="03:30">03:30</option>
                                            <option value="04:00">04:00</option>
                                            <option value="04:30">04:30</option>
                                            <option value="05:00">05:00</option>
                                            <option value="05:30">05:30</option>
                                            <option value="06:00">06:00</option>
                                            <option value="06:30">06:30</option>
                                            <option value="07:00">07:00</option>
                                            <option value="07:30">07:30</option>
                                            <option value="08:00">08:00</option>
                                            <option value="08:30">08:30</option>
                                            <option value="09:00">09:00</option>
                                            <option value="09:30">09:30</option>
                                            <option value="10:00">10:00</option>
                                            <option value="10:30">10:30</option>
                                            <option value="11:00">11:00</option>
                                            <option value="11:30">11:30</option>
                                            <option
                                              value="12:00"
                                            >
                                              12:00
                                            </option>
                                            <option value="12:30">12:30</option>
                                            <option value="13:00">13:00</option>
                                            <option value="13:30">13:30</option>
                                            <option value="14:00">14:00</option>
                                            <option value="14:30">14:30</option>
                                            <option value="15:00">15:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="16:00">16:00</option>
                                            <option value="16:30">16:30</option>
                                            <option value="17:00">17:00</option>
                                            <option value="17:30">17:30</option>
                                            <option value="18:00">18:00</option>
                                            <option value="18:30">18:30</option>
                                            <option value="19:00">19:00</option>
                                            <option value="19:30">19:30</option>
                                            <option value="20:00">20:00</option>
                                            <option value="20:30">20:30</option>
                                            <option value="21:00">21:00</option>
                                            <option value="21:30">21:30</option>
                                            <option value="22:00">22:00</option>
                                            <option value="22:30">22:30</option>
                                            <option value="23:00">23:00</option>
                                            <option value="23:30">23:30</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="promotion-hertz">
                                          <label className="label-space-4">Confirmation Number</label>
                                          <input
                                            type="text"
                                            defaultValue={bookingDefaultData.reservation_id}
                                            /* value="K20344831C8" */
                                            disabled={true} />

                                          <input
                                            type="hidden"
                                            defaultValue={bookingDefaultData.reservation_id}
                                            {...register("reservation_id")}
                                          /* value="K20344831C8" */
                                          />

                                          <input
                                            type="hidden"
                                            defaultValue={bookingDefaultData.driver_age}
                                            {...register("driver_age")}
                                          /* value="K20344831C8" */
                                          />


                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="promotion-hertz">
                                          <label className="label-space-4">Status</label>
                                          {Number(bookingDefaultData.status) == 1 ?
                                            <input type="text" value="Confirmed" disabled={true} />
                                            :
                                            <input type="text" value="Cancelled" disabled={true} />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {Number(bookingDefaultData.status) == 1 &&
                                  <div className="row">
                                    <div className="col-6 share-and-send-email">
                                      <div className="d-flex justify-content-between">
                                        <button type="button" onClick={onSendMail}>Send Email</button>
                                        <button type="button" onClick={downloadPdf}>Download PDF</button>
                                        {/* <button type="button" onClick={cancelPage}>Cancel</button> */}
                                      </div>
                                    </div>
                                  </div>
                                }
                                <div className="row">
                                  <div className="col-6 save-btn-group-hertz">
                                    <div className="d-flex block-mobile justify-content-between">
                                      {/* <button className="btn-back-hertz">Back</button> */}
                                      <button className="btn-continue-hertz" onClick={(e) => { e.preventDefault(); window.location.href = configRoutes.booking_list }}>Back </button>
                                      {Number(bookingDefaultData.status) == 1 &&
                                        <button type="submit" className="btn-continue-hertz">Continue</button>
                                      }
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-4 w-100-tab order-lg-2 order-sm-first order-md-1 ">
                          <div className="map-image-hertz">
                            {
                              pickupDetail ?
                                <MapQuestDirections
                                  zoom={12}
                                  lat={locLat}
                                  lon={locLon}
                                  name={pickupDetail.city}
                                  address={pickupDetail.address}
                                  operationHour={pickupDetail.operation_hours ?? ""}
                                />
                                :
                                ""

                            }
                            {/* <img
                              src={mapImage}
                              className="w-100 h-100"
                              alt="map"
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingEdit;
