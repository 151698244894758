import axios from 'axios';
//import AppUrl from '../config/AppUrls';
const baseApiURL = process.env.REACT_APP_API_URL;

//axios.defaults.withCredentials = true;

//@ts-ignore
const axiosInstance = axios.create({
    baseURL:baseApiURL,
    withCredentials: false,
  });
  



//@ts-ignore
axiosInstance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      //const token = "";
      //const token = localStorage.getItem('hertzTravelUserToken');
      const token = `Bearer ${localStorage.getItem('hertzTravelUserToken')}`;
        if (token) {
            //@ts-ignore
            config.headers.authorization = token;
            
            /* config.headers.common = {
              Authorization: `${token}`
            } */
        }
   
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  
  
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

export default axiosInstance;