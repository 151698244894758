//import * as React from "react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import "../../assets/scss/login.scss";
import Titlestrip from "../../utility/Titlestrip";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { resetPassword, verifyToken,  } from "../../external_api/User";
import { userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import Loader from "../../components/loading/loader"

import { decode } from 'js-base64';


const ResetPassword = () => {

  const {  isSuccess: isLogin } = useSelector(
    userSelector
  );
  const [loading, setLoading] = useState(false);
  
  const [errorResMsg, setErrorResMsg] = useState({
    status: false,
    msg: ""
  });

  const paramData = useParams();
  const token = decode(String(paramData.token));
  const tokenArr = token.split("####")
  const [userTokenDecoded, setUserTokenDecoded] = useState(token);
  const [userName, setUserName] = useState(tokenArr[2] ?? "");
  const [userEmail, setUserEmail] = useState(tokenArr[1] ?? "");
  const [userToken, setUserToken] = useState(tokenArr[0] ?? "");
  const [userTokenInvalid, setUserTokenInvalid] = useState(true);

  const navigate = useNavigate();
  if (isLogin) {
    navigate("/dashboard");
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('New Password is required')
      .min(6, 'Password must be at least 6 characters'),
    password_confirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match with New password')
  });

  //const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };

  type FormValues = {
    password: string,
    password_confirm: string,
  }
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


  const onSubmitForm = async (formData: any) => {
    formData['email'] = userEmail;
    formData['username'] = userName;
    setErrorResMsg(
      {
        status:false,
        msg: ""
      })
    //return true;
    setLoading(true);
    var response = await resetPassword(formData);
    const { data, status, errorMessage } = response;
    console.log(response);
    if (status == 200) {
      reset();
      navigate("/");
      toast.success("Password has been reset");
      
    } else {
      setErrorResMsg(
        {
          status:false,
          msg: errorMessage
        })
      //toast.error(errorMessage);
    }
    setLoading(false);

  };

  useEffect(() => {
    (async () => {
        setErrorResMsg(
            {
              status:false,
              msg: ""
            })
          setLoading(true);
          let formData = {
                email: userEmail,
                token: userToken
          }
          var response = await verifyToken(formData);
          const { data, status, errorMessage } = response;
          //console.log(response);
          if (status == 200) {
            setUserTokenInvalid(false);
            //toast.success("Password has been reset");
            //reset();
            //navigate("/");
          } else {
            setErrorResMsg(
              {
                status:false,
                msg: errorMessage
              })
            //toast.error(errorMessage);
          }
          setLoading(false);

        
    })()

}, []);


  return (
    <>
      <Toaster

        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <Titlestrip title={"Reset Password"} />
      <div className="container-fluid bg-image">
        <div className="row">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center center-height">
              <div className="outer-form-container">
                <div className="form-container">
                  {/* <h1 className="heading-h1-container">Change Password</h1>
                  <h5 className="heading-h5-container">
                    If you don't have an account
                  </h5>
                  <h6 className="heading-h6-container">
                    You can <Link to="">Register here!</Link>
                  </h6> */}
                  <form
                    className="spacertopform"
                    onSubmit={handleSubmit(onSubmitForm)}
                    name="userEdit"
                    id="userEdit"
                    autoComplete="off"
                  >
                    {
                      errorResMsg.msg ?
                        <div className="col-12">
                          <div className={`alert ${!errorResMsg.status ? "alert-danger set-default-fs" : ""} ${errorResMsg.status ? "alert-success" : ""}`} role="alert">
                            {errorResMsg.msg}
                          </div>
                        </div>
                        :
                        ""
                    }
                    { userTokenInvalid !== true ?
                      <>  
                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>New password<span className="red">*</span></label>
                        <input
                          className="input-field"
                          type="password"
                          {...register("password")}
                        />

                      </div>
                      <span className="formFieldError error">{errors.password?.message}</span>
                    </div>

                    <div className="form-field">
                      <div className="form-field-inner">
                        <label>Retype New password<span className="red">*</span></label>
                        <input
                          className="input-field"
                          type="password"
                          {...register("password_confirm")}
                        />
                      </div>
                      <span className="formFieldError error">{errors.password_confirm?.message}</span>
                    </div>


                    <div className="d-flex flex-wrap align-content-center justify-content-between">
                      <button
                        className="btn-yellow"
                        type="submit"
                      /* disabled={isSubmitting} */
                      >
                        Reset Password
                      </button>

                      <p className="para-text">
                        <Link to="/" className="link-container">
                          Cancel
                        </Link>
                      </p>
                    </div>
                    </>
                    :
                    ""
                    }
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
