import * as React from "react";
import Loader from "../../components/loading/loader";
import aboutImg from "../../assets/images/about_us_supercar.png";
import "../../assets/scss/static.scss";
const About = () => {
  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid static-pages">
        {/* static code section start */}
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-12  text-center">
                <div className="page-intro-content">
                  <h1 className="page-title">
                    <span>About Us</span>
                  </h1>
                  <p className="x-large">
                    Hertz has the experience and dependability your customers
                    look for overseas. We are the world’s leading vehicle
                    renting organization, operating from approximately 8,500
                    locations in 147 countries worldwide. We are the largest
                    general use car rental brand in the world, and the number
                    one airport car rental brand in the U.S. and at 69 major
                    airports in Europe, operating both corporate and licensee
                    locations in cities and airports in North America, Europe,
                    Latin America, Australia and New Zealand.
                  </p>
                  <p>
                    In addition, the Company has licensee locations in cities
                    and airports in Africa, Asia, and the Middle East. Product
                    and service initiatives such as Hertz Gold Plus Rewards,
                    NeverLost customized, onboard navigation systems, SIRIUS
                    Satellite Radio, and unique cars and SUV s offered through
                    the company’s Prestige, Fun and Green Collections, set Hertz
                    apart from the competition.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-sm-6">
                <figure>
                  <img src={aboutImg} className="img-fw" alt="" />
                </figure>
              </div>
              <div className="col-12 col-sm-6">
                <p>
                  Today Hertz's Worldwide Reservation Centres handle
                  approximately 40 million phone calls and deliver approximately
                  30 million reservations annually ensuring your customers will
                  receive low, competitive rates and quality service with every
                  rental, no matter where they travel.
                </p>

                <p>
                  Your customers won't be the only ones who benefit from
                  choosing Hertz as we want to show you how much we appreciate
                  your hard work. With excellent levels of commission available
                  and a great selection of leisure and non leisure products
                  available, Hertz is the right choice for your car rental
                  bookings.
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 text-center">
                <p className="large">
                  Please contact our dedicated travel trade team by emailing{" "}
                  <a href="mailto:traveltrade@hertz.ie">traveltrade@hertz.ie</a>{" "}
                  or by calling 053 91 52 500
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* static code section end */}
      </div>
    </>
  );
};

export default About;
