/* import * as React from "react"; */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getCountryList, getUserDetials, updateUser } from "../../external_api/User";
import { getCompanyDetail, getCompanyList, updateCompany } from "../../external_api/Company";
import { getRoleList } from "../../external_api/User";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { companyTypeList, paymentTypeList, vendorList, extrasList } from "../../constants/StaticData";
import { getLocationDetail, updateLocation } from "../../external_api/Location";


const EditLocation = () => {

    const { isFetching, isSuccess: isLogin } = useSelector(
        userSelector
    );

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }




    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required('Location is required'),
        licensee: Yup.string()
            .required('Licensee is required'),
        vendor: Yup.string()
            .required('Vendor is required'),
        address: Yup.string()
            .required('Address is required'),
        country: Yup.string()
            .required('Country is required'),
        city: Yup.string()
            .required('City is required'),
        state: Yup.string().nullable(),
        cap: Yup.string().nullable(),
        phone: Yup.string()
            .required('Phone is required'),
        description: Yup.string().nullable(),
        latitude: Yup.string()
            .required('Latitude is required'),
        longitude: Yup.string()
            .required('Longitude is required'),
        conversion_code: Yup.string().nullable(),
        operation_hours: Yup.string().nullable(),
        name: Yup.string()
            .required('Name is required'),
        extras: Yup.array(),
        status: Yup.string()
            .required('Status is required'),
    });


    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    type FormValues = {
        code: string,
        licensee: string,
        vendor: string,
        cap: string,
        address: string,
        city: string,
        country: string,
        state: string,
        phone: string,
        description: string,
        latitude: string,
        longitude: string,
        conversion_code: string,
        operation_hours: string,
        name: string,
        extras: Array<number>,
        status: string,
    }


    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


    const [loading, setLoading] = useState(false);

    const [locationDetail, setLocationDetail] = useState(
        {
            code: '',
            licensee: '',
            vendor: '',
            cap: '',
            address: '',
            city: '',
            country: '',
            state: '',
            phone: '',
            description: '',
            latitude: '',
            longitude: '',
            conversion_code: '',
            operation_hours: '',
            name: '',
            extras: [],
            status: '',
        }
    );
    const paramData = useParams();
    const [locationId, setLocationId] = useState(paramData.id);

    //const userId = paramData.

    const [isSubmitForm, setSubmitForm] = useState(false);

    const [loginResMsg, setLoginResMsg] = useState({
        status: false,
        msg: ""
    });

    const [inputUserName, setUserName] = useState("");


    // Login Form Submit Handler

    const onSubmitForm = async (formData: any) => {
        console.log("formData", formData, locationId);
        //return true;
        setLoading(true);
        var response = await updateLocation(formData, Number(locationId));
        const { data, status, errorMessage } = response;
        console.log(response);
        if (status == 201) {
            setLoading(false);
            toast.success("Location has been updated");
            setTimeout(() => {
                navigate("/location");  
            }, 500);
        } else {
            toast.error(errorMessage);
        }
        setLoading(false);

    };

    const [countryList, setCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [roleList, setRoleList] = useState([]);


    useEffect(() => {
        (async () => {
            setLoading(true);
            var responseCountry = await getCountryList();
            var { data, status } = responseCountry;
            //console.log("data",data[0])
            if (status === 200) {
                setCountryList(data);
            }

            var response = await getLocationDetail(locationId);
            console.log("profile response", response)
            var { data, status } = response;
            console.log("userdata", status, data)
            if (status === 201) {
                setTimeout(() => setLocationDetail(data), 1000);
                //setUserDetail(data);
            } else {
                //navigate("/location");
            }
            setLoading(false);

            //console.log("profile data",data.output);
        })()

    }, []);

    useEffect(() => {
        return reset(
            {
                code: locationDetail.code,
                licensee: locationDetail.licensee,
                vendor: locationDetail.vendor,
                cap: locationDetail.cap,
                address: locationDetail.address,
                city: locationDetail.city,
                country: locationDetail.country,
                state: locationDetail.state,
                phone: locationDetail.phone,
                description: locationDetail.description,
                latitude: locationDetail.latitude,
                longitude: locationDetail.longitude,
                conversion_code: locationDetail.conversion_code,
                operation_hours: locationDetail.operation_hours,
                name: locationDetail.name,
                extras: locationDetail.extras,
                status: locationDetail.status,
            }
        );
    }, [reset, locationDetail]);





    //console.log(post)

    return (
        <>
            <Toaster

                toastOptions={{
                    className: 'toaster-popup',
                }}
            />
            {loading ? (

                <Loader />
            ) :
                ""
            }
            <Titlestrip title={"Edit Location"} />
            <div className="form-wrapper container-fluid">
                <div className="row">
                    <div className="container">


                        <form
                            className="spacertopform"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="userEdit"
                            id="userEdit"
                            autoComplete="off"
                        >
                            {
                                loginResMsg.msg ?
                                    <div className="col-12">
                                        <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                                            {loginResMsg.msg}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        <label>Location Code<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.code ? 'is-invalid' : ''}`}
                                                {...register("code")}
                                                defaultValue={locationDetail.code}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.code?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        <label>Licensee<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("licensee")}
                                                defaultValue={locationDetail.licensee}
                                            >
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.licensee?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Status<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("status")}
                                                defaultValue={locationDetail.status}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        <label>Vendor<span className="red">*</span></label>
                                        <select
                                            {...register("vendor")}
                                            defaultValue={locationDetail.vendor}
                                        >
                                            {vendorList.length > 0 ?
                                                vendorList.map((vendor: any, index) => {
                                                    return <option value={vendor.code} key={index}>{vendor.name}</option>;
                                                })
                                                :
                                                ""


                                            }
                                        </select>
                                    </div>
                                    <span className="formFieldError error">{errors.code?.message}</span>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-4 col-12">
                                    <div className="custom-field">
                                        <label>Address<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("address")}
                                                defaultValue={locationDetail.address}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.address?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field">
                                        <label>CAP<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("cap")}
                                                defaultValue={locationDetail.cap}
                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.cap?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Name<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("name")}
                                                defaultValue={locationDetail.name}
                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.name?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>City<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("city")}
                                                defaultValue={locationDetail.city}
                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.city?.message}</span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-2 col-12">
                                    <div className="custom-field">
                                        <label>ST/PR<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("state")}
                                                defaultValue={locationDetail.state}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.state?.message}</span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="custom-field">
                                        <label>Country<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("country")}
                                                defaultValue={locationDetail.country ?? ""}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    countryList.map((country: any, index) => {

                                                        return <option value={country.code} key={index}>{country.name}</option>
                                                    })

                                                }


                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.country?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Phone<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("phone")}
                                                defaultValue={locationDetail.phone}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.phone?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Description</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("description")}
                                                defaultValue={locationDetail.description}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.description?.message}</span>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Latitude<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("latitude")}
                                                defaultValue={locationDetail.latitude}

                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.latitude?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Longitude<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("longitude")}
                                                defaultValue={locationDetail.longitude}

                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.longitude?.message}</span>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Conversion Code</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("conversion_code")}
                                                defaultValue={locationDetail.conversion_code}

                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.conversion_code?.message}</span>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Operation Hours</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("operation_hours")}
                                                defaultValue={locationDetail.operation_hours}

                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.operation_hours?.message}</span>
                                </div>

                            </div>

                            <div className="gray-bg">
                                <h2>Extras<span className="red">*</span></h2>
                                <div className="d-flex flex-wrap">
                                    {

                                        extrasList.map((extra: any, index) => {
                                            //var isChecked = (userDetail.profile.find(profile.id) !== undefined) ? true : false;
                                            return (
                                                <div className="form-check" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={extra.code}
                                                        id="extra"
                                                        //checked={isChecked}
                                                        //checked={true}
                                                        {...register(`extras`)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`extras`}
                                                    >
                                                        {extra.name}
                                                    </label>
                                                </div>
                                            )

                                        })
                                    }
                                </div>

                            </div>
                            <span className="formFieldError error">{errors.extras?.message}</span>

                            <div className="row mt-4">
                                <div className="col-12">
                                    <input type="submit" value="Save" className="btn-yellow" />
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>

        </>
    )



}


export default EditLocation;


