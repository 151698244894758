import React, {useEffect, useState} from "react";
import Loader from "../../components/loading/loader";
import "../../assets/scss/static.scss";
import "../../assets/scss/new-dashboard.scss";
import userImg from "../../assets/svg/user-img.svg";
import graph from "../../assets/images/graph.jpg";
import Listitem from "../../components/dashboard/Listitem";
import GraphData from "../../components/dashboard/Graph";
import Locationlist from "../../components/dashboard/Locationlist";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from '../../store/slices/user';
import { getUserDetials } from "../../external_api/User";


const DashboardNew = () => {
  const { isFetching, isSuccess: isLogin,id: userId, first_name: userFirstName, last_name: userLastName, name: userName, email: userEmail, phone: userPhone, company: userCompanyInfo, role: userRoleInfo } = useSelector(
    userSelector
  );

const navigate = useNavigate();
if(isLogin === false) {
    navigate("/");
}

  let listitemData = [
    {
      title: "Business",
      numberData: 100,
    },

    {
      title: "Liesure",
      numberData: 140,
    },

    {
      title: "Touroperator",
      numberData: 300,
    },

    {
      title: "Dollarthriftyadv",
      numberData: 0,
    },

    {
      title: "Dollarthriftytor",
      numberData: 0,
    },

    
  ];
  type IUserValues = {
    first_name: string,
    last_name: string,
    profile: Array<number>,
    company_id: string,
    role_id: string,
    phone: string,
    email: string,
    name: string,
    password: string,
    status: string,
    company: {
      name: string,
      code: string
    },
    roles: {
      slug:string
    }
}
  const [userDetail, setUserDetail] = useState<IUserValues>();
  useEffect(() => {
    (async () => {
    if(isLogin === false) {
      navigate("/");
    } else {
      var response = await getUserDetials(userId);
      var { data, status } = response;
      if (status === 201) {
          //setTimeout(() => setUserDetail(data), 1000);
          setUserDetail(data);
      } else {
          navigate("/user");
      }
    }
  
  })()

}, []);
  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid">
        {/* static code section start */}
        <div className="row">
          <div className="container">
            <main className="main-padding d-flex-container">
              {/* list component */}
              <Listitem listitemData={listitemData} />
              {/* list component end */}

              {/* graph code */}
              <GraphData />

              <section className="user-profile">
                <h2 className="sub-heading">User profile</h2>
                <div className="d-flex-container">
                  {/* <div className="user-icon">
                    <img src={userImg} alt="user-img" />
                  </div> */}
                  <div className="user-details">
                    <h3>{userDetail?.first_name ?? ""}{" "}{userDetail?.last_name ?? ""}</h3>
                    <div className="d-flex-container">
                      <div className="user-details-fields">
                        <p>
                          <span>Company</span>
                          {userDetail?.company?.name ?? ""}
                        </p>
                      </div>
                      {/* <div className="user-details-fields">
                        <p>
                          <span>Client code</span> {userDetail?.company?.code ?? ""}
                        </p>
                      </div>
                      <div className="user-details-fields">
                        <p>
                          <span>Level</span> {userDetail?.roles?.slug ?? ""}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
              {/* location list */}
              <Locationlist />
            </main>
            {/* static code section end */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardNew;
