import * as React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";


const FooterList =  ({ footerLink } : { footerLink: any}) => {
  const footerdata = footerLink;
  return (
    <>
      {footerdata.map((data:any) => (
        <div className="col-lg-3 col-sm-4 col-xs-12" key={uuidv4()}>
          <h5 className="heading-container">{data.heading}</h5>
          <ul>
            {data.list.map((listData:any) => {
              return (
                <li key={uuidv4()}>
                  <Link to={listData.link}>{listData.text}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </>
  );
};

export default FooterList;
