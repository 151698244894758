//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getLocationDetail = async (locationId:any)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.get(`/locations/${locationId}`);
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}

const getLocationList = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/locations/all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const getLocations = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string) => {
  let data, status, errorMessage;
  //const cancelToken = axiosInstance
  try {
    let requestUrl = "/locations?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=code"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=licensee"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=vendor"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[3]=address"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[4]=city"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[5]=state"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[6]=phone"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[7]=status"+ ',' + searchTerm;

    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const addLocation = async (LocationData: any) => {
  console.log(LocationData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/locations`, LocationData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateLocation = async (LocationData: any, LocationId:number) => {
  console.log(LocationData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.put(`/locations/${LocationId}`, LocationData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const uploadLocation = async (formData: any) => {
  
  let data, status, errorMessage;

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  try {
    const response = await axiosInstance.post(`/locations/upload`, formData, {
      headers: headers
    });
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    console.log("error api", error)
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const getLocationsSearchByCodeVendor = async (code:string, vendor:string) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/locations?code="+code+"&vendor="+vendor;
    
    const response = await axiosInstance.get(requestUrl);
    
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const searchLocations = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string) => {
  let data, status, errorMessage;
  //const cancelToken = axiosInstance
  try {
    let requestUrl = "/locations/search?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=code"+ ',' + searchTerm;
      //requestUrl = requestUrl + "&like_or[1]=licensee"+ ',' + searchTerm;
      //requestUrl = requestUrl + "&like_or[2]=vendor"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[3]=name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[4]=address"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[5]=city"+ ',' + searchTerm;
      //requestUrl = requestUrl + "&like_or[6]=state"+ ',' + searchTerm;
     // requestUrl = requestUrl + "&like_or[7]=phone"+ ',' + searchTerm;
      //requestUrl = requestUrl + "&like_or[8]=status"+ ',' + searchTerm;

    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

type locationData = {
      code: string,
      vendor?: string,
      country?: string,
};

const getLocationCodeWiseDetail = async (querydata:locationData)=>{
  const queryString = '?' + new URLSearchParams(querydata).toString();
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/locations/detail${queryString}`);
   data = response.data.data;
   status = response.status;
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   console.log(errorMessage);
 }
 return {data, status, errorMessage}
}

const getAllLocations = async (query?:string)=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/locations/get-all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const uploadLocationStatusApi = async (formData: any) => {
  
  let data, status, errorMessage;

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  try {
    const response = await axiosInstance.post(`/locations/upload-status`, formData, {
      headers: headers
    });
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    console.log("error api", error)
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

export {
  getLocationDetail, 
  getLocationList, 
  getLocations, 
  addLocation, 
  updateLocation, 
  uploadLocation, 
  getLocationsSearchByCodeVendor, 
  searchLocations,
  getLocationCodeWiseDetail,
  getAllLocations,
  uploadLocationStatusApi
}                                                                                                             