//@ts-check
import axiosInstance from '../../lib/axios/index'
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';

type regiterUser = {
    name:string,
    email:string,
    phone:number
    password:any
}

type loginUser = {
  email:string,
  password:any
}
 


const checkMember = async (userData: {username:string|number}) => {
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/check-member`,userData);
    console.log(response.data);
    data = response.data;
    status = response.data.success;
    if(status == 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}


const register = async (userData: regiterUser) => {
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/register`,userData);
    console.log(response.data);
    data = response.data.output;
    //const status = {'status': response.data.success, 'message': response.data.message}
    status = response.data.success;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const login = async (loginData: loginUser) => {
  console.log(loginData);
  let data, status, errorMessage = '';

  try {
    const response = await axiosInstance.post(`/login`,loginData);
    data = response.data.data;
    status = response.status;
    if(status !== 200) {
      errorMessage = response.data.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const logout = async () => {
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/logout`);
    data = response.data.output.data;
    //const status = {'status': response.data.success, 'message': response.data.message}
    status = response.data.success;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}


type changePasswordData = {
  old_password:number | string,
  password:number | string
}
const changePassword = async (postData:changePasswordData) => {
  let data, status, errorMessage;
  try {
    const response = await axiosInstance.post(`/change-password`, postData);
    data = response.data.data;
    //const status = {'status': response.data.success, 'message': response.data.message}
    status = response.status;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}


const getStateList = async () => {
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.get(`/state`);
    //console.log("cart List:",response.data);
    data = response.data.output;    
    status = response.data.success;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const getUserDetials = async (userId:any) => {
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.get(`/users/${userId}`);
    //console.log(response.data);
    data = response.data.data;
    //status = response.data.success;
    status = response.status;
    if(status !== 201) {
      //errorMessage = response.data.message
      errorMessage = response.data.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

type profileData = {
      email?:string,
      phone?:number,
      name?:string,
      password?:any,
}

const updateProfile = async (updateProfileData: profileData) => {
  //console.log(postData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/profile-update`,updateProfileData);
    data = response.data;
    status = response.data.success;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const addUser = async (userData: any) => {
  console.log(userData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/users`, userData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateUser = async (updateUserData: any, userId:number) => {
  console.log(updateUserData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/users/${userId}`,updateUserData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}


const getUserAllData = async ()=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/users/get-all`);
    data = response.data.data;
    status = response.status;
   if(status !== 201) {
      errorMessage = response.data.message
    }
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   //console.log(errorMessage);
 }
 return { data, status, errorMessage }
}

const getUserList = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string,dateRange?:{startDate:string, endDate:string}) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/users?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=email"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=first_name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[3]=status"+ ',' + searchTerm;
    }

    if(dateRange) {
      if(dateRange.startDate !== "") {
        requestUrl = requestUrl + "&greater_or_equal=created_at"+ ',' + dateRange.startDate;
        requestUrl = requestUrl + "&less_or_equal=created_at"+ ',' + dateRange.endDate;
      }
      
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const forgotPassword = async (userData: any) => {
  let data, status, errorMessage;
  try {
    const response = await axiosInstance.post(`/forgotpassword`, userData);
    data = response.data.data;
    status = response.status;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}
const verifyToken = async (userData: any) => {
  console.log(userData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/verifytoken`, userData);
    data = response.data.data;
    status = response.status;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}
const resetPassword = async (userData: any) => {
  console.log(userData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/resetpassword`, userData);
    data = response.data.data;
    status = response.status;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const getRoleList = async () => {
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.get(`/roles`);
    //console.log("cart List:",response.data);
    data = response.data.data;    
    status = response.status;
    if(status !== 200) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const getCountryList = async ()=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/countries/all`);
   data = response.data;
   status = response.status;
   if(status !== 200) {
      errorMessage = response.data.message
    }
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   //console.log(errorMessage);
 }
 return { data, status, errorMessage }
}



export { register, login, changePassword, checkMember, getUserDetials , updateProfile, getStateList, logout, 
  getUserList, getRoleList, updateUser, addUser, forgotPassword, verifyToken, resetPassword, getCountryList, getUserAllData}