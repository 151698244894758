import * as React from "react";
import {Link} from 'react-router-dom';
import configRoutes from '../../config/Routes';
import "../../assets/scss/footer.scss";
import {footerLink} from "../../data";
import FooterList from "./FooterList";


const Index = () => {
  return (
    <>
      <footer className="footer-container">
        <div className="container-fluid">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-4 col-xs-12 d-none d-sm-flex flex-sm-column">
                  <Link to={configRoutes.home} className="footer-logo">
                    <img src="/logo.png" alt="logo" />
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-4 col-xs-12">
                  <h5 className="heading-container">Customer Support</h5>
                  <ul>
                    <li>
                      <a href="tel:+353539152556">+353 53 91 52 556</a>
                    </li>

                    <li>
                      <a href="mailto:traveltrade@hertz.ie">
                        traveltrade@hertz.ie
                      </a>
                    </li>
                  </ul>
                </div>

                <FooterList footerLink={footerLink} />

                <div className="col-12">
                  <p className="copyright-container text-center">
                    THE HERTZ CORPORATION Ryan's Investments - Hertz
                    International GSA Ferrybank - Wexford, Ireland
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Index;
