/* import * as React from "react"; */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getCountryList } from "../../external_api/User";
import { getCompanyList } from "../../external_api/Company";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { companyTypeList, paymentTypeList, vendorList, privilegeList, paymentTypeRateList, additionalInclusionList } from "../../constants/StaticData";
import { updateLocation } from "../../external_api/Location";
import { getRateDetail, updateRate } from "../../external_api/Rate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react-ts";
//import Select2 from "react-select2-wrapper";
import Multiselect from 'multiselect-react-dropdown';


const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    uploader: {
      insertImageAsBase64URI: true
    },
    height: 200 
}


const EditRate = () => {

    const { isFetching, isSuccess: isLogin } = useSelector(
        userSelector
    );

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }




    const validationSchema = Yup.object().shape({
        pcc: Yup.string()
            .required('PCC is required'),
        order: Yup.string()
            .required('Order is required'),
        vendor: Yup.string()
            .required('Vendor is required'),
        privilege: Yup.string()
            .required('Privilege is required'),
        country: Yup.string()
            .required('Country is required'),
        payment: Yup.string()
            .required('Payment is required'),
        e_voucher: Yup.string().nullable(),
        cap: Yup.string().nullable(),
        company_id: Yup.string()
            .required('Company is required'),
        fare_name: Yup.string()
            .required('Fare name is required'),
        business: Yup.string()
            .required('Business is required'),
        iata_taco: Yup.string().nullable(),
        cdp: Yup.string().nullable(),
        rc: Yup.string().nullable(),
        it_code: Yup.string().nullable(),
        booking_date_from: Yup.string().nullable(),
        booking_date_to: Yup.string().nullable(),
        rental_date_from: Yup.string().nullable(),
        rental_date_to: Yup.string().nullable(),
        content: Yup.string().nullable(),
        notes_content: Yup.string().nullable(),
        terms_content: Yup.string().nullable(),
        special_content: Yup.string().nullable(),
        status: Yup.string()
            .required('Status is required'),
        additional_inclusions: Yup.array().nullable(),
        auto_proforma_invoice: Yup.string(),
        proforma_companies: Yup.array().nullable(),
        margin: Yup.string().min(0).nullable(),
                
    });


    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    type FormValues = {
        pcc: string,
        vendor: string,
        payment: string,
        company_id: string,
        privilege: string,
        country: string,
        e_voucher: string,
        fare_name: string,
        business: string,
        iata_taco: string,
        cdp: string,
        rc: string,
        it_code: string,
        booking_date_from: string|null|any,
        booking_date_to: string|null|any,
        rental_date_from: string|null|any,
        rental_date_to: string|null|any,
        content: string,
        notes_content: string,
        terms_content: string,
        special_content: string,
        order: string,
        status: string,
        billing_number: string,
        additional_inclusions: Array<string>,
        auto_proforma_invoice: string,
        proforma_companies: Array<number|string>,
        margin: string,
    }   


    const { register, handleSubmit, formState: { errors }, reset, control } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


    const [loading, setLoading] = useState(false);

    const [rateDetail, setRateDetail] = useState(
        {
            pcc: "",
            vendor: "",
            payment: "ALL",
            company_id: "",
            privilege: "",
            country: "",
            e_voucher: "",
            fare_name: "",
            business: "0",
            iata_taco: "",
            cdp: "",
            rc: "",
            it_code: "",
            booking_date_from: null,
            booking_date_to: null,
            rental_date_from: null,
            rental_date_to: null,
            content: "",
            notes_content: "",
            terms_content: "",
            special_content: "",
            order: "0",
            status: "",
            billing_number: "",
            additional_inclusions: [],
            auto_proforma_invoice: "0",
            proforma_companies: [],
            margin: "",
        }
    );
    const paramData = useParams();
    const [rateId, setRateId] = useState(paramData.id);

    //const userId = paramData.

    const [isSubmitForm, setSubmitForm] = useState(false);

    const [loginResMsg, setLoginResMsg] = useState({
        status: false,
        msg: ""
    });

    const [inputUserName, setUserName] = useState("");


    // Login Form Submit Handler

    const onSubmitForm = async (formData: any) => {
        formData['content'] = content;
        formData['notes_content'] = notesContent;
        formData['terms_content'] = termsContent;
        formData['special_content'] = specialContent;
        formData['proforma_companies'] = selProformaCat;
        
        //console.log("formData", formData, rateId);
            if(formData['booking_date_from']) {
                formData['booking_date_from'] = moment(new Date(Number(formData['booking_date_from']))).format("YYYY-MM-DD");
             }
             if(formData['booking_date_to']) {
                formData['booking_date_to'] = moment(new Date(Number(formData['booking_date_to']))).format("YYYY-MM-DD");
             }
             if(formData['rental_date_from']) {
                formData['rental_date_from'] = moment(new Date(Number(formData['rental_date_from']))).format("YYYY-MM-DD");
             }
             if(formData['rental_date_to']) {
                formData['rental_date_to'] = moment(new Date(Number(formData['rental_date_to']))).format("YYYY-MM-DD");
             }
        setLoading(true);
        var response = await updateRate(formData, Number(rateId));
        const { data, status, errorMessage } = response;
        
        if (status == 201) {
            setLoading(false);
            toast.success("Rate has been updated");
            setTimeout(() => {
                navigate("/rate");  
            }, 500);
            //navigate("/rate");
        } else {
            toast.error(errorMessage);
        }
        setLoading(false);

    };

    const [countryList, setCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    interface Icompany {
            id: number,
            name: string
    }
    const [selCompany, setSelCompany] = useState<Icompany[]>([]);

    const [selProformaCat, setSelProformaCat] = useState<number[]>([]);

    //const [bookingFromDate, setBookingFromDate] = useState(new Date());
    const [bookingFromDate, setBookingFromDate] = useState(null);
    const [bookingToDate, setBookingToDate] = useState(null);
    const [rentalFromDate, setRentalFromDate] = useState(null);
    const [rentalToDate, setRentalToDate] = useState(null);

    const [content, setContent] = useState("");
    const [notesContent, setNotesContent] = useState("");
    const [termsContent, setTermsContent] = useState("");
    const [specialContent, setSpecialContent] = useState("");

    const setSelectedProformaCmp = () => {
        //var selCat = [6,44,3,111];
        var selList:Icompany[] = [];
        
        //if(rateDetail.proforma_companies) {
        console.log('rateDetail.proforma_companies',rateDetail.proforma_companies);
        if(rateDetail.proforma_companies){
            console.log('rateDetail.proforma_companies',rateDetail.proforma_companies);
            companyList.find((ele) => {
                var id = ''+ele['id'];
                if(id && rateDetail.proforma_companies[0] !== "") {
                    console.log('id',typeof id);
                    rateDetail.proforma_companies.map((cmpId) =>
                    {
                        if(cmpId == id){
                            console.log(rateDetail.proforma_companies);
                            selList.push(ele);
                        }    
                     
                            
                    })
                    
                }
                
            });    
        }
        
        setSelCompany(selList);
        //console.log(selList);
    } 


    useEffect(() => {
        (async () => {
            setLoading(true);
            var responseCountry = await getCountryList();
            var { data, status } = responseCountry;
            if (status === 200) {
                setCountryList(data);
            }

            var responseCompany = await getCompanyList();
            var { data, status } = responseCompany;
            if (status === 200) {
                setCompanyList(data);
            }

            var response = await getRateDetail(rateId);
            var { data, status } = response;
            
            if (status === 201) {
                setTimeout(() => setRateDetail(data), 500);
                //setUserDetail(data);
            } else {
                //navigate("/rate");
            }
            setSelectedProformaCmp();
            setLoading(false);

            //console.log("profile data",data.output);
        })()

    }, []);

    useEffect(() => {
        setBookingFromDate(rateDetail.booking_date_from);
        setBookingToDate(rateDetail.booking_date_to);
        setRentalFromDate(rateDetail.rental_date_from);
        setRentalToDate(rateDetail.rental_date_to);
        //rateDetail.proforma_companies = [44,11];
        //setSelProformaCat(rateDetail.proforma_companies);
        //console.log(rateDetail.proforma_companies);
        setSelectedProformaCmp();
        return reset(
            {
                pcc: rateDetail.pcc,
                vendor: rateDetail.vendor,
                payment: rateDetail.payment,
                company_id: rateDetail.company_id ?? 0,
                privilege: rateDetail.privilege,
                country: rateDetail.country ?? "All",
                e_voucher: rateDetail.e_voucher,
                fare_name: rateDetail.fare_name,
                business: rateDetail.business ?? 0,
                iata_taco: rateDetail.iata_taco,
                cdp: rateDetail.cdp,
                rc: rateDetail.rc,
                it_code: rateDetail.it_code,
                //booking_date_from: (rateDetail.booking_date_from ? moment(new Date(rateDetail.booking_date_from)).unix()  : ""),
                booking_date_from: (rateDetail.booking_date_from ? new Date(rateDetail.booking_date_from).getTime()  : ""),
                booking_date_to: (rateDetail.booking_date_to ? new Date(rateDetail.booking_date_to).getTime()  : ""),
                rental_date_from: (rateDetail.rental_date_from ? new Date(rateDetail.rental_date_from).getTime()  : ""),
                rental_date_to: (rateDetail.rental_date_to ? new Date(rateDetail.rental_date_to).getTime()  : ""),
                content: rateDetail.content,
                notes_content: rateDetail.notes_content,
                terms_content: rateDetail.terms_content,
                special_content: rateDetail.special_content,
                status: rateDetail.status,
                order: rateDetail.order,
                billing_number: rateDetail.billing_number,
                additional_inclusions: rateDetail.additional_inclusions,
                auto_proforma_invoice: rateDetail.auto_proforma_invoice ?? 0,
                proforma_companies: rateDetail.proforma_companies ?? [],
                margin: rateDetail.margin,
                
                
            }
        );
    }, [reset, rateDetail]);

    const onSelect = (selectedList:any, selectedItem:any) => {
            console.log(selectedList, selectedItem);
            var catIds : number[] = [];
            if(selectedList.length){
                selectedList.map((obj:any) => catIds.push(obj.id));
            }   
            setSelProformaCat(catIds);
            console.log(catIds);
    }
    const onRemove = (selectedList:any, removedItem:any) => {
            console.log(selectedList, removedItem);   
            var catIds : number[] = [];
            if(selectedList.length){
                selectedList.map((obj:any) => catIds.push(obj.id));
            }
            setSelProformaCat(catIds);
            console.log(catIds);
    }



    return (
        <>
            <Toaster

                toastOptions={{
                    className: 'toaster-popup',
                }}
            />
            {loading ? (

                <Loader />
            ) :
                ""
            }
            <Titlestrip title={"Edit Rate"} />
            
            <div className="form-wrapper container-fluid">
                <div className="row">
                    <div className="container">
                        <form
                            className="spacertopform"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="userEdit"
                            id="userEdit"
                            autoComplete="off"
                        >
                            {
                                loginResMsg.msg ?
                                    <div className="col-12">
                                        <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                                            {loginResMsg.msg}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row">
                                <div className="col-md-1 col-12">
                                    <div className="custom-field ">
                                        <label>PCC<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.pcc ? 'is-invalid' : ''}`}
                                                {...register("pcc")}
                                                defaultValue={rateDetail.pcc}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.pcc?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field ">
                                        <label>Vendor<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("vendor")}
                                                defaultValue={rateDetail.vendor}
                                            >
                                                {vendorList.length > 0 ?
                                                    vendorList.map((vendor: any, index) => {
                                                        return <option value={vendor.code} key={index}>{vendor.name}</option>;
                                                    })
                                                    :
                                                    ""


                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.vendor?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field">
                                        <label>Status<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("status")}
                                                defaultValue={rateDetail.status}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field ">
                                        <label>Order<span className="red">*</span></label>
                                        <select
                                            {...register("order")}
                                            defaultValue={rateDetail.order}
                                        >
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    <span className="formFieldError error">{errors.order?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field ">
                                        <label>Privilege<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("privilege")}
                                                defaultValue={rateDetail.privilege}
                                            >
                                                {privilegeList.length > 0 ?
                                                    privilegeList.map((privilege: any, index) => {
                                                        return <option value={privilege.code} key={index}>{privilege.name}</option>;
                                                    })
                                                    :
                                                    ""


                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.privilege?.message}</span>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field ">
                                        <label>Margin(%)</label>
                                        <div className="text">
                                            <input
                                                type="number"
                                                className={`input-field`}
                                                {...register("margin")}
                                                defaultValue={rateDetail.margin}
                                                min="0"
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.margin?.message}</span>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Country<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("country")}
                                                defaultValue={rateDetail.country ?? ""}
                                            >
                                                <option value={"All"} key={999}>All</option>
                                                {
                                                    countryList.map((country: any, index) => {

                                                        return <option value={country.code} key={index}>{country.name}</option>
                                                    })

                                                }


                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.country?.message}</span>
                                </div>
                            </div>

                            <div className="row hide">

                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Payment<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("payment")}
                                                defaultValue={rateDetail.payment}
                                            >
                                                {paymentTypeRateList.length > 0 ?
                                                    paymentTypeRateList.map((paymentTypeRate: any, index) => {
                                                        return <option value={paymentTypeRate.code} key={index}>{paymentTypeRate.name}</option>;
                                                    })
                                                    :
                                                    ""


                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.payment?.message}</span>
                                </div>
                                <div className="col-md-3 col-12 ">
                                    <div className="custom-field">
                                        <label>E-Voucher</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("e_voucher")}
                                                defaultValue={rateDetail.e_voucher}
                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.e_voucher?.message}</span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Company<span className="red">*</span></label>
                                        <div className="text">
                                            <select
                                                {...register("company_id")}
                                                defaultValue={rateDetail.company_id}
                                            >
                                                <option value={0} key={9999}>All</option>;
                                                {companyList.length > 0 ?
                                                    companyList.map((company: any, index) => {
                                                        return <option value={company.id} key={index}>{company.name}</option>;
                                                    })
                                                    :
                                                    ""
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.company_id?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field">
                                        <label>Name Fare<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("fare_name")}
                                                defaultValue={rateDetail.fare_name}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.fare_name?.message}</span>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field">
                                        <label>Business<span className="red">*</span></label>
                                        <div className="select">
                                        <select
                                            {...register("business")}
                                            defaultValue={rateDetail.business}
                                        >
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                        </div>
                                       

                                    </div>
                                    <span className="formFieldError error">{errors.business?.message}</span>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field">
                                        <label>IATA / TACO</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("iata_taco")}
                                                defaultValue={rateDetail.iata_taco}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.iata_taco?.message}</span>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field">
                                        <label>CDP</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("cdp")}
                                                defaultValue={rateDetail.cdp}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.cdp?.message}</span>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field">
                                        <label>RC</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("rc")}
                                                defaultValue={rateDetail.rc}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.rc?.message}</span>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field">
                                        <label>IT Code</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("it_code")}
                                                defaultValue={rateDetail.it_code}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.it_code?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field">
                                        <label>Billing Number</label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                {...register("billing_number")}
                                                defaultValue={rateDetail.billing_number}

                                            />
                                        </div>

                                    </div>
                                    <span className="formFieldError error">{errors.billing_number?.message}</span>
                                </div>                
                            </div>

                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Booking Date From</label>
                                        <div className="text">


                                        <Controller
                                            render={(ref) => (
                                            <DatePicker
                                                selected={
                                                  ref.field.value ? new Date(Number(ref.field.value)) : undefined
                                                }
                                                //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                dateFormat={"yyyy-MM-dd"}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                            )}
                                            name={"booking_date_from"}
                                            control={control}
                                            rules={{ required: true }}
                                        />
                                        
                                        </div>
                                    </div>
                                    {/* <span className="formFieldError error">{errors.booking_date_from?.message}</span> */}
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Booking Date To</label>
                                        <div className="text">
                                        <Controller
                                            render={(ref) => (
                                            <DatePicker
                                                selected={
                                                  ref.field.value ? new Date(Number(ref.field.value)) : undefined
                                                }
                                                //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                dateFormat={"yyyy-MM-dd"}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                            )}
                                            name={"booking_date_to"}
                                            control={control}
                                            rules={{ required: true }}
                                        />

                                        </div>
                                    </div>
                                    {/* <span className="formFieldError error">{errors.booking_date_to?.message}</span> */}
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Rental Date From</label>
                                        <div className="text">
                                        <Controller
                                            render={(ref) => (
                                            <DatePicker
                                                selected={
                                                  ref.field.value ? new Date(Number(ref.field.value)) : undefined
                                                }
                                                //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                dateFormat={"yyyy-MM-dd"}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                            )}
                                            name={"rental_date_from"}
                                            control={control}
                                            rules={{ required: true }}
                                        />
                                        
                                        </div>
                                    </div>
                                    {/* <span className="formFieldError error">{errors.rental_date_from?.message}</span> */}
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Rental Date To</label>
                                        <div className="text">
                                        <Controller
                                            render={(ref) => (
                                            <DatePicker
                                                selected={
                                                  ref.field.value ? new Date(Number(ref.field.value)) : undefined
                                                }
                                                //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                                dateFormat={"yyyy-MM-dd"}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                            )}
                                            name={"rental_date_to"}
                                            control={control}
                                            rules={{ required: true }}
                                        />

                                        </div>
                                    </div>
                                    {/* <span className="formFieldError error">{errors.rental_date_to?.message}</span> */}
                                </div>

                            </div>
                            <div className="gray-bg">
                                <h2>Additional Incusions</h2>
                                <div className="d-flex flex-wrap col-12">
                                    {

                                        additionalInclusionList.map((additionalInclusion: any, index) => {
                                            return (
                                                <div className="form-check" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        /* value={paymentType.id} */
                                                        value={additionalInclusion.name}
                                                        id="localCharge"
                                                        //checked={isChecked}
                                                        //checked={true}
                                                        {...register(`additional_inclusions`)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`additional_inclusions`}
                                                    >
                                                        {additionalInclusion.name}
                                                    </label>
                                                </div>
                                            )

                                        })
                                    }
                                    
                                </div>
                                <span className="formFieldError error">{errors.additional_inclusions?.message}</span>
                                
                            </div>
                            <div className="row">
                                <div className="col-md-2 col-4">
                                    <div className="custom-field">
                                        <label>Auto Proforma Invoice Enable</label>
                                        <div className="select">
                                        <select
                                            {...register("auto_proforma_invoice")}
                                            defaultValue={rateDetail.auto_proforma_invoice}
                                        >
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                        </div>
                                       

                                    </div>
                                    <span className="formFieldError error">{errors.auto_proforma_invoice?.message}</span>
                                </div>
                                {/*<div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Send Proforma Companies<span className="red">*</span></label>
                                        <div className="text">
                                            <select
                                                 multiple
                                                {...register("proforma_companies")}
                                                defaultValue={rateDetail.proforma_companies}
                                            >
                                                <option value={""} key={9999}></option>;
                                                {companyList.length > 0 ?
                                                    companyList.map((company: any, index) => {
                                                        return <option value={company.id} key={index}>{company.name}</option>;
                                                    })
                                                    :
                                                    ""
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.proforma_companies?.message}</span>
                                </div>*/}
                                <div className="col-md-4 col-12">
                                    <div className="custom-field">
                                        <label>Send Proforma to Companies<span className="red">*</span></label>
                                        <div className="text">
                                
                                        <Multiselect
                                            options={companyList} // Options to display in the dropdown
                                            selectedValues={selCompany} // Preselected value to persist in dropdown
                                            onSelect={onSelect} // Function will trigger on select event
                                            onRemove={onRemove} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        </div>
                                    </div>
                                </div>

                            </div>    

                            
                            <div className="row">
                            <div className="col-md-12 col-12">   
                            <div className="custom-field border-none">
                                <label className="border-none">Content</label>
                            </div>
                            <JoditEditor
                                    defaultValue={rateDetail.content}
                                    config={editorConfig}
                                    //tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent:string) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    
                                    />
                            
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-12 col-12">                    
                            <div className="custom-field border-none">
                                <label className="border-none">Notes</label>
                            </div>
                            <JoditEditor
                                    defaultValue={rateDetail.notes_content}
                                    config={editorConfig}
                                    //tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent:string) => setNotesContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    
                                    />

                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-12 col-12">      
                            <div className="custom-field border-none">
                                <label className="border-none">Terms</label>
                            </div>              
                            <JoditEditor
                                    defaultValue={rateDetail.terms_content}
                                    config={editorConfig}
                                    //tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent:string) => setTermsContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    
                                    />

                            </div>
                            </div>
                            <div className="row">
                            <div className="col-md-12 col-12">      
                            <div className="custom-field border-none">
                                <label className="border-none">Special</label>
                            </div>              
                            <JoditEditor
                                    defaultValue={rateDetail.special_content}
                                    config={editorConfig}
                                    //tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent:string) => setSpecialContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    
                                    />

                            </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <input type="submit" value="Save" className="btn-yellow" />
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>

        </>
    )



}


export default EditRate;


