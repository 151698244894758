/* import * as React from "react"; */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { uploadLocation, getAllLocations, uploadLocationStatusApi } from "../../external_api/Location";

import axios from 'axios';

import { ExportCSV } from "../../components/Excel/ExportCSV"

const UploadLocationStatus = () => {

    const { isFetching, isSuccess: isLogin } = useSelector(
        userSelector
    );

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }

    

    const FILE_SIZE = 5000000;
    const SUPPORTED_FORMATS = ['text/csv'];
    
    const validationSchema = Yup.object().shape({
        file: Yup.mixed()
            .required('file is required')
            /* .test("type", "Only the following formats are accepted: .csv", (value) => {

                return value && (
                    value[0]?.type === "text/csv" 
                );
            }) */
            .test("fileSize", "The file is too large.", (value) => {
                console.log(value[0]);
                return value && value[0]?.size <= FILE_SIZE;
            })
            
        
    });


    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    type FormValues = {
        file: string,
    }


    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


    const [loading, setLoading] = useState(false);

    
    //const userId = paramData.


    const [loginResMsg, setLoginResMsg] = useState({
        status: false,
        msg: ""
    });

    const [selectedFile, setSelectedFile] = useState<any>('');
    // Login Form Submit Handler

    const onSubmitForm = async (formData: any) => {
        
        const fdata = new FormData();
        //@ts-ignore
        fdata.append('file', selectedFile);
        setLoading(true);
        var response = await uploadLocationStatusApi(fdata);
        const { data, status, errorMessage } = response;
        
        console.log(response);
        if (status == 201) {
            toast.success("Location has been updated");
            setTimeout(() => {
            setLoading(false);
                navigate("/location");  
            }, 500);
        } else {
            toast.error(errorMessage);
        }
        
        setLoading(false);

    };

    const getAllLocationsNew = async (e: { preventDefault: () => void; }) => {
        setDownloadRead(false);
        e.preventDefault()
        setLoading(true);
        //console.log("searchTerm getUserDataHandler:",searchTerm);
        const response = await getAllLocations();
        console.log(response.data);

        //let headers = ["Creation,Reservation No,Departure,Return,Country,Pick-Up,Drop-Off,Num Days,Travellers,Currency,Total Amount,Net Total Amount,SubAgent Commission,Payment Type,Privilege,Status,Cancellation Date,Company,Username,Booking number,Remarks"]
        
        let dataCsv = response.data.reduce((acc:any, location:any) => {
            //reservation.status = (reservation.status == "0") ? "Cancelled" : "Reserved"  
        console.log("location",location)
          const { id, status, code, city, country, vendor } = location
          
          
          const newData = {"id": id,"Name": code,"City": city,"Country": country,"Vendor": vendor,"Status": status};
          acc.push(newData)
          return acc
        }, []) 

       console.log("dataCsv",dataCsv);

        
        
        setDownloadData(dataCsv);
        setDownloadRead(true);
        setLoading(false);
    }

    const [downloadReady, setDownloadRead] = useState(false);
    const [downloadData, setDownloadData] = useState([]);

    useEffect(() => {
        if(downloadReady) {
            //@ts-ignore
            document.getElementById("download_xlsx").click();
            //exportToCsv();
        }
        
    }, [downloadData, downloadReady]);

    //console.log(post)

    return (
        <>
            <Toaster

                toastOptions={{
                    className: 'toaster-popup',
                }}
            />
            {loading ? (

                <Loader />
            ) :
                ""
            }
            <Titlestrip title={"Update Location Status"} />
            <div className="form-wrapper container-fluid">
                <div className="row">
                    <div className="container">
                        <div className="col-12 col-sm-6 d-flex flex-wrap justify-content-sm-end right-container order-sm-1">
                            <button className="btn btn-export" onClick={getAllLocationsNew}>Export</button>
                            <div style={{display:"none"}}>
                                <ExportCSV csvData={downloadData} fileName={"locations-status"} />
                             </div>
                        </div>

                        <form
                            className="spacertopform"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="userEdit"
                            id="userEdit"
                            autoComplete="off"
                            encType="multipart/form-date"
                        >
                            {
                                loginResMsg.msg ?
                                    <div className="col-12">
                                        <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                                            {loginResMsg.msg}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        {/* <label>Location Code<span className="red">*</span></label> */}
                                        <div className="text">
                                            <input
                                                style={{"height":"35px"}}
                                                type="file"
                                                className={`input-field ${errors.file ? 'is-invalid' : ''}`}
                                                {...register("file")}
                                                onChange={(e) =>
                                                    {
                                                    //@ts-ignore
                                                    setSelectedFile(e.target.files[0])
                                                        
                                                    }}
                                                
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.file?.message}</span>
                                </div>
                                
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <input type="submit" value="Save" className="btn-yellow" />
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </>
    )



}


export default UploadLocationStatus;


