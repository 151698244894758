//import * as React from "react";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Titlestrip from "../../utility/Titlestrip";
import editIcon from "../../assets/svg/edit.svg";
import "../../assets/scss/company-list.scss";
import "../../assets/scss/payment-type.scss";
import "../../assets/scss/search.scss";
import Loader from "../../components/loading/loader";
import { v4 as uuidv4 } from "uuid";
import Pagination from "react-js-pagination";
import configRoutes from "../../config/Routes";

import { getCompanies, getCompanyAllData } from "../../external_api/Company";

import { debounce } from "lodash"
import { useSelector } from "react-redux";
import { userSelector } from "../../store/slices/user";
import { downloadFile } from "../../utility/Helpers";
import DateRangePickerComp from "../../components/common/DateRangePickerComp";



const Company = () => {

    const { isFetching, isSuccess: isLogin, isError, errorMessage, first_name: userFirstName, last_name: userLastName, name: userName, email: userEmail, phone: userPhone, company: userCompanyInfo, role: userRoleInfo } = useSelector(
        userSelector
    );

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }

    const [loading, setLoading] = useState(false);
    const [pageEntries, setpageEntries] = useState(5);

    //const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [current_page, setCurrentPage] = useState(1);
    const [per_page, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);

    // Filter Inputs
    const [sort, setSort] = useState("email");
    const [order, setOrder] = useState("asc");
    const [searchTerm, setSearchTerm] = useState("");

    const columns = [
        { label: "Name", accessor: "name", sortable: true },
        { label: "Parent", accessor: "company", sortable: false },
        { label: "Address", accessor: "address", sortable: true },
        { label: "City", accessor: "city", sortable: true },
        { label: "Email", accessor: "email", sortable: true },
        { label: "Code", accessor: "code", sortable: true },
        { label: "Status", accessor: "status", sortable: true },
    ];


    const getCompanyDataHandler = async (pageNumber: number = 1, pageSize?: number, search?: string) => {
        setLoading(true);
        console.log("pageEntries", pageEntries)
        if (!pageSize) {
            pageSize = pageEntries;
        }
        if (!search) {
            search = searchTerm;
        }
        //console.log("searchTerm getUserDataHandler:",searchTerm);
        const response = await getCompanies(pageNumber, pageSize, sort, order, search, selectedDate);
        //console.log("response", response)
        setCompanies(response.data.data);
        setCurrentPage(response.data.meta.current_page);
        setPerPage(response.data.meta.per_page);
        setTotal(response.data.meta.total);

        //setDownloadData(response.data.data);

        setLoading(false);
    }


    useEffect(() => {
        console.log("mounting");
        getCompanyDataHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const handlePageEntries = (pageEntryNumber: any) => {
        setpageEntries(pageEntryNumber.target.value);
        getCompanyDataHandler(1, pageEntryNumber.target.value);
    };


    const handleSortingChange = (accessor: string) => {
        const sortOrder =
            accessor === sort && order === "asc" ? "desc" : "asc";
        setSort(accessor);
        setOrder(sortOrder);
        getCompanyDataHandler();
    };

    const launchSearch = async (searchValue: string): Promise<any> => {
        /* if (searchValue.length < 3) {
          return;
        } */
        setSearchTerm(searchValue);

    };
    const delayedSearch = useCallback(

        debounce(async (searchValue) => {

            await launchSearch(searchValue);
        }, 1000),
        []
    );


    const [selectedDate, setSelectedDate] = useState({
        startDate: "",
        endDate: ""
    });

    const getDates = (date:{startDate:string, endDate:string}) => {
        setSelectedDate({ "startDate": date.startDate, "endDate" :  date.endDate})
    }

    useEffect(() => {
        console.log("selectedDate", selectedDate);
        if(selectedDate.startDate !== "") {
            getCompanyDataHandler();
        }
        
    }, [searchTerm, selectedDate]);

    
    const getAllCompany = async (e: { preventDefault: () => void; }) => {
        setDownloadRead(false);
        e.preventDefault()
        setLoading(true);
        //console.log("searchTerm getUserDataHandler:",searchTerm);
        const response = await getCompanyAllData();
        //console.log(response.data);
        setDownloadData(response.data);
        setDownloadRead(true);
        setLoading(false);
    }

    const [downloadReady, setDownloadRead] = useState(false);
    const [downloadData, setDownloadData] = useState([]);


     useEffect(() => {
        if(downloadReady) {
            exportToCsv();
        }
        
    }, [downloadData, downloadReady]);

    const exportToCsv = () => {
        if(downloadReady) {
            // Headers for each column
            let headers = ["name,code,payment_type,vat,parent_company,payment_type,address,country,city,phone,email,fax,zip,status"]
            // Convert users data to a csv
            let dataCsv = downloadData.reduce((acc:any, company_obj:any) => {
                company_obj.status = (company_obj.status == "0") ? "Inactive" : "Active"  
            const { name,code,type,vat,parent_company,payment_type,address,country,city,phone,email,fax,zip, status } = company_obj
            const { name:parent_company_name } = parent_company
            const payment_type_update = payment_type.join("|");
            acc.push([ name,code,type,vat,parent_company_name,payment_type_update,address,country,city,phone,email,fax,zip,status].join(','))
            return acc
            }, []) 
        
            downloadFile({
            data: [...headers, ...dataCsv].join('\n'),
            fileName: 'companies.csv',
            fileType: 'text/csv',
            }) 
        }
        
      }

    return (
        <>
            <Titlestrip title={"Company List"} />
            <div className="container">
                <div className="outer-container">
                    {loading ? (

                        <Loader />
                    ) :
                        ""
                    }
                    <>
                        <div className="c-inner-container col-12">
                            <div className="row d-flex flex-wrap justify-content-between align-items-center">
                                <div className="col-12 col-sm-6 d-flex flex-wrap justify-content-sm-end right-container order-sm-1">
                                <button className="btn btn-export" onClick={getAllCompany}>Export</button>
                                    <Link
                                        to={configRoutes.company_list.add}
                                        className="btn btn-add-new"
                                    >
                                        Add New
                                    </Link>
                                </div>
                                <div className="col-12 col-sm-6 left-container d-flex flex-wrap justify-content-end justify-content-sm-start order-sm-0">
                                    <div className="left-wrapper">
                                        <span>Show</span>
                                        <select
                                            defaultValue={pageEntries}
                                            onChange={(e) => handlePageEntries(e)}
                                        >
                                            <option value={5}>5</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                        </select>
                                        <span>entries</span>

                                    </div>
                                    <div className="search-box">
                                        <div className="search-icon"></div>
                                        <input
                                            className="form-search"
                                            placeholder="Seach..."
                                            type="text"
                                            name="search"
                                            onChange={(e) => { delayedSearch(e.target.value) }}
                                        />
                                    </div>
                                    {/* <div className="search-box dateElementHide">
                                        <DateRangePickerComp getDates={getDates} />
                                   </div>  */}
                                </div>
                            </div>
                        </div>

                        <div className="table-data">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="table-light">
                                        <tr>
                                            {columns.map(({ label, accessor, sortable }) => {
                                                let cls = accessor === sort && order === "asc" ? "desc" : "asc";
                                                if (accessor !== sort) {
                                                    cls = "";
                                                }
                                                return <th key={accessor} className={cls} onClick={() => { if (!sortable) { return; } handleSortingChange(accessor) }}>{label}</th>;
                                            })}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companies ?
                                            (
                                                companies.map((company: any) => {
                                                    //let company_name = (user.company != null) ? user.company.name : "";
                                                    //let role_name = (user.roles != null) ? user.roles.slug : "";

                                                    let parent_name = (company.parent_company != null) ? company.parent_company.name : "";


                                                    return (

                                                        <tr key={uuidv4()}>
                                                            <td>{company.name ?? ""}</td>
                                                            <td>{parent_name ?? ""}</td>
                                                            <td>{company.address}</td>
                                                            <td>{company.city}</td>
                                                            <td>{company.email}</td>
                                                            <td>{company.code}</td>
                                                            <td>{company.status == 1 ? "Enabled" : "Disabled"}</td>
                                                            <td className="edit-container">
                                                                <Link
                                                                    to={`${configRoutes.company_list.edit}/${company.id}`}
                                                                >
                                                                    <img
                                                                        src={editIcon}
                                                                        alt="Edit Icon"
                                                                        title="Edit"
                                                                    />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )

                                            )
                                            :
                                            ""
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>

                    <div className="pagination-container">
                        <Pagination
                            activePage={current_page}
                            itemsCountPerPage={per_page}
                            totalItemsCount={total}
                            pageRangeDisplayed={3}
                            onChange={getCompanyDataHandler}
                            itemClass="page-item"
                            linkClass="page-link"
                            prevPageText="Previous"
                            nextPageText="Next"
                            innerClass="pagination justify-content-end"
                            itemClassPrev="noBorder"
                            itemClassNext="noBorder"
                            itemClassFirst="hideElement"
                            itemClassLast="hideElement"
                        />
                    </div>

                    
                </div>
            </div>
        </>
    );
};

export default Company;
