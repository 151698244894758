//@ts-check
//import { stringify } from 'querystring';
import { isNumber } from 'lodash';
import { getConstantValue } from 'typescript';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getStaticPageDetail = async (staticPageId:any, privilege?:any)=>{
   let data, status, errorMessage, response;
   console.log(staticPageId," ", typeof staticPageId)
  try{
    if(isNumber(staticPageId)) {
      response = await axiosInstance.get(`/static-pages/${staticPageId}`);
    } else {  
      if(privilege) {
        response = await axiosInstance.get(`/static-pages/slug/${staticPageId}/${privilege}`);
      } else {
        response = await axiosInstance.get(`/static-pages/slug/${staticPageId}`);
      }
      
    }
    
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}

const getStaticPageList = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/static-pages/all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const getStaticPages = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/static-pages?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=slug"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=privilege"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=vendor"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[3]=status"+ ',' + searchTerm;
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const addStaticPage = async (StaticPageData: any) => {
  console.log(StaticPageData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/static-pages`, StaticPageData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateStaticPage = async (StaticPageData: any, StaticPageId:number) => {
  console.log(StaticPageData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.put(`/static-pages/${StaticPageId}`, StaticPageData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}




export {getStaticPageDetail, getStaticPageList, getStaticPages, addStaticPage, updateStaticPage}