/* import * as React from "react"; */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getCountryList, getUserDetials, updateUser } from "../../external_api/User";
import { getCompanyDetail, getCompanyList, updateCompany } from "../../external_api/Company";
import { getRoleList } from "../../external_api/User";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { companyTypeList, paymentTypeList, privilegeList, vendorList } from "../../constants/StaticData";
import { updateLocation } from "../../external_api/Location";
import { getStaticPageDetail, updateStaticPage } from "../../external_api/StaticPage";
import { getLanguageList } from "../../external_api/Language";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react-ts";

const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    uploader: {
      insertImageAsBase64URI: true
    },
    height: 400 
}

const EditStaticPage = () => {

    const { isFetching, isSuccess: isLogin } = useSelector(
        userSelector
    );

    //const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }


    


    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        slug: Yup.string()
            .required('Slug is required'),
        content: Yup.string().nullable(),
        language_id: Yup.string()
            .required('Locale is required'),
        company_id: Yup.string().nullable(),
        vendor: Yup.string()
            .required('Vendor is required'),
        privilege: Yup.string().nullable(),
        status: Yup.string()
            .required('Status is required'),
    });


    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    type FormValues = {
        name: string,
        slug: string,
        content: string,
        vendor: string,
        language_id: string,
        company_id: string,
        privilege: string,
        status: string,
    }


    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


    const [loading, setLoading] = useState(false);

    const [staticPageDetail, setStaticPageDetail] = useState(
        {

            name: '',
            slug: '',
            content: '',
            vendor: '',
            language_id: '',
            company_id: '',
            privilege: '',
            status: '',

        }
    );
    const paramData = useParams();
    const [pageId, setPageId] = useState(paramData.id);

    //const userId = paramData.

    const [loginResMsg, setLoginResMsg] = useState({
        status: false,
        msg: ""
    });

    const [inputUserName, setUserName] = useState("");


    // Login Form Submit Handler

    const onSubmitForm = async (formData: any) => {
        formData['content'] = content;
        console.log("formData", formData, pageId);
        //return true;
        setLoading(true);
        var response = await updateStaticPage(formData, Number(pageId));
        const { data, status, errorMessage } = response;
        console.log(response);
        if (status == 201) {
            setLoading(false);
            toast.success("Page has been updated");
            setTimeout(() => {
                navigate("/staticpage");  
            }, 500);
        } else {
            toast.error(errorMessage);
        }
        setLoading(false);

    };

    const [countryList, setCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [languageList, setLanguageList] = useState([]);

    
    const [content, setContent] = useState("");

      

    useEffect(() => {
        (async () => {
            setLoading(true);
            var responseCountry = await getCountryList();
            var { data, status } = responseCountry;
            //console.log("data",data[0])
            if (status === 200) {
                setCountryList(data);
            }

            var responseLanguage = await getLanguageList();
            var { data, status } = responseLanguage;
            //console.log("data",data[0])
            if (status === 200) {
                setLanguageList(data);
            }

           var responseCompnay = await getCompanyList();
            var { data, status } = responseCompnay;
            //console.log("data",data[0])
            if (status === 200) {
                setCompanyList(data);
            }

            var response = await getStaticPageDetail(Number(pageId));
            console.log("profile response", response)
            var { data, status } = response;
            console.log("userdata", status, data)
            if (status === 201) {
                setTimeout(() => setStaticPageDetail(data), 1000);
                //setUserDetail(data);
            } else {
                //navigate("/location");
            }
            setLoading(false);

            //console.log("profile data",data.output);
        })()

    }, []);

    useEffect(() => {
        return reset(
            {

                name: staticPageDetail.name,
                slug: staticPageDetail.slug,
                content: staticPageDetail.content,
                vendor: staticPageDetail.vendor,
                language_id: staticPageDetail.language_id,
                company_id: staticPageDetail.company_id,
                privilege: staticPageDetail.privilege,
                status: staticPageDetail.status,

                
            }
        );
    }, [reset, staticPageDetail]);





    //console.log(post)

    return (
        <>
            <Toaster

                toastOptions={{
                    className: 'toaster-popup',
                }}
            />
            {loading ? (

                <Loader />
            ) :
                ""
            }
            <Titlestrip title={"Edit Page"} />
            <div className="form-wrapper container-fluid">
                <div className="row">
                    <div className="container">


                        <form
                            className="spacertopform"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="userEdit"
                            id="userEdit"
                            autoComplete="off"
                        >
                            {
                                loginResMsg.msg ?
                                    <div className="col-12">
                                        <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                                            {loginResMsg.msg}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <div className="custom-field ">
                                        <label>Name Page<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.name ? 'is-invalid' : ''}`}
                                                {...register("name")}
                                                defaultValue={staticPageDetail.name}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.name?.message}</span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="custom-field ">
                                        <label>Slug<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.slug ? 'is-invalid' : ''}`}
                                                {...register("slug")}
                                                defaultValue={staticPageDetail.slug}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.slug?.message}</span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="custom-field">
                                        <label>Locale<span className="red">*</span></label>
                                
                                    <div className="select">
                                            <select
                                                {...register("language_id")}
                                                defaultValue={staticPageDetail.language_id ?? ""}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    languageList.map((lang: any, index) => {

                                                        return <option value={lang.id} key={index}>{lang.name}</option>
                                                    })

                                                }


                                            </select>
                                        </div>
                                        <span className="formFieldError error">{errors.language_id?.message}</span>
                                        </div>
                                        </div>

                                
                            </div>

                            <div className="row">   

                            <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        <label>Privilege<span className="red">*</span></label>
                                        <select
                                            {...register("privilege")}
                                            defaultValue={staticPageDetail.privilege}
                                        >
                                            {privilegeList.length > 0 ?
                                                privilegeList.map((privilege: any, index) => {
                                                    return <option value={privilege.code} key={index}>{privilege.name}</option>;
                                                })
                                                :
                                                ""
                                            }
                                        </select>
                                    </div>
                                    <span className="formFieldError error">{errors.privilege?.message}</span>
                                </div>     
                                <div className="col-md-3 col-12">
                                    <div className="custom-field ">
                                        <label>Vendor<span className="red">*</span></label>
                                        <select
                                            {...register("vendor")}
                                            defaultValue={staticPageDetail.vendor}
                                        >
                                            {vendorList.length > 0 ?
                                                vendorList.map((vendor: any, index) => {
                                                    return <option value={vendor.code} key={index}>{vendor.name}</option>;
                                                })
                                                :
                                                ""


                                            }
                                        </select>
                                    </div>
                                    <span className="formFieldError error">{errors.vendor?.message}</span>
                                </div>                                                    
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Status<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("status")}
                                                defaultValue={staticPageDetail.status}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-12">
                                    <div className="custom-field">
                                        <label>Company</label>
                                
                                <select
                                                {...register("company_id")}
                                                defaultValue={staticPageDetail.company_id}  
                                                >
                                                <option value={""} key={999}>---</option>    
                                                {companyList.length > 0 ?
                                                    companyList.map((company: any,index) => {
                                                        return <option value={company.id} key={index}>{company.name}</option>;
                                                    })
                                                    :
                                                    ""


                                                }
                                            </select>
                                            </div>
                                            <span className="formFieldError error">{errors.company_id?.message}</span>
                                            </div>    

                                

                            </div>
                            <div className="row">
                            <div className="col-md-12 col-12">                    
                            <JoditEditor
                                    defaultValue={staticPageDetail.content}
                                    config={editorConfig}
                                    //tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent:string) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    
                                    />

                            </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12">
                                    <input type="submit" value="Save" className="btn-yellow" />
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>

        </>
    )



}


export default EditStaticPage;


