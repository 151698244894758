//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getSettingDetail = async (settingId?:any)=>{
   let data, status, errorMessage;
  try{

    if(settingId) 
      var response = await axiosInstance.get(`/settings/${settingId}`);
    else 
      var response = await axiosInstance.get(`/get-setting`);
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}



 const getSettings = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/settings?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    /*if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=code"+ ',' + searchTerm;
    }*/
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const addSetting = async (SettingData: any) => {
  console.log(SettingData);
  let data, status, errorMessage;

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  try {
    const response = await axiosInstance.post(`/settings`, SettingData, {
      headers: headers
    });
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateSetting = async (SettingData: any, settingId:number) => {
  console.log(SettingData);
  let data, status, errorMessage;

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  try {
    const response = await axiosInstance.post(`/settings/${settingId}`, SettingData, {
      headers: headers
    });
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}




export {getSettingDetail,getSettings, addSetting, updateSetting}