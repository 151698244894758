//import * as React from "react";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Titlestrip from "../../utility/Titlestrip";
import editIcon from "../../assets/svg/edit.svg";
import "../../assets/scss/company-list.scss";
import "../../assets/scss/payment-type.scss";
import "../../assets/scss/search.scss";
import Loader from "../../components/loading/loader";
import { v4 as uuidv4 } from "uuid";
import Pagination from "react-js-pagination";
import configRoutes from "../../config/Routes";

import { getCompanies } from "../../external_api/Company";

import { debounce } from "lodash"
import { useSelector } from "react-redux";
import { userSelector } from "../../store/slices/user";
import { getStaticPages } from "../../external_api/StaticPage";



const StaticPage = () => {

    const { isSuccess: isLogin} = useSelector(
        userSelector
    );

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }

    const [loading, setLoading] = useState(false);
    const [pageEntries, setpageEntries] = useState(5);

    //const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [staticPages, setStaticPages] = useState([]);

    const [current_page, setCurrentPage] = useState(1);
    const [per_page, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);

    // Filter Inputs
    const [sort, setSort] = useState("slug");
    const [order, setOrder] = useState("asc");
    const [searchTerm, setSearchTerm] = useState("");

    const columns = [
        { label: "Name page", accessor: "name", sortable: true },
        { label: "Locale", accessor: "language", sortable: false },
        { label: "Privilege", accessor: "privilege", sortable: true },
        { label: "Vendor", accessor: "vendor", sortable: true },
        { label: "Company", accessor: "company", sortable: false },
        { label: "Status", accessor: "status", sortable: true },
    ];


    const getStaticPageDataHandler = async (pageNumber: number = 1, pageSize?: number, search?: string) => {
        setLoading(true);
        console.log("pageEntries", pageEntries)
        if (!pageSize) {
            pageSize = pageEntries;
        }
        if (!search) {
            search = searchTerm;
        }
        //console.log("searchTerm getUserDataHandler:",searchTerm);
        const response = await getStaticPages(pageNumber, pageSize, sort, order, search);
        //console.log("response", response)
        setStaticPages(response.data.data);
        setCurrentPage(response.data.meta.current_page);
        setPerPage(response.data.meta.per_page);
        setTotal(response.data.meta.total);

        setLoading(false);
    }


    useEffect(() => {
        console.log("mounting");
        getStaticPageDataHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const handlePageEntries = (pageEntryNumber: any) => {
        setpageEntries(pageEntryNumber.target.value);
        getStaticPageDataHandler(1, pageEntryNumber.target.value);
    };


    const handleSortingChange = (accessor: string) => {
        const sortOrder =
            accessor === sort && order === "asc" ? "desc" : "asc";
        setSort(accessor);
        setOrder(sortOrder);
        getStaticPageDataHandler();
    };

    const launchSearch = async (searchValue: string): Promise<any> => {
        /* if (searchValue.length < 3) {
          return;
        } */
        setSearchTerm(searchValue);

    };
    const delayedSearch = useCallback(

        debounce(async (searchValue) => {

            await launchSearch(searchValue);
        }, 1000),
        []
    );

    return (
        <>
            <Titlestrip title={"Page List"} />
            <div className="container">
                <div className="outer-container">
                    {loading ? (

                        <Loader />
                    ) :
                        ""
                    }
                    <>
                        <div className="c-inner-container col-12">
                            <div className="row d-flex flex-wrap justify-content-between align-items-center">
                                <div className="col-12 col-sm-6 d-flex flex-wrap justify-content-sm-end right-container order-sm-1">
                                    {/* <button className="btn btn-export">Export</button> */}
                                    <Link
                                        to={configRoutes.staticpage_list.add}
                                        className="btn btn-add-new"
                                    >
                                        Add New
                                    </Link>
                                </div>
                                <div className="col-12 col-sm-6 left-container d-flex flex-wrap justify-content-end justify-content-sm-start order-sm-0">
                                    <div className="left-wrapper">
                                        <span>Show</span>
                                        <select
                                            defaultValue={pageEntries}
                                            onChange={(e) => handlePageEntries(e)}
                                        >
                                            <option value={5}>5</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                        </select>
                                        <span>entries</span>

                                    </div>
                                    <div className="search-box">
                                        <div className="search-icon"></div>
                                        <input
                                            className="form-search"
                                            placeholder="Seach..."
                                            type="text"
                                            name="search"
                                            onChange={(e) => { delayedSearch(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-data">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="table-light">
                                        <tr>
                                            {columns.map(({ label, accessor, sortable }) => {
                                                let cls = accessor === sort && order === "asc" ? "desc" : "asc";
                                                if (accessor !== sort) {
                                                    cls = "";
                                                }
                                                return <th key={accessor} className={cls} onClick={() => { if (!sortable) { return; } handleSortingChange(accessor) }}>{label}</th>;
                                            })}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {staticPages ?
                                            (
                                                staticPages.map((staticPage: any) => {
                                                    let company_name = (staticPage.company != null) ? staticPage.company.name : "";
                                                    let locale = (staticPage.language != null) ? staticPage.language.name : "";
                                                    //let role_name = (user.roles != null) ? user.roles.slug : "";


                                                    return (

                                                        <tr key={uuidv4()}>
                                                            <td>{staticPage.name ?? ""}</td>
                                                            <td>{locale ?? ""}</td>
                                                            <td>{staticPage.privilege}</td>
                                                            <td>{staticPage.vendor}</td>
                                                            <td>{company_name}</td>
                                                            <td>{staticPage.status == 1 ? "Enabled" : "Disabled"}</td>
                                                            <td className="edit-container">
                                                                <Link
                                                                    to={`${configRoutes.staticpage_list.edit}/${staticPage.id}`}
                                                                >
                                                                    <img
                                                                        src={editIcon}
                                                                        alt="Edit Icon"
                                                                        title="Edit"
                                                                    />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )

                                            )
                                            :
                                            ""
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>

                    <div className="pagination-container">
                        <Pagination
                            activePage={current_page}
                            itemsCountPerPage={per_page}
                            totalItemsCount={total}
                            pageRangeDisplayed={3}
                            onChange={getStaticPageDataHandler}
                            itemClass="page-item"
                            linkClass="page-link"
                            prevPageText="Previous"
                            nextPageText="Next"
                            innerClass="pagination justify-content-end"
                            itemClassPrev="noBorder"
                            itemClassNext="noBorder"
                            itemClassFirst="hideElement"
                            itemClassLast="hideElement"
                        />
                    </div>

                    {/* <nav>

          <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={450}
          pageRangeDisplayed={5}
          
        />
            <ul className="pagination justify-content-end">
              <li className="page-item disabled">
                <a className="page-link">Previous</a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav> */}
                </div>
            </div>
        </>
    );
};

export default StaticPage;
