// server.autosuggest.js
import React from 'react';
import Autosuggest from 'react-autosuggest';
import './autosuggest.scss';

import { getLocations, searchLocations } from "../../external_api/Location";

import { vendorList } from "../../constants/StaticData";

type Props = {
    // using `interface` is also ok
    name: string
    SetSelPickupLoc?: any,
    SetSelPickupOagcode: any,
    SetSelDropoffLoc: any,
    SetSelDropoffOagcode: any,
    setLocLat: any,
    setLocLon: any,
    setCountry: any,
    setVendor?: any,
    defaultValue?: any,
    setName?: any,
    setAddress?: any,    
    setOperationHour?: any
};

type State = {
    value: string,
    suggestions: any
}

class ServerAutoSuggest extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        //Define state for value and suggestion collection
        this.state= {
            value: this.props?.defaultValue,
            suggestions: []
        };
        console.log("ServerAutoSuggest construct" + this.props?.defaultValue);
    }

    // Filter logic
    getSuggestions = async (value:string) => {
        //this.props.classList.add("ajay");
        let elementContainer =  document.querySelectorAll('div[aria-owns=react-autowhatever-'+this.props.name+']');
        elementContainer[0].classList.add("autocomplete-loading");
        //document.querySelectorAll('div[aria-owns=react-autowhatever-pickup]')[0].classList.add("autocomplete-loading")

        /* this.props.SetSelPickupLoc("")
        this.props.SetSelPickupOagcode("")
        this.props.SetSelDropoffLoc("")
        this.props.SetSelDropoffOagcode("")
         */        
        const inputValue = value.trim().toLowerCase();
        //let response = await fetch("http://www.omdbapi.com/?s=" + inputValue + "&apikey=a591bb53");
        const response = await searchLocations(1, 50, 'code', 'asc', inputValue);
        elementContainer[0].classList.remove("autocomplete-loading");
        return response.data.data;
        /* let data = await response.json()
        return data; */
    };

    // Trigger suggestions
    getSuggestionValue = (suggestion:any) => {
        if(this.props.name === "pickup") {
              
            this.props.SetSelPickupLoc(suggestion.name)
            this.props.SetSelPickupOagcode(suggestion.code)
            this.props.SetSelDropoffLoc(suggestion.name)
            this.props.SetSelDropoffOagcode(suggestion.code)
            this.props.setCountry(suggestion.country)
            this.props.setVendor(suggestion.vendor)
            this.props.setLocLat(suggestion.latitude)
            this.props.setLocLon(suggestion.longitude)
            this.props.setName(suggestion.name)
            this.props.setAddress(suggestion.address)
            this.props.setOperationHour(suggestion.operation_hours)
        } else {
            this.props.SetSelDropoffLoc(suggestion.name)
            this.props.SetSelDropoffOagcode(suggestion.code)

        }
        //localStorage.removeItem("search_booking");  
        
        //this.props.SetSelPickupLoc(suggestion.code+' - '+suggestion.country+' - '+suggestion.city);
        const vendor = vendorList.filter(item => item.code === suggestion.vendor);

        

        return suggestion.code+' - '+suggestion.country+' - '+suggestion.name;
    }

    // Render Each Option
    renderSuggestion = (suggestion: any) => {

        const vendor = vendorList.filter(item => item.code === suggestion.vendor);

        return (   
            <div className="sugg-option">
                <span className="locationContainer">{suggestion.code} - {suggestion.country} -  {suggestion.city}  -  {suggestion.name}</span>
                <span className="addressContainer">
                    {suggestion.address}
                    <br />
                    <b>Opening Hours: {suggestion.operation_hours}</b>
                </span>
                
            </div>
           
        )
   };

    // OnChange event handler
    onChange = (event: any, { newValue }:{newValue:string}) => {
        this.setState({
            value: newValue
        });
    };

    // Suggestion rerender when user types
    onSuggestionsFetchRequested = ({ value }:{value: any}) => {
        this.getSuggestions(value)
            .then(data => {
                if (data.Error) {
                    this.setState({
                        suggestions: []
                    });
                } else {
                    this.setState({
                        suggestions: data
                    });
                }
            })
    };

    // Triggered on clear
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        // Option props
        const inputProps = {
            placeholder: '',
            value,
            onChange: this.onChange,
        };
        
        // Adding AutoSuggest component
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                id={this.props.name}
                
                
            />
        );
    }
}

export default ServerAutoSuggest;