//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';

type bookingInput = {
    pickUpLoc : string,
    pickUpDate : string,
    pickUpTime : string,
    retLoc : string,
    retDate : string,
    retTime : string,
    //payment_type? : string,
    extras? : string,
    dob?: string,
    reservation_id?: string
}


const getVehicleAvailable = async (postData:bookingInput)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.post(`/booking/vehicle-available`, postData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return {data, status, errorMessage}
}

type saveBookingInput = {
  firstname: string,
  lastname: string,
  phone: string,
  email: string,
  airline_carrier?: string,
  flight_number?: string,
  driving_licence_number: string,
  dropoff_oagcode: string,
  driving_licence_expiration: string,
  gpnumber?: string,
  customer_remarks?: string,
  reservationId?: string,
  selected_vehicle: {
    rateType: string,
    vehicleCode: string
  }
  
}

const saveBooking = async (postData:saveBookingInput)=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.post(`/booking/book`, postData);
   data = response.data.data;
   status = response.status;
   if(status !== 201) {
     errorMessage = response.data.message
   }
 } catch (error) {
   errorMessage = getAxiosErrorMessage(error);
 }
 return {data, status, errorMessage}
}

const updateBooking = async (postData:saveBookingInput)=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.put(`/booking/book`, postData);
   data = response.data.data;
   status = response.status;
   if(status !== 201) {
     errorMessage = response.data.message
   }
 } catch (error) {
   errorMessage = getAxiosErrorMessage(error);
 }
 return {data, status, errorMessage}
}

const getVehicleRates = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/booking/vehicle-list`);
     data = response.data.data;
     status = response.status;
     if(status !== 201) {
       errorMessage = response.data.message
     }
   } catch (error) {
     errorMessage = getAxiosErrorMessage(error);
   }
   return {data, status, errorMessage}
 }

const getReservationDetail = async (reservationId:string)=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.get(`/booking/confirmation/${reservationId}`);
   data = response.data.data;
   status = response.status;
 }catch(error){
   errorMessage = getAxiosErrorMessage(error);
   console.log(errorMessage);
 }
 return {data, status, errorMessage}
}



const sendEmail = async (reservation_id:any)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.post(`/booking/send-mail`, {'reservation_id': reservation_id});
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return {data, status, errorMessage}
}


const getReservationPdf = async (reservation_id:any)=>{
  let data, status, errorMessage;
 try{
   //const response = await axiosInstance.post(`/booking/pdf`, {responseType: 'blob', 'reservation_id': reservation_id});
   const response = await axiosInstance.get(`/booking/pdf/${reservation_id}`,{ responseType: 'blob' });
   const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', reservation_id+'.pdf')
    document.body.appendChild(link)
    link.click()
 } catch (error) {
   errorMessage = getAxiosErrorMessage(error);
 }
 return {data, status, errorMessage}
}


const cancelReservation = async (reservation_id:any)=>{
  let data, status, errorMessage;
 try{
   const response = await axiosInstance.post(`/booking/cancel`, {'reservation_id': reservation_id});
   data = response.data.data;
   status = response.status;
   if(status !== 201) {
     errorMessage = response.data.message
   }
 } catch (error) {
   errorMessage = getAxiosErrorMessage(error);
 }
 return {data, status, errorMessage}
}

 



export {getVehicleAvailable , getVehicleRates, saveBooking, getReservationDetail, updateBooking, sendEmail, getReservationPdf, cancelReservation}