import React from "react";
const Titlestrip = ({ title }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row page-title">
          <div className="container">
            <div>
              <h1 className="title">{title}</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Titlestrip;
