import * as React from "react";
import Loader from "../../components/loading/loader";
import team_member_1 from "../../assets/images/paul-img.jpg";
import team_member_2 from "../../assets/images/noeleen-img.jpg";
import team_member_3 from "../../assets/images/penny-img.jpg";
import team_member_4 from "../../assets/images/customer-srvc-img.jpg";
import call from "../../assets/svg/call.svg";
import mail from "../../assets/svg/mail.svg";
import "../../assets/scss/static.scss";
const Team = () => {
  return (
    <>
      {/* <Loader /> */}
      <div className="container-fluid static-pages">
        <div className="row">
          <section className="team-section">
            <h2 className="section-tittle">Meet the team</h2>
            <div className="inner-section">
              <div className="person-box">
                <div className="team-img-box">
                  <img src={team_member_1} alt="" />
                </div>
                <div className="person-detail">
                  <h3 className="tittle-name">Paul Manning</h3>
                  <p className="tittle-role">Sales Manager</p>
                  <div className="tittle-contact-box">
                    <a href="mailto:pmanning@hertz.ie">
                      <img src={mail} alt="" />
                      <span>pmanning@hertz.ie</span>
                    </a>
                    <a href="tel:+353 53 91 52 556">
                      <img src={call} alt="" />
                      <span>+353 53 91 52 556</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="person-box">
                <div className="team-img-box">
                  <img src={team_member_2} alt="" />
                </div>
                <div className="person-detail">
                  <h3 className="tittle-name">Noeleen Furlong</h3>
                  <p className="tittle-role">Senior Account Manager</p>
                  <div className="tittle-contact-box">
                    <a href="mailto:nfurlong@hertz.ie">
                      <img src={mail} alt="" />
                      <span>nfurlong@hertz.ie</span>
                    </a>
                    <a href="tel:+353 53 91 52 552">
                      <img src={call} alt="" />
                      <span>+353 53 91 52 552</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="person-box">
                <div className="team-img-box">
                  <img src={team_member_3} alt="" />
                </div>
                <div className="person-detail">
                  <h3 className="tittle-name">Penny Kirwan</h3>
                  <p className="tittle-role">Credit Control</p>
                  <div className="tittle-contact-box">
                    <a href="mailto:pmanning@hertz.ie">
                      <img src={mail} alt="" />
                      <span>creditcontrol@hertz.ie</span>
                    </a>
                    <a href="tel:+353 53 91 52 556">
                      <img src={call} alt="" />
                      <span>+353 53 91 52 520</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="person-box">
                <div className="team-img-box">
                  <img src={team_member_4} alt="" />
                </div>
                <div className="person-detail">
                  <h3 className="tittle-name">Customer Service</h3>
                  <p className="tittle-role"></p>
                  <div className="tittle-contact-box">
                    <a href="mailto:customerservices@hertz.ie">
                      <img src={mail} alt="" />
                      <span>customerservices@hertz.ie</span>
                    </a>
                    <a href="tel:+353 53 91 52 500">
                      <img src={call} alt="" />
                      <span>+353 53 91 52 500</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Team;
