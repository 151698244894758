import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../../store';


export const vehicleSelectedRate = createAsyncThunk(
    'vehicleSelected/rate',
    // eslint-disable-next-line no-empty-pattern
    async ({ rateType, vehicleCode, rateObj }: { rateType: string, vehicleCode: string, rateObj: any }, thunkAPI) => {
        try {

            return {
                rateType: rateType,
                vehicleCode: vehicleCode,
                rateObj: rateObj
            }
        } catch (e) {
            console.log('Error', e);
            //@ts-ignore
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


    
export const vehicleSelectedRateSlice = createSlice({
    name: 'vehicleSelectedRate',
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        rateType: "",
        vehicleCode: "",
        rateObj: {
            total_price: "",
            picture_url: "",
            veh_name: "",
            passener_qty: "",
            baggage_qty: "",
            mileage: "",
            currency: "",
            transmission_type: "",
            pre_pay: "",
            pre_pay_amount: "",
            priced_equip: [],
            fees: [],
            priced_coverages: [],
            vc_one_way: "",
            vc_one_way_charge: "",
            vc_one_way_currency_code: "",
            is_business_fare: "",
            total_price_initial: "",
            privilege: "",
            additional_inclusions: [],
            damage_excess: "",
            damage_excess_currency: "",
            payAtLocationTax: "",
            payAtLocation: "",
            tour_voucher_amount: "",
            tour_voucher_amount_with_margin: "",
            tour_voucher_currency: "",
            margin: "",
            margin_amount: 0,
            total_amount_with_margin: "",

        }
    },
    reducers: {
        clearSelectedVehicleState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.rateType = "";
            state.vehicleCode = "";
            state.rateObj = {
                total_price: "",
                picture_url: "",
                veh_name: "",
                passener_qty: "",
                baggage_qty: "",
                mileage: "",
                currency: "",
                transmission_type: "",
                pre_pay: "",
                pre_pay_amount: "",
                priced_equip: [],
                fees: [],
                priced_coverages: [],
                vc_one_way: "",
                vc_one_way_charge: "",
                vc_one_way_currency_code: "",
                is_business_fare: "",
                total_price_initial: "",
                privilege: "",
                additional_inclusions: [],
                damage_excess: "",
                damage_excess_currency: "",
                payAtLocationTax: "",
                payAtLocation: "",
                tour_voucher_amount: "",
                tour_voucher_amount_with_margin: "",
                tour_voucher_currency: "",
                margin: "",
                margin_amount: 0,
                total_amount_with_margin: "",
            };
            return state;
        },
    },
    extraReducers: {
        //@ts-ignore
        [vehicleSelectedRate.fulfilled]: (state, { payload }) => {
            console.log('login User payload', payload);
            state.isFetching = false;
            state.isSuccess = true;
            state.isError = false;
            state.rateType = payload.rateType;
            state.vehicleCode = payload.vehicleCode;
            state.rateObj = payload.rateObj;
            return state;
        },
        //@ts-ignore
        [vehicleSelectedRate.rejected]: (state, { payload }) => {
            console.log('rejected payload', payload);
            state.isFetching = false;
            state.isError = true;
            state.isSuccess = false;
            state.rateType = "";
            state.vehicleCode = "";
            state.rateObj = {
                total_price: "",
                picture_url: "",
                veh_name: "",
                passener_qty: "",
                baggage_qty: "",
                mileage: "",
                currency: "",
                transmission_type: "",
                pre_pay: "",
                pre_pay_amount: "",
                priced_equip: [],
                fees: [],
                priced_coverages: [],
                vc_one_way: "",
                vc_one_way_charge: "",
                vc_one_way_currency_code: "",
                is_business_fare: "",
                total_price_initial: "",
                privilege: "",
                additional_inclusions: [],
                damage_excess: "",
                damage_excess_currency: "",
                payAtLocation: "",
                payAtLocationTax: "",
                tour_voucher_amount: "",
                tour_voucher_amount_with_margin: "",
                tour_voucher_currency: "",
                margin: "",
                margin_amount: 0,
                total_amount_with_margin: "",
            };
            //state.errorMessage = payload.message;
            //state.errorMessage = payload.errorMessage
            state.errorMessage = ""
        },
        //@ts-ignore
        [vehicleSelectedRate.pending]: (state) => {
            state.isFetching = true;
        }
    },
});


export const { clearSelectedVehicleState } = vehicleSelectedRateSlice.actions;

//export const userSelector = (state: { user: any; }) => state.user;
export const vehicleSelectedRateSelector = (state: RootState) => state.vehicleSelected;

export default vehicleSelectedRateSlice.reducer;
