import React, { useState } from "react";
//import closeIcon from "../../../assets/svg/cross-icon.svg";
import closeIcon from "../../assets/svg/cross-icon.svg";
import "../../assets/scss/booking.scss";
import { capitalizeFirstLetter, hasAdditionallDriver, hasCoverageIncluded, hasTankOfFuel, getAdditionalInclusions } from "../../utility/Helpers";
import { extrasList, coverageList } from "../../constants/StaticData"
const PopupIncluded = ({rates, country, closeHandler}:{rates:any, country:string, closeHandler:any}) => {
    const searchCoverage = (searchVal: any) => coverageList.filter(element => element.id == searchVal);  

  return (
    
    <>
    

      <div className={`modal-box-countainer infoPoup`}>
        <div className="spacerLR_model">
          <div className="modal-box">
            <div className="modal-body-box extra-fee-modal">
              <button className="close-btn btn">
                <img src={closeIcon} alt="close icon" onClick={closeHandler} />
              </button>
              <h2 className="etra-fee-title">Inclusions</h2>
              <div className="height-setting">
                <div className="s-scroll">
                  <div className="extra-fee-inner">
                    
                        <ul>
                            <li style={{textAlign:"left",width:"100% !important"}}>{capitalizeFirstLetter(rates.mileage)}</li>
                            <li style={{textAlign:"left",width:"100% !important"}}>{"Total Sales Tax"}</li>
                            {

                                rates.priced_coverages ? rates.priced_coverages.map((coverage: any, index: number) => {
                                var coverageDetails = searchCoverage(coverage.type);
                                if(!hasCoverageIncluded(coverage)) {
                                        return "";
                                }   
                                return <li style={{textAlign:"left",width:"100% !important"}}>{coverageDetails[0].name}</li>
                            }
                                )
                                :
                                ""
                            }
                            {/* {
                                rates?.vc_one_way ?
                                    <li style={{textAlign:"left",width:"100% !important"}}>{"One way charges:"} {rates?.vc_one_way_charge} {rates.vc_one_way_currency_code}</li>
                                :
                                ""
                            } */}
                            {/* {
                                rates?.fare_name.toLowerCase().indexOf("plus") !== -1 || rates?.fare_name.toLowerCase().indexOf("max") !== -1  ?
                                    <li style={{textAlign:"left",width:"100% !important"}}>{"Additional Driver"}</li>
                                :
                                ""
                            } */}
                            {/*{
                                hasAdditionallDriver(country, rates?.fare_name)  ?
                                    <li style={{textAlign:"left",width:"100% !important"}}>{"Additional Driver"}</li>
                                :
                                ""
                            }
                            {
                                hasTankOfFuel(country, rates?.fare_name)  ?
                                    <li style={{textAlign:"left",width:"100% !important"}}>{"Tank of fuel"}</li>
                                :
                                ""
                            }*/}
                            {
                                getAdditionalInclusions(country, rates?.additional_inclusions)  ?
                                    rates?.additional_inclusions.map((name: any) => (
                                      name != "" ?    
                                      <li style={{textAlign:"left",width:"100% !important"}}>  
                                        {name}  
                                      </li> 
                                      :
                                      ""
                                    ))
                                    
                                :
                                ""
                                
                            }
                            {/* {
                                rates.fees.length > 0 ?
                                rates.fees.map((fee:any, index:number) => {
                                    if(fee["@attributes"].IncludedInRate !== "true") {
                                        return "";
                                    }
                                    return <li style={{textAlign:"left",width:"100% !important"}} key={index}>{capitalizeFirstLetter(fee["@attributes"].Description.replace(":", "   "))}</li>
                                })
                                :
                                ""
                            } */}
                            {/* Vehicle Licence Fee (VLF)
                            One way fee (payable on return of the vehicle)
                            Total Sales Tax
                            Young Driver Surcharge */}
                        </ul>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupIncluded;
