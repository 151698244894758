import * as React from 'react';
import { Link } from 'react-router-dom';
import "../../assets/scss/404.scss";
import configRoutes from '../../config/Routes'
import notFoundImg from '../../assets/images/404-car-image.jpeg'

const NotFound = () => (
  <div className="container-fluid">
    <div className="row">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center center-height">
            <div className="col-12">
            <div className="notfound text-center">
                  <figure className="mb-5">
                    <img src={notFoundImg} alt="404" className="img-fluid" />
                  </figure>
                  <h1 className="heading-h1-container"><b>Oops! Looks like your page made a wrong turn and we can't seem to locate it.</b></h1>
                  <h5 className="heading-h5-container">Sorry, this page does not exist, but you can continue your journey with us here</h5>
                  <p>
                      <Link to={configRoutes.home} className="btn-blue">Make a reservation</Link>
                  </p>
            </div>
            </div>
          </div>
        </div>
    </div>
  </div>
);

export default NotFound;