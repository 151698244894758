import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../../assets/scss/static.scss";
import { getStaticPageDetail } from "../../external_api/StaticPage";
import Loader from "../loading/loader";
import NotFound from "./NotFound";



const PageInfo = (props: any) => {

  // Get the URL parameters
  //const params = props.match.params;
  type IPageData = {
    content: string
  }
  const pageInitialize = {
    content: ""
  }
  const [loading, setLoading] = useState(false);
  const [pageDetail, setPageDetail] = useState<IPageData>(pageInitialize);


  const slug = props.paramData.slug;
  const privilege_type = props.paramData.privilege_type;
  const [pageSlug, setPageSlug] = useState(slug);
  const [privilegeType, setPrivilegeType] = useState(privilege_type);
  console.log("props", props, slug, pageSlug, privilege_type, privilegeType)
  if (slug !== pageSlug) {
    setPageSlug(slug);
  }
  if (privilege_type !== privilegeType) {
    setPrivilegeType(privilege_type);
  }

  
  //const navigate = useNavigate();
  const [pageExist, setPageExist] = useState(true);
  useEffect(() => {
    (async () => {
      var response;
      setLoading(true);
      if(privilege_type) {
        response = await getStaticPageDetail(String(pageSlug), privilege_type);
      } else {
        response = await getStaticPageDetail(String(pageSlug));
      }
      
      var { data, status } = response;
      if (status === 201) {
        setPageDetail(data);
        const boxes = document.querySelectorAll('.acoordian-head');
        if(boxes.length) {
          boxes.forEach(box => {
            box.addEventListener('click', function handleClick(event:any) {
              const element = event.target.closest(".accordian-box");
              element.classList.toggle("open");
              
            });
          });
        }
        

      } else {
        setPageExist(false);
        //navigate("/");
      }
      setLoading(false);

      //console.log("profile data",data.output);
    })()

  }, [pageSlug, privilege_type]);


  return (
    <>
      {loading ? (
        <Loader />
      ) :
        ""
      }
      {
        pageExist ?
          <div className="static-content" dangerouslySetInnerHTML={{ __html: pageDetail.content }} />
          :
          <NotFound />
      }
    </>

  );
};

export default PageInfo;
