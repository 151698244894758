import React, { useState } from "react";
//import closeIcon from "../../../assets/svg/cross-icon.svg";
import closeIcon from "../../assets/svg/cross-icon.svg";
import "../../assets/scss/booking.scss";
const Popupinfo = ({closeHandler}:{closeHandler:any}) => {
  

  return (
    <>
    

      <div className={`modal-box-countainer infoPoup`}>
        <div className="spacerLR_model">
          <div className="modal-box">
            <div className="modal-body-box extra-fee-modal">
              <button className="close-btn btn">
                <img src={closeIcon} alt="close icon" onClick={closeHandler} />
              </button>
              <h2 className="etra-fee-title">Customer Remarks</h2>
              <div className="height-setting">
                <div className="s-scroll">
                  <div className="extra-fee-inner">
                    <p>
                      Enter details such as travelling into Eastern Europe, Italy. Do not use special characters such as ß=ss, ä=ae, ö=oe, ü=ue
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popupinfo;
