import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/svg/cross-icon.svg";

import { extrasList, coverageList } from "../../constants/StaticData"
import toast, { Toaster } from 'react-hot-toast';

import Loader from "../../components/loading/loader"
import { v4 as uuidv4 } from "uuid";
import { getVehicleAvailable, getVehicleRates } from "../../external_api/Booking";
import { useNavigate, useParams } from "react-router-dom";
import { vehiclerates, vehicleRatesSelector } from "../../store/slices/vehicleAvailable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { vehicleSelectedRate } from "../../store/slices/selectedVehicle";
import { decreaseCartQuantity, getTotal, increaseCartQuantity, extrasSelector } from '../../store/slices/extras'

import { indexOf } from "lodash";

import { capitalizeFirstLetter, hasCoverageIncluded } from "../../utility/Helpers";

type Props = { pickupLocationExtraData: any, extraData: any, selVehicle: any, extraCoverPopup: any, closextraCovelHandler: any, rateType: string, updateParentPrice:any }

const PopupExtrasCover = ({ pickupLocationExtraData, extraData, selVehicle, extraCoverPopup, closextraCovelHandler, rateType, updateParentPrice }: Props) => {

  const dispatch = useDispatch<AppDispatch>();
  const { vehicles: availableVehicles, rates: availableRates } = useSelector(
    vehicleRatesSelector
  );


  const { totalQuantity, extraItems } = useSelector(
    extrasSelector
  );

  const searchExtra = (searchVal: string) => extrasList.filter(element => element.code === searchVal);
  const searchCoverage = (searchVal: any) => coverageList.filter(element => element.id == searchVal);

  const [vehicle, setSelVehicle] = useState(selVehicle);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [extras, setExtras] = useState<number[]>([]);

  const [isExtraUpdate, setIsExtraUpdate] = useState(false);
  //const [isInitialize, setIsInitialize] = useState(false);

  const setExtrasArr = (extraId: number) => {

    if (extras.includes(extraId)) {
      setExtras(extras.filter((item: any) => item !== extraId))
      //setBookingDefaultData(prevValue => ({...prevValue, ...{"extra": extras.join(",")}})); 
      setIsExtraUpdate(true)
      return
    }
    setExtras([...extras, extraId])
    setIsExtraUpdate(true)
    //setBookingDefaultData(prevValue => ({...prevValue, ...{"extra": extras.join(",")}})); 
  }

  const [bookingDefaultData, setBookingDefaultData] = useState(
    {
      drop_location: "",
      pickup_time: "12:00",
      dropoff_time: "12:00",
      driver_age: "",
      //payment_type: "",
      pickup_location: "",
      dropoff_location: "",
      pickup_oagcode: "",
      dropoff_oagcode: "",
      pickup_date: "",
      dropoff_date: "",
      country: "",
      extra: "",
      vendor: "ZE",
      reservation_id: ""
    }
  );


  useEffect(() => {
    const searchBooking = String(localStorage.getItem('search_booking'));
    const searchBookingObj = JSON.parse(searchBooking);
    setBookingDefaultData(prevValue => ({ ...prevValue, ...searchBookingObj }));
    setIsExtraUpdate(false);
    

  }, [])


  useEffect(() => {
    console.log("Use Effect bookingDefaultData", bookingDefaultData, isExtraUpdate) ;
    if(!isExtraUpdate) {
      if(bookingDefaultData.extra != "") {
        const extra = String(bookingDefaultData.extra).split(",").map(Number);
        console.log("extra sel", extra);
        if(extra.length)
          setExtras(extra)
      }
      
    }
    
    var timeout = setTimeout(() => {
        if (isExtraUpdate) {
          console.log("Use Effect bookingDefaultData", bookingDefaultData, isExtraUpdate) ;
          bookRequest();
        }  
    }, 100);
    
    return () => {
      clearTimeout(timeout);
    }

  }, [bookingDefaultData])

  /* useEffect(() => {
     console.log("useEffect extras", extras)
    //setIsExtraUpdate(true);
    if(isExtraUpdate)
    setBookingDefaultData(prevValue => ({ ...prevValue, ...{ "extra": extras.join(",") } }));
 
  }, [extras]) */

  useEffect(() => {
    //console.log("useEffect extras", extras)
   //setIsExtraUpdate(true);
   setIsExtraUpdate(true);
   setBookingDefaultData(prevValue => ({ ...prevValue, ...{ "extra": extraItems } }));
   
   //if(isExtraUpdate)
   //setBookingDefaultData(prevValue => ({ ...prevValue, ...{ "extra": extras.join(",") } }));

 }, [totalQuantity, extraItems])

  const paramData = useParams();
  const [reservationId, setReservationId] = useState(paramData.reservationId);

  const handleContinueBtn = (event: any) => {
    if (reservationId)
      navigate("/booking/review-book/" + reservationId)
    else
      navigate("/booking/review-book")
    //bookRequest();
  }


  const [rateList, setRateList] = useState<any[]>([]);
  const [vehiclesList, setVehicleList] = useState<any[]>([]);

  const bookRequest = async () => {

    //console.log("Booking formData", bookingDefaultData);
    
    var formNewData = {
      pickUpLoc: bookingDefaultData.pickup_oagcode,
      pickUpDate: bookingDefaultData.pickup_date,
      pickUpTime: bookingDefaultData.pickup_time,
      retLoc: bookingDefaultData.dropoff_oagcode,
      retDate: bookingDefaultData.dropoff_date,
      retTime: bookingDefaultData.dropoff_time,
      country: bookingDefaultData.country,
      dob: bookingDefaultData.driver_age,
      extras: bookingDefaultData.extra,
      vendor: bookingDefaultData.vendor,
      reservation_id: bookingDefaultData?.reservation_id ?? ""
    }
    

    setLoading(true);

    var response = await getVehicleAvailable(formNewData);
    const { data, status, errorMessage } = response;
    //console.log("response", response)
    if (status == 201) {
      setLoading(false);
      localStorage.setItem('search_booking', JSON.stringify(bookingDefaultData));
      dispatch(vehiclerates(""));
      var response2 = await getVehicleRates();
      var { data: data2, status: status2 } = response2;
      console.log("data2", data2);
      if (status === 201) {
        //setTimeout(() => { setVehicleList(data2.vehicles); setRateList(data2.rates) }, 500);
        
        data2.rates.map((rate: any, index: number) => {
          var rateKey = Object.keys(rate)[0];
          if (rateType == rateKey) {
            var rateObj = rate[rateKey][vehicle.vehicle_code];
            storeSelVehicle(rateType, vehicle.vehicle_code, rateObj)
            console.log("rateObj.total_price", rateObj.total_price);
            //setTotalPrice((prev: any) => [...prev, rateObj.total_price]);
            //updateParentPrice(rateObj.total_price);
          }

        });
      } else {
        navigate("/booking");
      }

    } else {
      if (errorMessage.errors) {
        toast.error(errorMessage.errors.ShortText);
      } else {
        toast.error(errorMessage);
      }

    }
    setIsExtraUpdate(false)
    setLoading(false);

  };




  const storeSelVehicle = (rateType: string, vehicleCode: string, rateObj: any) => {

    var selectedVeh = {
      "rateType": rateType,
      "vehicleCode": vehicleCode,
      "rateObj": rateObj
    }

    localStorage.removeItem('selected_vehicle');
    localStorage.setItem('selected_vehicle', JSON.stringify(selectedVeh));
    dispatch(vehicleSelectedRate(selectedVeh));

  }

  
  


  //https://forum.freecodecamp.org/t/updating-product-quantity-to-cart-from-cardcomponent-redux-toolkit-react/584992

  useEffect(() => {
    dispatch(getTotal());

 }, [extraItems, dispatch])


  const handleRemoveExtra = (extraItem:any) => {
    dispatch(decreaseCartQuantity(extraItem));
  }

  const handleAddExtra = (extraItem:any) => {

    console.log('extraItem:',extraItem, 'totalQuantity',totalQuantity);
    if(extraItem.id == 13) {
        if(checkNavAdded(extraItem.id)) {
          return false;
        }
    }

    if (totalQuantity >= 4) {
        console.log("You cannot add more than 4 addons");
        return false;
    }
    dispatch(increaseCartQuantity(extraItem));
  }

  const getExtraCount = (extraId:number) => {

    const itemIndex = extraItems.findIndex(
      (extraItem: { id: any; }) => extraItem.id === extraId
    )
    return extraItems[itemIndex].extraQuantity ?? 0;

  }

  const checkNavAdded = (extraId:number) => {
        const itemIndex = extraItems.findIndex(
          (extraItem: { id: any; }) => extraItem.id === extraId
        )
      return extraItems[itemIndex].extraQuantity ?? 0;
  }

  /* function clearCart() {
    dispatch(clearAllCart());
  } */


  return (
    <>
      <Toaster
        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <div
        className={`modal-box-countainer ${!extraCoverPopup ? "hideBox" : ""}`}
      >
        <div className="modal-box">
          <div className="modal-body-box extra-fee-modal">
            <button
              className="close-btn btn"
              onClick={() => closextraCovelHandler()}
            >
              <img src={closeIcon} alt="close icon" />
            </button>
            <h2 className="etra-fee-title">Extras and Coverage{/*  {selVehicle.reference_ID}{vehicle.vehicle_code}{rateType} */}</h2>
            <div className="height-setting">
              <div className="s-scroll">
                <div className="extra-fee-inner">
                  {/* <h3>Extras</h3> */}
                  <div className="ef-d-flex hidee">
                    
                    {

                      extraData.length > 0 && pickupLocationExtraData.length > 0 ? extraData.map((extra: any, index: number) => {
                        var extraDetails = searchExtra(extra.code);
                        //console.log("searchLocExtra",searchLocExtra(extra.code));
                        let extraExist = pickupLocationExtraData.indexOf(extra.code);
                        //console.log("extraExist", extraExist, extraDetails[0].name);
                        var rentalText = "Per day Per item";
                        if(extra.country == "IE" && (extra.code == "BST" || extra.code == "CST")) {
                            rentalText = "Per rental";
                        }
                        if (extraExist === -1) {
                          return;
                        }

                        return (
                          <div className="fee-details" key={uuidv4()}>
                            <h4>{extraDetails[0].name}</h4>
                            <p dangerouslySetInnerHTML={{ __html: extra.content }} />
                            <h5>
                              {extra.cost_daily}{' '}{extra.currency} 

                              <span>{rentalText}</span>
                              

                            </h5>
                            {/* extras.indexOf(extraDetails[0].id) === -1 ?
                                <button className="btn add-btn" onClick={e => { e.preventDefault(); setExtrasArr(extraDetails[0].id) }}>Add</button>
                                :
                                <button className="btn remove-btn" onClick={e => { e.preventDefault(); setExtrasArr(extraDetails[0].id) }}>Remove</button> */}
                            {
                              getExtraCount(extraDetails[0].id) == 0 ?
                                <button className="btn add-btn" onClick={()=>{handleAddExtra(extraDetails[0])}}>Add</button>
                                :
                                <div className="input-group number-spinner">
                                  <span className="input-group-btn">
                                    <button 
                                    className="btn btn-default" 
                                    type="button" 
                                    data-extraid="{extraDetails[0].id}"
                                    onClick={()=>{handleRemoveExtra(extraDetails[0])}} 
                                    >
                                      <span className="spinner-minus"></span>
                                      </button>
                                    </span>
                                    <input maxLength={1} minLength={1} type="text" className="form-control text-center" defaultValue={getExtraCount(extraDetails[0].id)} />
                                     <span className="input-group-btn">
                                      <button 
                                      className="btn btn-default" 
                                      type="button" 
                                      onClick={()=>{handleAddExtra(extraDetails[0])}} 
                                      >
                                      <span className="spinner-plus"></span>
                                    </button>
                                    </span>
                            </div>
                            }
                            
                          </div>

                        )
                      })
                        :
                        ""
                    }
                  </div>
                  <h3>Coverage</h3>
                  <div className="ef-d-flex">
                    
                    {

                      selVehicle.priced_coverages ? selVehicle.priced_coverages.map((coverage: any, index: number) => {
                        var coverageDetails = searchCoverage(coverage.type);
                        var coverageIds: number[] = [38, 59];
                        /* if((Number(coverage.amount) === 0 && coverage.unitCharge === "") && coverageIds.indexOf(coverageDetails[0]?.id) !== -1  ) {
                          return "";
                        } */

                        /* if (!hasCoverageIncluded(coverage)) {
                          return "";
                        } */
                        // 32 PAI , 59 : SCE super cover
                        return (
                          <div className="fee-details">
                            <h4>{coverageDetails[0]?.name}</h4>
                            {
                            coverageDetails[0]?.id == 59 ?  
                            <p>
                            Eliminate your excess and protect yourself against collisions, theft and accidental damage.*
                            <br />
                            *Terms and conditions and exceptions apply.
                            </p>
                            :
                            coverageDetails[0]?.id == 38 ? 
                              <p className="coverage-para">
                              This option covers the authorised drivers, the passengers and the personal effects to a stated value which varies by country.
                            
                            </p>    
                            :
                            <p className="coverage-para">
                              Reduce your liability to an excess payment, in the event
                              of an incident resulting in damage to the vehicle*
                             
                            </p>
                            
                            }
                            {/* <span className="acess-ammount">
                              See Current<a href={""}> Excess amount</a>
                            </span> */}
                            <div className="p-bottom">
                              {
                                coverage.unitCharge !== "" && !hasCoverageIncluded(coverage) ?
                                  <h5>{coverage.unitCharge}{" "}{coverage?.currency_code}<span>Per Day exclusive of tax</span></h5>
                                  :
                                  ""
                              }
                              {
                                Number(coverage.amount) !== 0 && !hasCoverageIncluded(coverage) ?
                                  <h5>{coverage.amount}{" "}{coverage?.currency_code}<span>Per Day exclusive of tax</span></h5>
                                  :
                                  ""
                              }
                              {
                                hasCoverageIncluded(coverage) ?
                                  <button className="btn inclues">Included</button>
                                  :
                                  ""
                              }
                              {/* {
                                coverage.includeInRate == "false" ?
                                  <button className="btn available-btn">Available at counter</button>
                                  :
                                  ""
                              } */}

                            </div>
                          </div>
                        )
                      })
                        :
                        ""
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer-box">
              <button className="continue" onClick={closextraCovelHandler}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupExtrasCover;

