import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login, register } from "../../external_api/User";
import type { RootState } from '../../store';





export const signupUser = createAsyncThunk(
  'users/signupUser',
  async ({ name, email, phone, password }:{ name:string, email:string, phone:number, password:any }, thunkAPI) => {
    try {
      const response = await register({ name, email, phone, password })  
      
      let { data, status, errorMessage } = response;
      localStorage.setItem('hertzTravelUserToken', data.access_token);
      if (status === 200) {
        return { 
          first_name: data.first_name,  
          ...data, 
            last_name: data.last_name,  
            email: data.email, 
            phone: data.phone,
            errorMessage: '',
            access_token: data.token
        };
      } else {
        console.log('Error: 422 ', response);
        //return thunkAPI.rejectWithValue(data);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      //console.log('Error', e.response.data);
      //@ts-ignore
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  'users/login',
  async ({ email , password }:{email:string, password:any}, thunkAPI) => {
    try {
      
      const response = await login({ email, password })  
      console.log("response",response);
      let { data, status, errorMessage } = response;

      console.log('userLogin response', data,status,errorMessage);
      if (status === 200) {
        localStorage.setItem('hertzTravelUserToken', data.token);
        //localStorage.setItem('ta-app-user', data.user.id);
        return { 
          ...data,
          id: data.id, 
          name: data.name, 
          first_name: data.first_name,  
          last_name: data.last_name,  
          email: data.email, 
          phone: data.phone,
          access_token: data.token,
          company: data.company,
          role: data.role
      };
        //return data;
      } else {
        //return thunkAPI.rejectWithValue(data);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      //console.log('Error', e.response.data);
      //@ts-ignore
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    access_token: '',
    company: {
        name: "",
        code: "",
        id: "",
        vat: "",
        parent_id: "",
        payment_type: [],
        address: "",
        country: "",
        city: "",
        phone: "",
        email: "",
        fax: "",
        zip: "",
        special_fare: "",
        
    },
    role: {
      id: "",
      name: "",
      slug: ""
    },
    id: '',
  },
  reducers: {
    clearState: (state) => {
      localStorage.removeItem('hertzTravelUserToken') // delete token from storage
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.id = '';
      state.access_token = "";
      return state;
    },
  },
  extraReducers: {
    //@ts-ignore
    [signupUser.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.first_name = payload.first_name;
      state.last_name = payload.last_name;
      state.phone = payload.phone;
      state.id = payload.id;
      
    },
    //@ts-ignore
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    //@ts-ignore
    [signupUser.rejected]: (state, { payload }) => {
        console.log("Rejected");
        //console.log(payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.errorMessage;
    },
    //@ts-ignore
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log('login User payload', payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.name = payload.name;
      state.email = payload.email;
      state.first_name = payload.first_name;
      state.last_name = payload.last_name;
      state.phone = payload.phone;
      state.errorMessage = "";
      state.access_token = payload.token;
      state.id = payload.id;
      state.company = payload.company;
      state.role = payload.role;
      return state;
    },
    //@ts-ignore
    [loginUser.rejected]: (state, { payload }) => {
      console.log('rejected payload', payload);
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      //state.errorMessage = payload.message;
      state.errorMessage = payload.errorMessage
    },
    //@ts-ignore
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    }
  },
});


export const { clearState } = userSlice.actions;

//export const userSelector = (state: { user: any; }) => state.user;
export const userSelector = (state: RootState) => state.user;

export default userSlice.reducer;
